  //export esthetics
  export const truncateAddress = (address) => {
      if (!address) return "No Account";
      const match = address.match(
        /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
      );
      if (!match) return address;
      return `${match[1]} ... ${match[2]}`;
    };

    export const formatNumber = (number) => {
      if (!number) return "0";
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 6,
      });
      return formatter.format(number);
    };

    export const removeThousands = (value) => {
      const cleanedValue = value.replace(/,/g, '');
      const integerPart = cleanedValue.split('.')[0];
      return integerPart;
    };