import "./App.css";
import Web3 from 'web3';
import "./css/mining.css";
import moment from "moment";
import "./css/harvester.css";
import { ethers } from "ethers";
import { Howl, Howler } from "howler";
import ReactPlayer from 'react-player';
import { FaLock } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import * as s from "./styles/globalStyles";
import { BsShieldLock } from "react-icons/bs";
import { PiInfinityLight } from "react-icons/pi";
import { useMediaQuery } from 'react-responsive';
// import { Approve } from "./tools/providerOptions";
import { bgmusic, soundeffects } from './tools/effects';
import { ethers5Adapter } from "thirdweb/adapters/ethers5";
import { contractData, networkDataArray } from "./tools/networkData";
import React, { useEffect, useState, useRef, createRef } from "react";
import { createWallet, walletConnect, inAppWallet } from "thirdweb/wallets";
import { formatNumber, removeThousands, truncateAddress } from './tools/utils';
import { base, sepolia, polygon, arbitrumNova, bsc, defineChain } from "thirdweb/chains";
import { createThirdwebClient, getContract, sendTransaction, readContract, prepareContractCall, prepareEvent} from "thirdweb";
import { GiZeusSword, Gi3dHammer, GiEyeTarget, GiConsoleController, GiWarPick, GiTrophyCup, GiLaurelsTrophy, GiStarMedal, GiWallet} from "react-icons/gi"; 
import { ThirdwebProvider, ConnectButton, darkTheme, useConnect, useActiveAccount, useReadContract, useActiveWallet,
  useActiveWalletChain, useSwitchActiveWalletChain, useSendTransaction, useWalletBalance, useContractEvents } from "thirdweb/react";

// Media Screen Resolution *************************************************************************************************
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 });
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return isMobile ? children : null;
};

// Global Constants ***************************************************************************************************************

let gasoptions;
let network;
let approved;

const client = createThirdwebClient({
  clientId: "56fbbaeaec406bdb5114a7d46f75cf6c",
});

const wallets = [
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  createWallet("com.binance"),
  walletConnect(),
  inAppWallet({
    auth: {
      options: [
        "email",
        "google",
        "twitter",
        "discord",
        "farcaster",
        "facebook",
        "apple",
        "phone",
      ],
    },
  }),
];

const manta = defineChain({id: networkDataArray[0].chainId, rpc: networkDataArray[0].rpc,});
const neon = defineChain({ id: networkDataArray[2].chainId, rpc: networkDataArray[2].rpc,});
const mantle = defineChain({ id: networkDataArray[3].chainId,  rpc: networkDataArray[3].rpc,});
const swanchain = defineChain({ id: networkDataArray[4].chainId,  rpc: networkDataArray[4].rpc,});
const core = defineChain({ id: networkDataArray[6].chainId,  rpc: networkDataArray[6].rpc,});
const linea = defineChain({ id: networkDataArray[8].chainId, rpc: networkDataArray[8].rpc,});
const pulsechain = defineChain({ id: networkDataArray[9].chainId, rpc: networkDataArray[9].rpc,});
const opbnb = defineChain({ id: networkDataArray[10].chainId, rpc: networkDataArray[10].rpc,});
const zkfair = defineChain({ id: networkDataArray[11].chainId, rpc: networkDataArray[11].rpc,});
const okx = defineChain({ id: networkDataArray[12].chainId, rpc: networkDataArray[12].rpc,});
const blast = defineChain({ id: networkDataArray[14].chainId, rpc: networkDataArray[14].rpc,});
const scroll = defineChain({ id: networkDataArray[15].chainId, rpc: networkDataArray[15].rpc,});

const chains = [manta, base, neon, mantle, swanchain, arbitrumNova, core, polygon,
   linea, pulsechain, opbnb, zkfair, okx, bsc, blast, scroll ];

const backgroundMusic = [bgmusic.Soundtrack1, bgmusic.Soundtrack2, bgmusic.Soundtrack3, bgmusic.Soundtrack4, bgmusic.Soundtrack5, 
  bgmusic.Soundtrack6, bgmusic.Soundtrack7, bgmusic.Soundtrack8, bgmusic.Soundtrack9, bgmusic.Soundtrack10, bgmusic.Soundtrack11, 
  bgmusic.Soundtrack12, bgmusic.Soundtrack13, bgmusic.Soundtrack14, bgmusic.Soundtrack15, bgmusic.Soundtrack16, bgmusic.Soundtrack17
]

// Global Variables ***********************************************************************************************************************
let wallet; let account; let web3; let battledog; 
let isACID; let isProcessing; let isProcess; let isLogin; let isAT; let isAT2; let isOBAD; let isCharge;
let isWrap; let isSetInterval; let saadm; let isAttackerId; let isDefenderId; let isRunning; 
let isLocking; let isStored; let isSetting; let isApproveNative; let isApproveGame; let isActivate; let isAssault; 
let isClaimAssault; let isClaimDebilitate; let isDebilitate; let isScoreboard; let isLevelUp; let isMint; let isPayout; 
let isRegenerate; let isResetTimer; let isCustomize; let isWeaponize; let isVolIcon; let isAAS; let isProcessed; 

//App ***********************************************************************************************************************

function App() {  
  const chainId = useActiveWalletChain();
  account = useActiveAccount();
  const [backgroundImage, setBackgroundImage] = useState(0);
  const [ counter, setCounter] = useState(0); 
  const {connect, isConnecting, error } = useConnect();
  let blockchain = networkDataArray[counter];
  const [ submitText, setSubmitText] = useState(blockchain.ecosystem);
  const [allOwnerOfPlayers, setAllOwnerOfPlayers] = useState([]);
  const [startMenuBkg, setStartMenuBkg] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [approval, setApproval] = useState();
  const [inputValue, setInputValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [attackerId, setAttackerId] = useState('');
  const [defenderId, setDefenderId] = useState('');
  const { mutate: sendTransaction, data: transactionResult } = useSendTransaction();
  const [accountBal, setAccountBal] = useState();
  const [provider, setProvider] = useState();
  const [signer, setSigner] = useState();
  const [trigger, setTrigger] = useState(false);
  const [rewarderAddress, setRewarderAddress] = useState();
  const [ownedBattledogs, setOwnedBattledogs] = useState();
  const [activeBattledogs, setActiveBattledogs] = useState();
  const [listener, setListener] = useState(true);
  const [showSwap, setShowSwap] = useState(false);
  const [tutorial, setTutorial] = useState(true);
  const [condition, setCondition] = useState(false);
  const [textTutor, setTextTutor] = useState('ON');
  const inputRef = useRef(null);

  wallet = useActiveWallet();

  const [allPlayers, setAllPlayers] = useState();
  const [availBalance, setAvailBalance] = useState();
  const [nativeBalance, setNativeBalance] = useState();
  const [gameBalance, setGameBalance] = useState();
  const [userAllowance, setUserAllowance] = useState();
  const [balOwned, setBalOwned] = useState();
  const [activatingAmount, setActivatingAmount] = useState();
  const [requiredAmount, setRequiredAmount] = useState();
  const [healthLimit, setHealthLimit] = useState();
  const [charge, setCharge] = useState();
  
  
// convert a thirdweb account to ethers signer
const getSigner = async () => {    
  try {   
    //signers & providers
  const ethersSigner = await ethers5Adapter.signer.toEthers({
    client,
    chain: chains[counter],
    account: account,
  });
  
  setSigner(ethersSigner);
  
  const ethersProvider = ethers5Adapter.provider.toEthers({
    client,
    chain: chains[counter],
  });

  setProvider(ethersProvider);   

  // web3 connect
  web3 = new Web3(window.ethereum);
  battledog = new web3.eth.Contract(
    contractData.battledog.abi,
    blockchain.battledogaddress
  );

  // conditionals
  // if (wallet.id === "inApp") {
  //   setCondition(true);
  // } 
  if (wallet) {
    setCondition(true);
  } 

// console.log("wallet: ", wallet);
// console.log("web3: ", web3);
// console.log("battledog: ", battledog);

  } catch (err) {
    console.log(err);
  }
}    


// console.log("provider: ", provider);
// console.log("signer: ", signer);

//Constants       
let contractBattledog = new ethers.Contract(blockchain.battledogaddress, contractData.battledog.abi, signer);
let reducer = blockchain.reducer;
let decimals = blockchain.decimals;
// Specify gas for sensitive chains

  //Counter States
  const plusNetwork = () => {
    if (counter + 1 < 16) { 
    setCounter(counter + 1);  
    playWhoosh();
    }
  };

  const dropNetwork = () => {
    if (counter - 1 >= 0) {
    setCounter(counter - 1);
    playWhoosh();
    }
  };

//Connector & Functions **************************************************************************************************************
function NativeBalance() {
  const { data, isLoading, error } = useReadContract({
    contract: contractNative,
    method: "function balanceOf(address account) view returns (uint256)", 
    params: [account.address]
  });

  if (isLoading) return <><span className="layoutBox" >Loading...</span></>;
  if (error) return <><span className="layoutBox" >Error: {error.message}</span></>;

  if (data && !isAT) {
    setNativeBalance(Number(data?.toString()) / decimals);
    setTrigger(true);
    isAT = true;
    getActivePlayers();
    allOwnerOfPlayersTx();
    gasOptions();        
  }
  
  return (
    <>
    <Desktop>
      {nativeBalance && (
      <div className="layoutBox" >{formatNumber((nativeBalance).toFixed(blockchain.tofixed))} <span className='twhite'> {blockchain.tokenA}</span></div>
      )}
    </Desktop>
    <Mobile>
      {nativeBalance && (
      <span className="layoutBoxMobile" >{formatNumber((nativeBalance).toFixed(blockchain.tofixed))} <span className='twhite'> {blockchain.tokenA}</span></span>
      )}
    </Mobile>
    </>
  );
}

function GameBalance() {
  const { data, isLoading, error } = useReadContract({
    contract: contractGame,
    method: "function balanceOf(address account) view returns (uint256)", 
    params: [account.address]
  });

  if (isLoading) return <><span className="layoutBox" >Loading...</span></>;
  if (error) return <><span className="layoutBox" >Error: {error.message}</span></>;

  if (data !== "undefined") {
    setGameBalance(Number(ethers.utils.formatEther(data?.toString())));
  }

  return (
    <>
    <Desktop>
      {gameBalance && (
    <div className="layoutBox" >{formatNumber((gameBalance).toFixed(blockchain.tofixed))}<span className='twhite'> GAME</span></div>
      )}
    </Desktop>
    <Mobile>
    {gameBalance && (
    <div className="layoutBoxMobile" >{formatNumber((gameBalance).toFixed(blockchain.tofixed))}<span className='twhite'> GAME</span></div>      
    )}
    </Mobile>
    </>
  );
}

function MintParameter() {
  let tx;
  const { data, isLoading, isError } = useWalletBalance({
    chain: chains[counter],
    address: account.address,
    client,
  });

  if (isError) {
    setErrorMessage("Internet Error. Retry Please.");
    setErrorMessageVisible(true);
    setLoading(false);
    return;
  }
  tx = ethers.utils.formatEther(data.value.toString());
  setAccountBal(Number(tx)); 
};

// function SampleBalance() {
//   const { data, isLoading, error } = useReadContract({
//     contract: contractGame,
//     method: "function balanceOf(address account) view returns (uint256)", 
//     params: [account.address]
//   });

//   if (isLoading) return <><span className="layoutBox" >Loading...</span></>;
//   if (error) return <><span className="layoutBox" >Error: {error.message}</span></>;
//   gameBalance = Number(ethers.utils.formatEther(data?.toString()));

//   return (
//     <>
//     <Desktop>
//     <div className="layoutBox" >{formatNumber((gameBalance).toFixed(blockchain.tofixed))}<span className='twhite'> GAME</span></div>
//     </Desktop>
//     <Mobile>
//     <div className="layoutBoxMobile" >{formatNumber((gameBalance).toFixed(blockchain.tofixed))}<span className='twhite'> GAME</span></div>
//     </Mobile>
//     </>
//   );
// }

// function BattledogTotalSupply() {
//   const { data, isLoading, error } = useReadContract({
//     contract: contract,
//     method: "totalSupply",
//     params: []
//   });

//   if (isLoading) return <><span className="layoutBox" >Loading...</span></>;
//   if (error) return <><span className="layoutBox" >Error: {error.message}</span></>;
//   allPlayers = (Number(data?.toString())).toFixed(blockchain.tofixed);

//   return (
//     <>
//       <span className="layoutBox" >{allPlayers.toFixed(blockchain.tofixed)}</span>
//     </>
//   );
// }

function Connector () {
  return (
    <ConnectButton
      client={client}
      chain={chains[counter]}
      wallets={wallets}
      theme={darkTheme({
        colors: {
          primaryText: `${blockchain.theme}`,
          secondaryText: "#c5c4cf",
          connectedButtonBg: "#252525",
          connectedButtonBgHover: "#161616",
          separatorLine: "#262830",
          primaryButtonBg: `${blockchain.theme}`,
        },
      })}
        supportedTokens={{
          [blockchain.chainId]: [{
            address: `${blockchain.gameaddress}`,
            name: "Game",
            symbol: "GAME",
            icon: 'https://battledogs.fun/assets/images/welcome.png',
          }]
        }}
        connectButton={{ label: "Login" }}
        connectModal={{
          size: "wide",
          title: "Socials or Wallet",
          titleIcon:
            "https://battledogs.fun/assets/images/welcome.png",
          welcomeScreen: {
            title: "Battledogs Arena",
            subtitle:
              "A p2p crypto battle card NFT game",
            img: {
              src: `https://battledogs.fun/assets/images/collectable/${counter}-bg.png`,
              width: 250,
              height: 250,
            },
          },
        }}
      />     
  );
}

// Contracts **************************************************************************************************************

let web3 = new Web3(window.ethereum);

let battledog = new web3.eth.Contract(
  contractData.battledog.abi,
  blockchain.battledogaddress
);

// get Battledogs contract
const contract = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: blockchain.battledogaddress,
  // OPTIONAL: the contract's abi
  abi: contractData.battledog.abi,
});

// get Game contract
const contractGame = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: blockchain.gameaddress,
  // OPTIONAL: the contract's abi
  abi: contractData.game.abi,
});

// get Proof of Play contract
const contractPop = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: blockchain.popaddress,
  // OPTIONAL: the contract's abi
  abi: contractData.pop.abi,
});

// get Harvester contract
const contractHarvester = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: rewarderAddress,
  // OPTIONAL: the contract's abi
  abi: contractData.harvester.abi,
});

// get Native contracts
const contractNative = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: blockchain.nativeaddress,
  // OPTIONAL: the contract's abi
  abi: blockchain.NATIVE.abi,
});

// get LP contracts
const contractLP = getContract({
  // the client you have created via `createThirdwebClient()`
  client,
  // the chain the contract is deployed on
  chain: chains[counter],
  // the contract's address
  address: blockchain.lpaddress,
  // OPTIONAL: the contract's abi
  abi: contractData.lp_abi,
});

// Write Calls *********************************************************************************************

  const approve = (_digit) => {    
    if (isWaiting) return;
    setIsWaiting(true);
    console.log("Approval: " , _digit); 
    if (decimals == 10**18)  {
      approved = ethers.utils.parseEther(_digit);
    } else {
      approved = _digit * decimals;
    };
      const transaction = prepareContractCall({ 
        contract: contractNative, 
        method: "approve", 
        params: [blockchain.battledogaddress, approved] 
      });
      sendTransaction(transaction);
  };
  
  const approveGame = (_digit) => {    
    if (isWaiting) return;
    setIsWaiting(true);
    if (decimals == 10**18)  {
      approved = ethers.utils.parseEther(_digit);
    } else {
      approved = _digit * decimals;
    };
    const transaction = prepareContractCall({ 
      contract: contractGame, 
      method: "approve", 
      params: [blockchain.battledogaddress, approved] 
    });
    sendTransaction(transaction);
  };


const revoke = () => {  
  setIsWaiting(true);
  const transaction = prepareContractCall({ 
    contract: contractNative, 
    method: "approve", 
    params: [blockchain.battledogaddress, 0] 
  });
  sendTransaction(transaction);
}

const revokeGame = () => { 
  setIsWaiting(true);
  const transaction = prepareContractCall({ 
    contract: contractGame, 
    method: "approve", 
    params: [blockchain.battledogaddress, 0] 
  });
  sendTransaction(transaction);
}

const wrap = (_amount) => {
  if (isWaiting) return;
  setIsWaiting(true);
  const amount_ = ethers.utils.parseEther(_amount.toString());
  const amount = Number(amount_);

    const transaction = prepareContractCall({ 
      contract: contractNative, 
      method: "function deposit(uint256 value) returns (bool)", 
      params: [amount],
      value: amount,
    });
    sendTransaction(transaction);
};

const unwrap = (_amount) => {
  if (isWaiting) return;
  setIsWaiting(true);
  const amount_ = ethers.utils.parseEther(_amount.toString());
  const amount = Number(amount_);

    const transaction = prepareContractCall({ 
      contract: contractNative, 
      method: "function withdraw(uint256 value) returns (bool)", 
      params: [amount] 
    });
    sendTransaction(transaction);
};

const Wrapper = () => { 
  useEffect(() => {
    if (isWaiting && transactionResult) { 
        setIsWaiting(false);
        wrapAfterCall();
      }
  },[isWaiting]); 
  return (
    <>
      <Desktop>
                <div>
                  <div className="" title={`Convert ${blockchain.coin} to ${blockchain.tokenA}`}>
                    <img src={require("./assets/images/icons/switch.png")} className={`permissionicons loadbearer${isWaiting}`}  onClick={() => {setShowSwap(true); handleShowForm()}} />
                  </div>
                </div>
                  {showForm && showSwap && (
                    <form autoComplete="on" onSubmit={(e) => handleSubmitter(e, wrap)} className={`loadbearer${isWaiting}`}>
                      <div>
                        <input autoFocus  name="input1"
                          className="inputform"
                          style={{ color: "white", border: "3px solid goldenrod", fontSize: '1.5vh'}}
                          type="text"
                          placeholder={`Convert ${blockchain.coin} rewards to ${blockchain.tokenA}`}
                          alt="Unwrap Amount"
                        />
                      </div>
                      <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Convert</button>
                      <button className={"button0"} type="button" onClick={handleHideForm} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
                    </form>
                  )}
      </Desktop>
      <Mobile>
        <div>
          <div className="spinner">
            <img src={require("./assets/images/icons/switch.png")} style={{height: "40px"}} title={`Convert ${blockchain.coin} rewards to ${blockchain.tokenA}`} 
            onClick={() => {setShowSwap(true); handleShowForm()}} className={`loadbearer${isWaiting}`} /> 
            {showForm && showSwap && (
              <form autoComplete="on" onSubmit={(e) => handleSubmitter(e, wrap)} className={`loadbearer${isWaiting}`} >
                <div>
                  <input autoFocus  name="input1"
                    className="inputformMb"
                    style={{ color: "white", border: "3px solid goldenrod", fontSize: '1.5vh'}}
                    type="text"
                    placeholder={`Convert ${blockchain.coin} rewards to ${blockchain.tokenA}`}
                    alt="Unwrap Amount"
                    />
                </div>
                  <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Convert</button>
                  <button className={"button0"} type="button" onClick={handleHideForm}  style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
              </form>
            )}
          </div>    
          </div>
      </Mobile>
    </>
  )
};

const Unwrapper = () => { 
  useEffect(() => {
    if (isWaiting && transactionResult) { 
        setIsWaiting(false);
        wrapAfterCall();
      }
  },[isWaiting]);  
  return (
    <>
      <Desktop>
          <div className="menu4" style={{color: `${blockchain.theme}`}}>      
              <div >
                <div onClick={handleShowForm}>
                  <div className="" title={`Convert ${blockchain.tokenA} rewards to ${blockchain.coin}`}>
                    <img src={require("./assets/images/icons/switch.png")} className={`permissionicons loadbearer${isWaiting}`} />
                  </div>
                </div>
                  {showForm && (
                    <form autoComplete="on" onSubmit={(e) => handleSubmitter(e, unwrap)} className={`loadbearer${isWaiting}`}>
                      <div>
                        <input autoFocus  name="input1"
                          className="inputform"
                          style={{ color: "white", border: "3px solid goldenrod" }}
                          type="text"
                          placeholder={`Convert ${blockchain.tokenA} rewards to ${blockchain.coin}`}
                          alt="Unwrap Amount"
                        />
                      </div>
                      <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Convert</button>
                      <button className={"button0"} type="button" onClick={handleHideForm} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
                    </form>
                  )}
              </div>
          </div>
      </Desktop>
      <Mobile>
        <div className='mobileapprove' align="center">
          <div className="spinner">
            <img src={require("./assets/images/icons/switch.png")} style={{height: "40px"}} title={`Convert ${blockchain.tokenA} rewards to ${blockchain.coin}`} 
            onClick={handleShowForm} className={`loadbearer${isWaiting}`} /> 
            {showForm && (
              <form autoComplete="on" onSubmit={(e) => handleSubmitter(e, unwrap)} className={`loadbearer${isWaiting}`} >
                <div>
                  <input autoFocus  name="input1"
                    className="inputformMb"
                    style={{ color: "white", border: "3px solid goldenrod" }}
                    type="text"
                    placeholder={`Convert ${blockchain.tokenA} rewards to ${blockchain.coin}`}
                    alt="Unwrap Amount"
                    />
                </div>
                  <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Convert</button>
                  <button className={"button0"} type="button" onClick={handleHideForm}  style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
              </form>
            )}
          </div>    
          </div>
      </Mobile>
    </>
  )
};

// Read Only Calls & Effects *******************************************************************************************************

const allDataCall = async () => {
  console.log("now calling all new data...");
  let tx; let txs;
  try {
    const call1 = await readContract({
      contract: contract,
      method: "function totalSupply() view returns (uint256)", 
      params: [] 
    });
    
    tx = Number(call1?.toString());
    setAllPlayers(tx);

    const call2 = await readContract({ 
      contract: contractNative, 
      method: "function balanceOf(address account) view returns (uint256)", 
      params: [account.address],
    });

    tx = Number(call2?.toString());
    txs = tx / decimals
    setAvailBalance(tx);
    setNativeBalance(txs);  
    setBalOwned(txs.toFixed(blockchain.tofixed));

    const call3 = await readContract({ 
      contract: contractGame, 
      method: "function balanceOf(address account) view returns (uint256)", 
      params: [account.address],
    });

    tx = ethers.utils.formatEther(call3?.toString());
    setGameBalance(Number(tx));
    
    const call4 = await readContract({ 
      contract: contractNative, 
      method: "function allowance(address owner, address spender) view returns (uint256)", 
      params: [account.address, blockchain.battledogaddress],
    });

    tx = call4?.toString();
    setUserAllowance(Number(tx) / decimals);

    const call5 = await readContract({ 
      contract: contract, 
      method: "function activatingAmount() view returns (uint256)", 
      params: [],
    });

    tx = call5?.toString();
    setActivatingAmount(Number(tx));
    
    const call6 = await readContract({ 
      contract: contract, 
      method: "function requiredAmount() view returns (uint256)", 
      params: [],
    });

    tx = Number(call6?.toString());
    setRequiredAmount(tx);

    setHealthLimit(tx * 50);

    const call7 = await readContract({ 
      contract: contract, 
      method: "function charge() view returns (uint256)", 
      params: [],
    });

    tx = ethers.utils.formatEther(call7?.toString());
    setCharge(Number(tx));

  } catch (error) {
    console.error("Error fetching Data elements:", error);
  //  throw error;
    return;
  }
};

const allOwnerOfPlayersTx = async () => {
  console.log("now fetching allOwnersOfPlayers data...");
  setAllOwnerOfPlayers([]);
  try {
    const result = await readContract({
      contract: contract,
      method: "function getPlayerOwners(address _player) view returns ((string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)[])",
      params: [account.address],
    });
    setAllOwnerOfPlayers(result); 
    return result; // for exporting
  } catch (error) {
    console.error("Error fetching Battledog NFTs:", error);
    setErrorMessage("Error fetching Battledog NFTs. Refresh.");
    setErrorMessageVisible(true);
    // throw error;
    return;
  }
};

// component states, handlers & Effects ***************************************************************************************
  const [startMenu, setStartMenu] = useState(true);
  const [arsenal, setArsenal] = useState();
  const [start, setStart] = useState();
  const [arena, setArena] = useState();
  const [winnings, setWinnings] = useState();
  const [tools, setTools] = useState();
  const [scoreboard, setScoreboard] = useState();  
  const [activityTracker, setActivityTracker] = useState();
  const [activate, setActivate] = useState();  
  const [activateHelp, setActivateHelp] = useState();
  const [assault, setAssault] = useState();
  const [assaultHelp, setAssaultHelp] = useState();
  const [claimAssault, setClaimAssault] = useState();
  const [claimAssaultHelp, setClaimAssaultHelp] = useState();
  const [claimDebilitate, setClaimDebilitate] = useState();
  const [claimDebilitateHelp, setClaimDebilitateHelp] = useState();
  const [debilitate, setDebilitate] = useState();  
  const [debilitateHelp, setDebilitateHelp] = useState();  
  const [levelUp, setLevelUp] = useState();
  const [levelUpHelp, setLevelUpHelp] = useState();
  const [harvester, setHarvester] = useState();
  const [mining, setMining] = useState();
  const [mint, setMint] = useState();
  const [mintHelp, setMintHelp] = useState();
  const [payout, setPayout] = useState();
  const [payoutHelp, setPayoutHelp] = useState();
  const [regenerate, setRegenerate] = useState();
  const [regenerateHelp, setRegenerateHelp] = useState();
  const [resetTimer, setResetTimer] = useState();
  const [resetTimerHelp, setResetTimerHelp] = useState();
  const [customize, setCustomize] = useState();
  const [customizeHelp, setCustomizeHelp] = useState();
  const [weaponize, setWeaponize] = useState();
  const [weaponizeHelp, setWeaponizeHelp] = useState();
  const [lock, setLock] = useState();

  const resetStates = () => {
    setLock(false);
    setStartMenu(); 
    setArsenal();
    setStart();
    setArena();
    setWinnings();
    setTools();
    setScoreboard();  
    setActivate();   
    setActivateHelp()
    setAssault();
    setAssaultHelp();;  
    setClaimAssault();
    setClaimAssaultHelp();
    setClaimDebilitate();
    setClaimDebilitateHelp();
    setDebilitate();  
    setDebilitateHelp();
    setLevelUp();
    setLevelUpHelp();
    setHarvester();
    setMint();
    setMintHelp();
    setMining();
    setPayout();
    setPayoutHelp();
    setRegenerate();
    setRegenerateHelp();
    setResetTimer();
    setResetTimerHelp();
    setCustomize();
    setCustomizeHelp();
    setWeaponize();
    setWeaponizeHelp();
    setActivityTracker();
    setLoading();
    setIsWaiting();
    setIsFarming();
    setIsClaiming();
    setSubmitText();
    setSubmitText2();
    setUnlocker(false);
    setUnlocker2(false);
    setSelectedPlayer(null);
    hideView();
  }

  const resetVar = () => {
    wallet = false; account = false; web3 = false; battledog = false; 
    isACID = false; isProcessing = false; isProcess = false; isLogin = false; isAT = false; isAT2 = false; isOBAD = false; isCharge = false;
    isWrap = false; isSetInterval = false; saadm = false; isAttackerId = false; isDefenderId = false; isRunning = false; 
    isLocking = false; isStored = false; isSetting = false; isApproveNative = false; isApproveGame = false; isActivate = false; isAssault = false; 
    isClaimAssault = false; isClaimDebilitate = false; isDebilitate = false; isScoreboard = false; isLevelUp = false; isMint = false; isPayout = false; 
    isRegenerate = false; isResetTimer = false; isCustomize = false; isWeaponize = false; isVolIcon = false; isAAS = false; isProcessed = false; 
  }
   
  const resetData = () => {
    setCharge();
    setClaims();
    setTrigger(false);
    setBalOwned();
    setAllPlayers();
    setMintLogs([]);
    setHealthLimit();
    setGameBalance();
    setLevelLogs([]);
    setErrorMessage();
    setAvailBalance();
    setPayoutsLog([]); 
    setAssaultWins([]);
    setAssaultLogs([]); 
    setNativeBalance();
    setUserAllowance();
    setRequiredAmount();
    setOwnedBattledogs();
    setRewarderAddress();
    setActivePlayers([]);
    setDebilitateLogs([]); 
    setDebilitateWins([]);
    setActivatingAmount();
    setActiveBattledogs();
    setAllOwnerOfPlayers([]);
    setActivePlayersList([]);
    resetVar();
  }

  const handleStartMenu = () => {
    resetStates();
    setStartMenu(true);
    setBackgroundImage(1);
    playPop();
  }

  const handleStart = () => {
    resetStates();
    playPop();
    setStart(true);
  }

  const handleArsenal = async() => { 
    resetStates(); 
    setLock(true);    
    setBackgroundImage(2);
    playPop();
    setArsenal(true);
  }
  
  const handleArena = () => {
    setErrorMessage('You are at the Arena');
    setErrorMessageVisible(true);
    resetStates();
    playPop();
    if (tutorial) {
      setArena(true);
    } else {
      handleAssault();
    }
  }
  
  const handleWinnings = () => {
    setErrorMessage('Manage Your Wins here');
    setErrorMessageVisible(true);
    resetStates();
      playPop();
    if (tutorial) {
      setWinnings(true);
    } else {
      handlePayout();
    }
  }
  
  const handleTools = () => {
    setErrorMessage('Customize, Farm, Mine or Reset here');
    setErrorMessageVisible(true);
    resetStates();
    playPop();
    if (tutorial) {
      setTools(true);
    } else {
      handleHarvester();
    }
  }
  
  const handleScoreboard = () => {
    resetStates();
    playPop();
    setScoreboard (true);
    setBackgroundImage(19);
  }

  const handleTutorial = () => {
    if (textTutor === 'ON') {
      setTextTutor('OFF')
    } else {
      setTextTutor('ON')
    }
    setTutorial(!tutorial);
  }
  
  const handleMint = () => {
    resetStates();
    setLock(true);
    playPop();
    setMint(true);
    setBackgroundImage(3);
  }
    
  const handleLevelUpHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setLevelUpHelp(true);
    } else {
      setLevelUp(true);
      setLock(true);
      setBackgroundImage(12);
    }
  }
    
  const handleLevelUp = () => {
    resetStates(false);
    setLock(true);
    playPop();
    setLevelUp(true);
    setBackgroundImage(12);
  }
    
  const handleAssaultHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setAssaultHelp(true);
    } else {
      setAssault(true);
      setLock(true);
      setBackgroundImage(5);
    }
  }
    
  const handleAssault = () => {
    resetStates();
    playPop();
    setAssault(true);
    setBackgroundImage(5);
  }

  const handleDebilitateHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setDebilitateHelp(true);
    } else {
    setDebilitate(true);
    setLock(true);
    setBackgroundImage(6);
    }
  }
    
  const handleDebilitate = () => {
    resetStates();
    playPop();
    setDebilitate(true);
    setLock(true);
    setBackgroundImage(6);
  }
    
  const handleActivateHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setActivateHelp(true);
    } else {
    setActivate(true);
    setLock(true);
    setBackgroundImage(4);
    }
  }
    
  const handleActivate = () => {
    resetStates();
    playPop();
    setActivate(true);
    setLock(true);
    setBackgroundImage(4);
  }
    
  const handlePayoutHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setPayoutHelp(true);
    } else {
    setPayout(true);
    setLock(true);
    setBackgroundImage(7);
    }
  }
    
  const handlePayout = () => {
    resetStates();
    setLock(true);
    playPop();
    setPayout(true);
    setBackgroundImage(7);
  }
  
  const handleClaimAssaultHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setClaimAssaultHelp(true);
    } else {
      setClaimAssault(true);
    setLock(true);
      setBackgroundImage(10);
    }
  }
  
  const handleClaimAssault = () => {
    resetStates();
    playPop();
    setClaimAssault(true);
    setLock(true);
    setBackgroundImage(10);
  }

  const handleClaimDebilitateHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setClaimDebilitateHelp(true);
    } else {
      setClaimDebilitate(true);
      setLock(true);
      setBackgroundImage(11);
    }
  }

  const handleClaimDebilitate = () => {
    resetStates();
    setLock(true);
    playPop();
    setClaimDebilitate(true);
    setBackgroundImage(11);
  }

  const handleHarvester = () => {
    resetStates();
    playPop();
    setHarvester(true);
    setBackgroundImage(1);
    setRewarderAddress(blockchain.harvesteraddress);
  }

  const handleMining = async () => {  
    allOwnerOfPlayersTx();
    resetStates();
    playPop();
    setMining(true);
    setBackgroundImage(15);
  }

  const handleRegenerateHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setRegenerateHelp(true);
    } else {
      setRegenerate(true);
      setLock(true);
      setBackgroundImage(9);
    }
  }

  const handleRegenerate = () => {
    resetStates();
    setLock(true);
    playPop();
    setRegenerate(true);
    setBackgroundImage(9);
  }

  const handleResetTimerHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setResetTimerHelp(true);
    } else {
    setResetTimer(true);
    setLock(true);
    setBackgroundImage(16);
    }
  }

  const handleResetTimer = () => {
    resetStates();
    setLock(true);
    playPop();
    setResetTimer(true);
    setBackgroundImage(16);
  }

  const handleCustomizeHelp = () => {
    resetStates();
    playPop();
    if (tutorial) {
      setCustomizeHelp(true);
    } else {
    setCustomize(true);
    setBackgroundImage(18);
    }
  }

  const handleCustomize = () => {
    resetStates();
    playPop();
    setCustomize(true);
    setBackgroundImage(18);
  }

  const handleWeaponizeHelp = () => { 
    resetStates();
    playPop();
    if (tutorial) {
      setWeaponizeHelp(true);
    } else {
    setWeaponize(true);
    setLock(true);   
    setBackgroundImage(8); 
    }
  }

  const handleWeaponize = () => { 
    resetStates();
    playPop();
    setWeaponize(true);
    setLock(true);
    setBackgroundImage(8);
  }

  const handleActivityTracker = () => {
    resetStates();
    playPop();
    setActivityTracker(true);
    setBackgroundImage(17);
  }

// Arsenal Component, handles & states **********************************************************************************************
const [selectedPlayer, setSelectedPlayer] = useState(null);
const [selectedPlayerStats, setSelectedPlayerStats] = useState();
const [reservePlayerData, setReservePlayerData] = useState();
const [playerData, setPlayerData] = useState([]);
const [currentIndex, setCurrentIndex] = useState(0);

function handleShowForm() {
  setShowForm(true);
}

function handleHideForm() {
  setShowForm(false);
}

function handleSubmit(e) {
  e.preventDefault();    
  approve();
  setApproval("");
  setShowForm(false);
}

const handleSelectedPlayerClick = (tokenId) => {
  if (selectedPlayer != tokenId) {
  setSelectedPlayer(tokenId);
  playBrick();
  }
};

const getPlayerData = async (tokenId) => {
  const response = await fetch(`https://${blockchain.website}/battledogs/${tokenId}.json`);
  const dataplayer = await response.json();

  // Check if dataplayer has the attributes property
  if (!dataplayer.hasOwnProperty("attributes")) {
    console.error("The dataplayer object does not have the 'attributes' property.");
    return;
  }

  // Filter the attributes array to get the trait_type and value properties
  const filteredAttributes = dataplayer.attributes.filter((attribute) => {
    return attribute.hasOwnProperty("trait_type") && attribute.hasOwnProperty("value");
  });

  // Create a new array to store the filtered attributes
  const playerAttributes = filteredAttributes.map((attribute) => {
    return {
      trait_type: attribute.trait_type,
      value: attribute.value
    };
  });

  // Slice the playerAttributes array to get the first 9 elements
  const slicedPlayerAttributes = playerAttributes.slice(0, 9);
  // Find the selected player in allOwnerOfPlayers based on player.id
  const selectedPlayerData = allOwnerOfPlayers.find((player) => player.id === selectedPlayer);

  // Check if the selected player was found
  if (!selectedPlayerData) {
    console.error("Selected player not found in allOwnerOfPlayers");
    return;
  }

  const payoutNumber = Number(selectedPlayerData.payout); // Convert payout to Number

  // Create an object for selected player's attributes
  const selectedPlayerAttributes = [
    { trait_type: "Current Level", value: selectedPlayerData.level.toString() },
    { trait_type: "Your Aggression", value: selectedPlayerData.attack + " POINTS"},
    { trait_type: "Current Stamina", value: selectedPlayerData.defence + " POINTS"},
    { trait_type: "Payable Fights", value: selectedPlayerData.fights.toString() },
    { trait_type: "Registered Wins", value: selectedPlayerData.wins.toString() },
    { trait_type: "Pending Payout", value: (payoutNumber / reducer) + ` ${blockchain.tokenA}` },
    { trait_type: "Activation", value: "TIER " + selectedPlayerData.activate + " MINING"},
    { trait_type: "Your History", value: selectedPlayerData.history + " FIGHTS"}
  ];


    // Create a new array from selectedPlayerData
      const reservePlayerDataArray = [
      Number(selectedPlayerData.level),
      Number(selectedPlayerData.attack),
      Number(selectedPlayerData.defence),
      Number(selectedPlayerData.fights),
      Number(selectedPlayerData.wins),
      payoutNumber,
      Number(selectedPlayerData.activate),
      Number(selectedPlayerData.history)];   
    setReservePlayerData(reservePlayerDataArray);

  // Combine slicedPlayerAttributes and selectedPlayerAttributes
  const combinedAttributes = [...slicedPlayerAttributes, ...selectedPlayerAttributes];
  // Set the combined player data in the state
  setPlayerData(combinedAttributes);
};

useEffect(() => {  
  if (account && chainId) {
    if (isACID) return;
     isACID = true;
    for (let i = 0; i < chains.length; i++) {
      if (chains[i].id === chainId.id) {
        setCounter(i);   
      }
    }
    isACID = false;
  };
}, [account]);

useEffect(() => {
  if (!account || !wallet) {
      if(isLogin) return;
      isLogin = true;
    handleStartMenu();
    resetData();
    setSigner();
    playLogoff();
    // Howler.volume(0);
    setBackgroundImage(0);
    setSoundtrack(bgmusic.Windhowl);
      isLogin = false
  };
}, [account]);

useEffect(() => {
  if (account && wallet) {
      if(isLogin) return;
      isLogin = true;
    setBackgroundImage(1); 
    setSoundtrack(backgroundMusic[counter]);
    setVolumeLevel(0.3);
    Howler.volume(0.3);
    playAudio();
    playLogin();
      isLogin = false;
  };
}, [account]);

useEffect(() => {  
  if (account && signer && trigger) {
    if (isAT2) return;
      isAT2 = true;
      allListeners();
  }
},[signer])

useEffect(() => {
  if (signer && allOwnerOfPlayers) {
    if (isOBAD) return;
    isOBAD = true;
  let ownedbattledogs; let activebattledogs;    
  ownedbattledogs = allOwnerOfPlayers.filter(player => Number(player.activate.toString()) > 0);
  setOwnedBattledogs(ownedbattledogs);
  activebattledogs = ownedbattledogs.map(x => +x.id.toString());
  setActiveBattledogs(activebattledogs);
  allDataCall();
    isOBAD = false
  }
},[allOwnerOfPlayers]);

useEffect(() => {
  if (charge) {
    console.log("running fetchAllowance & unclaimed data...");
    if (isCharge) return;
    isCharge = true;
  setLastMint(allPlayers);
  fetchAllowanceData();  
  unclaimed();
    isCharge = false;
  }
},[charge])

// useEffect(() => {
//   if (startMenu || harvester || start || arena || winnings || tools || activityTracker) {
//     if (shsawta) return;
//       shsawta = true;
//     setLock(false);
//       shsawta = false;
//   }
// }, [startMenu, harvester, start, arena, winnings, tools, activityTracker]);

useEffect(() => { 
  if (!account) return;
    if (allOwnerOfPlayers && selectedPlayer) {
      if (isSetInterval) return;
        isSetInterval = true;
      getPlayerData(selectedPlayer);
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % playerData.length);
      }, 10000);
      isSetInterval = false;
  
      return () => clearInterval(interval);
  }
  }, [selectedPlayer, playerData.length]);

// Mint Component and States ****************************************************************************************

const [name, setName] = useState("");
const [lastMint, setLastMint] = useState(allPlayers);
const [effect, setEffect] = useState(soundeffects.Welcome);
  
let newMint = `url(https://battledogs.fun/engine/${lastMint}.png)`;
 
async function Mint(_account) {    
  const estimatedGasPrice = await web3.eth.getGasPrice();
  console.log('Estimated gas price (wei):', estimatedGasPrice);
  
  const gasPrice = web3.utils.fromWei(estimatedGasPrice, 'gwei');
  console.log('Gas price (gwei):', gasPrice);
  let mintfee;

  const call1 = await readContract({ 
    contract: contract, 
    method: "function mintFee() view returns (uint256)", 
    params: [] 
  });

  mintfee = Number(ethers.utils.formatEther(call1?.toString()));

  // check if user can mint a Battledog
  if (accountBal < mintfee) {
    setErrorMessage("Insufficient Funds to Mint.");
    setErrorMessageVisible(true);
    return;
  }

  if (condition) {

    setLoading(true); 
    try {  
    const transaction = prepareContractCall({ 
      contract: contract, 
      method: "function mint(string _name) payable", 
      params: [name],
      value: call1,
    });

    sendTransaction(transaction);

    } catch (err) {
      setLoading(false);  
      setErrorMessage("Error: ", err);
      setErrorMessageVisible(true);    
    }
  } else {
    let tx = Number(call1?.toString());
    try {
    setLoading(true); 
      battledog.methods
        .mint(name)
        .send({
          from: _account,
          value: (tx), 
          gasPrice: estimatedGasPrice
        })
        .once("error", (err) => {
          setLoading(false);
          setErrorMessage(`Oops, insufficient ${blockchain.coin} to mint Battledog!`);
          setErrorMessageVisible(true);
          console.log(err);
        })
        .then((receipt) => {
          setLoading(false);
          if (!receipt.status) {
            // check receipt.events for error event
            setErrorMessage({ errorMessage });
            setErrorMessageVisible(true);
          } else {
            console.log('Mint Succeeded');
            allOwnerOfPlayersTx();
            playWelcome();
            setErrorMessage("Fresh Mint!");
            setErrorMessageVisible(true);
          }
        }).catch((error) => {
          const err = error.toJSON();
          if (!err.cause) {
            setErrorMessage(err.code.toString()+": "+err.name.toString());
            setErrorMessageVisible(true);           
          } else {
            setErrorMessage(err.cause.message.toString());
            setErrorMessageVisible(true);
          } 
          setLoading(false);
        }); 
    } catch (err) {
      console.log("A Mint Call Error Occured")
    }

  }
};

const handleMintClick = (e) => {
  e.preventDefault();
  if (loading) return;
  let tx;
  playButton();

  // Check that name is not an empty string
  if (name === "") {
    setErrorMessage("Name cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // All input is valid, mint a new Battledog
  Mint(account.address);
};


const imageDuration = 6000;
const divDuration = imageDuration / 2;

useEffect(() => {
  if (arsenal || assault || debilitate || mint) {
    if (saadm) return;
    saadm = true;
  const interval = setInterval(() => {
    setShowImage((prevShowImage) => !prevShowImage);
  }, showImage ? imageDuration : divDuration);

  saadm = false;
  // Clean up the interval when the component is unmounted
  return () => clearInterval(interval);
  }
}, [showImage, arsenal, assault, debilitate, mint]);

// Activate Component & States ******************************************************************************************
const [showTargetA, setTargetA] = useState('');
const [showTargetB, setTargetB] = useState('');
const [activePlayers, setActivePlayers] = useState([]);
const [activePlayersList, setActivePlayersList] = useState([]);

const gasOptions = async () => {
  getSigner();
  if (blockchain.gasoptions) return;  // TBD
    const estimatedGasPrice = await web3.eth.getGasPrice();
    console.log('Estimated gas price (wei):', estimatedGasPrice);
    
    const gasPrice = web3.utils.fromWei(estimatedGasPrice, 'gwei');
    console.log('Gas price (gwei):', gasPrice);
    
    gasoptions = {
      from: account.address, 
      gasPrice: estimatedGasPrice 
    }   
};

let TargetA = `https://battledogs.fun/engine/${showTargetA}.png`;
let TargetB = `https://battledogs.fun/engine/${showTargetB}.png`;
let targetA = `url(${TargetA})`;
let targetB = `url(${TargetB})`;

// Regular expression to validate that input is a positive integer
const integerRegex = /^[1-9]\d*$/;

async function Activatenft(_account, _tokenId) {
  setLoading(true);
  if (condition) {
    let tx = Number(_tokenId);
    try {
    const transaction = prepareContractCall({ 
      contract: contract, 
      method: "function activateNFT(uint256 _tokenId) payable", 
      params: [tx],
    });
  
    sendTransaction(transaction);
    } catch (err) {     
      // check receipt.events for error event
      setLoading(false);
      setErrorMessage(`You need to first approve or have enough ${blockchain.tokenA} for gameplay.`);        
      setErrorMessageVisible(true);
    }
  } else {          
      battledog.methods
        .activateNFT(_tokenId)
        .send(gasoptions)
        .once("error", (err) => {
          setLoading(false);
          setErrorMessage(`Failed? You need to first approve or have enough ${blockchain.tokenA} for gameplay.`);        
          setErrorMessageVisible(true);
        })
        .then((receipt) => {
          setLoading(false);
          if (!receipt.status) {
            // check receipt.events for error event
            setErrorMessage(errorMessage);
            setErrorMessageVisible(true);
          } else {
            allOwnerOfPlayersTx();
            handleUpload(_tokenId);
            handleState(_tokenId);
            setErrorMessage("Activated!");
            setErrorMessageVisible(true);
            playActivate();
          }
        }).catch((error) => {
          const err = error.toJSON();
          if (!err.cause) {
            setErrorMessage(err.code.toString()+": "+err.name.toString());
            setErrorMessageVisible(true);           
          } else {
            setErrorMessage(err.cause.message.toString());
            setErrorMessageVisible(true);
          } 
          setLoading(false);
        });    
  }
};

const getActivePlayers = async () => {
  try {
    const response = await fetch(`https://${blockchain.website}/battledogs/activePlayers.json`, {cache: "no-cache",});
    if (!response.ok) {
      // throw new Error('Failed to fetch activePlayers.json');
      setErrorMessage('Failed to fetch activePlayers. Refresh');        
      setErrorMessageVisible(true);      
    }

    const allplayers = await response.json();

    // Parse the player IDs as integers and store them in activePlayersList state
    const storedActivePlayers = allplayers.map(id => parseInt(id, 10));
    setActivePlayers(storedActivePlayers);
    setActivePlayersList(storedActivePlayers);
  } catch (error) {
    console.error('Error fetching active players:', error);
  }
};

const handleUpload = async (_tokenId) => {
  // e.preventDefault(); 
  
  network = (blockchain.ecosystem).toLowerCase();
  // console.log(network);
  // console.log("Updating ActivePlayers...");
  // check if playerId is included in the activePlayers list 
  let uniqueTokenId = [...activePlayers];        
    if (!uniqueTokenId.includes(_tokenId)) {
        uniqueTokenId.push(parseInt(_tokenId, 10));
    }  

  // Save array to a JSON file
  const file = JSON.stringify(uniqueTokenId);
  // console.log("Value: ", file);
  const blob = new Blob([file], { type: 'application/json' }); // Convert string to Blob
  const newfilename = 'activePlayers.json';
  const formData = new FormData();
  
  // Append the Blob to FormData
  formData.append("avatar", blob, newfilename);
  // Append network type string
  formData.append("network", network);

    try {
        const response = await fetch(`https://pdflupserver.battledogs.fun/avatar`, {
          method: "POST",
          body: formData
        });
        const parsedResponse = await response.json();
        if (response.ok) {
          console.log('List Up To Date!');
        } else {
          console.log("Error Occured")
        }
      } catch (e) {
        console.error(e.message)
      }
    };

    const handleState = async (_tokenId) => { 
      let tx;
      tx = Number(_tokenId);  
      network = (blockchain.ecosystem).toLowerCase();
      console.log(network);
      try {          
        const responsecall = await fetch(`https://${blockchain.website}/battledogs/${_tokenId}.json`, {cache: "no-cache",});
        if (!responsecall.ok) {
          // throw new Error('Failed to fetch token data');
          setErrorMessage('Failed to fetch token data. Retry');        
          setErrorMessageVisible(true);
        };
        let tokenData = await responsecall.json();

        const playersData = await readContract({ 
          contract, 
          method: "function players(uint256) view returns (string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)", 
          params: [tx] 
        })
    
        const playerData = {
          Name: playersData[0].toString(),
          Id: Number(playersData[1].toString()),
          Level: Number(playersData[2].toString()),
          Aggression: Number(playersData[3].toString()),
          Stamina: Number(playersData[4].toString()),
          Fights: Number(playersData[5].toString()),
          Wins: Number(playersData[6].toString()),
          Payout: playersData[7].toString(),
          Activation: Number(playersData[8].toString()),
          History: Number(playersData[9].toString()),
        };
    
        const payoutValue = parseInt(playerData.Payout) / reducer;
        playerData.Payout = payoutValue + ` ${blockchain.tokenA}`;

        const traitTypes = ['Level', 'Aggression', 'Stamina', 'Fights', 'Wins', 'Payout', 'Activation', 'History'];

        for (const [traitType, value] of Object.entries(playerData)) {
          if (traitTypes.includes(traitType)) {
            const existingTrait = tokenData.attributes.find(attr => attr.trait_type === traitType);
    
            if (existingTrait) {
              existingTrait.value = value;
            } else {
              tokenData.attributes.push({ trait_type: traitType, value: value });
            }
          }
        }

         // Save data to a JSON file
          const file = JSON.stringify(tokenData);
          const blob = new Blob([file], { type: 'application/json' }); // Convert string to Blob
          const newFileName = `${_tokenId}.json`;
          const formData = new FormData();            
          console.log("file raw data: ", file);
          // Append the Blob to FormData
          formData.append("avatar", blob, newFileName);
          // Append network type string
          formData.append("network", network);
    
        const response = await fetch('https://pdflupserver.battledogs.fun/avatar', {
          method: 'POST',
          body: formData
        });
    
        const data = await response.json();
        console.log(data.message);
      } catch (error) {
        console.error('Error updating player data:', error);
      }
    };      

    const handleActivateUpdateClick = async (e) => {
      if (loading) return;
      e.preventDefault();
      gasOptions();
    
      const totalSupply = allPlayers - 1; 
    
      // Check the ID is not an empty string
      if (attackerId === "") {
        setErrorMessage("ID cannot be empty.");
        setErrorMessageVisible(true);
        return;
      }
    
      // Check that attackerId is a valid integer
      if (!integerRegex.test(attackerId)) {
        setErrorMessage("Only a battledog ID is accepted.");
        setErrorMessageVisible(true);
        return;
      }
    
      // Check that attackerId is greater than 0 and less than or equal to totalSupply
      if (attackerId <= 0 || attackerId > totalSupply) {
        setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
        setErrorMessageVisible(true);
        return;
      }
    
      // Check if the player owns the battledog
      const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === attackerId);
      if (ownedbattledogs.length === 0) {
        setErrorMessage("You do not own this Battledog.");
        setErrorMessageVisible(true);
        return;
      }
    
      const activeDog = Number((ownedbattledogs[0].activate).toString());
    
      if (activeDog === 0 && availBalance < activatingAmount) {
        setErrorMessage(`Insufficient ${blockchain.tokenA} for activation.`);
        setErrorMessageVisible(true);
        return;
      } 
    
    
      if (activeDog > 0 && gameBalance < charge) {
        setErrorMessage(`Insufficient ${blockchain.tokenB} for activation.`);
        setErrorMessageVisible(true);
        return;
      }
    
      // Everything is valid, activate the nft
      Activatenft(account.address, attackerId);  
    };

const handleAttackerChange = (e) => {
  setAttackerId(e.target.value);
  playConcrete();
};

const handleDefenderChange = (e) => {
  setDefenderId(e.target.value);
  playConcrete();
};

const handleInputChange = (e) => {
  setInputValue(e.target.value);
  playConcrete();
};

const handleApprovalChange = (e) => {
  setApproval(e.target.value);
  playConcrete();
};

const handleNameChange = (e) => {
  setName(e.target.value);
  playConcrete();
};

useEffect(() => {
  if (attackerId !=='') {
    if (isAttackerId) return;
      isAttackerId = true;
    setTargetA(attackerId);
      isAttackerId = false;
  }
}, [attackerId]);

useEffect(() => {  
  if (defenderId !=='') {
    if (isDefenderId) return;
      isDefenderId = true;
    setTargetB(defenderId);
     isDefenderId = false;     
  } else {
    if (isDefenderId) return;
      isDefenderId = true;
    setTargetB(0);       
      isDefenderId = false;  
  };    
}, [defenderId]);

// Assault Components & States ***********************************************************************************************

async function Assault(_account, _attackerId, _defenderId) {
  setLoading(true);      
  if (condition) {

    let tx1; let tx2;
    try {
      const transaction = prepareContractCall({ 
        contract, 
        method: "function Assault(uint256 attackerId, uint256 defenderId) payable", 
        params: [_attackerId, _defenderId] 
      });
      sendTransaction(transaction);
  
      } catch (err) {
        setErrorMessage("Failed? Either limit surpassed or wait 24hrs to attack this Battledog again.");
        setErrorMessageVisible(true);
        setLoading(false);
      }
  } else {      
    
      battledog.methods
        .Assault(_attackerId,_defenderId)
        .send(gasoptions)
        .once("error", (err) => {
          setLoading(false);
          setErrorMessage("Failed? Either limit surpassed or wait 24hrs to attack this Battledog again.");
          setErrorMessageVisible(true);
          console.log(err);
        })
        .then((receipt) => {
          setLoading(false);
          if (!receipt.status) {
            // check receipt.events for error event
            setErrorMessage({errorMessage});
            setErrorMessageVisible(true);
        } else {
            
          allOwnerOfPlayersTx();
            handleState(_attackerId);
            handleState(_defenderId);
            setErrorMessage("Success!");
            setErrorMessageVisible(true);
        }
        }).catch((error) => {
          const err = error.toJSON();
          if (!err.cause) {
            setErrorMessage(err.code.toString()+": "+err.name.toString());
            setErrorMessageVisible(true);           
          } else {
            setErrorMessage(err.cause.message.toString());
            setErrorMessageVisible(true);
          } 
          setLoading(false);  
        });
    }
  };

  // Sleep function that resolves after a specified number of milliseconds
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleAssaultUpdateClick = async (_value) => {
    if (loading) return;
    let tx; let _attackerId;
    gasOptions(); 
    
    if (!attackerId) {    
      _attackerId = _value;
    } else {
      _attackerId = attackerId;
    }
  
     // Check that ID is not an empty string
     if (_attackerId === "" || defenderId === "") {
      setErrorMessage("IDs cannot be empty.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check IDs are valid integers
    if (!integerRegex.test(_attackerId) || !integerRegex.test(defenderId)) {
      setErrorMessage("Only battledog IDs are accepted.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check that battledog does not self debilitate
    if (_attackerId == defenderId) {
      setErrorMessage("You cannot inflict self-harm");
      setErrorMessageVisible(true);
      return;
    }
  
    const totalSupply = allPlayers - 1;
    tx = Number(defenderId);
  
    // Check that IDs are greater than 0 and less than or equal to totalSupply
    if (Number(_attackerId) <= 0 || tx <= 0  || Number(_attackerId) > totalSupply || tx > totalSupply) {
      setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
      setErrorMessageVisible(true);
      return;
    }
  
    const enemyBattledog = await readContract({ 
      contract, 
      method: "function players(uint256) view returns (string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)", 
      params: [tx] 
    })
  
    // Check if the player owns the battledog
    const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === _attackerId);
    if (ownedbattledogs.length === 0) {
      setErrorMessage("You do not own this Battledog.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check if the battledog has been activated
    const ownedbattledog = allOwnerOfPlayers.find(x => x.id.toString() === _attackerId);
    if (ownedbattledog.activate.toString() === 0) {
      setLoading(false);
      setErrorMessage("You need to activate your battledog first!");
      setErrorMessageVisible(true);
      return;
    }
    // Check if enemy battledog has been activated
    if (enemyBattledog[8].toString() === 0) {
      setLoading(false);
      setErrorMessage("Enemy Battledog is not in the Arena!");
      setErrorMessageVisible(true);
      return;
    }
  
  // Check if the battledog is handicapped
  if (ownedbattledog.attack.toString() === 0) {
    setLoading(false);
    setErrorMessage("Battledog is handicapped. You need to weaponize first!");
    setErrorMessageVisible(true);
    return;
  }
  
  // Check if enemy battledog has been handicapped 
  if (enemyBattledog[3].toString() === 0 ) {
    setLoading(false);
    setErrorMessage("Enemy Battledog is already handicapped!");
    setErrorMessageVisible(true);
    return;
  }
  
    // Everything is valid, assault the battledog
    Assault(account.address, _attackerId, defenderId);
};   

// Debilitate Component & States *****************************************************************************

async function Debilitate(_account, _attackerId, _defenderId) {
  setLoading(true);   
  if (condition) {

    let tx1; let tx2;
    try {
    const transaction = prepareContractCall({ 
      contract, 
      method: "function Debilitate(uint256 attackerId, uint256 defenderId) payable", 
      params: [_attackerId, _defenderId] 
    });
  
    sendTransaction(transaction);
  
      } catch (err) {      
        setLoading(false);
        setErrorMessage("Failed? Either limit surpassed or wait 24hrs to attack this battledog again.");
        setErrorMessageVisible(true);
      }  
  } else {
    
      battledog.methods
      .Debilitate(_attackerId, _defenderId)
      .send(gasoptions)
      .once("error", (err) => {
        setLoading(false);
        setErrorMessage("Failed? Either limit surpassed or wait 24hrs to attack this battledog again.");
        setErrorMessageVisible(true);
        console.log(err);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {      
          allOwnerOfPlayersTx();
          handleState(_attackerId);
          handleState(_defenderId);
          setErrorMessage("Success!");
          setErrorMessageVisible(true);
      }
      }).catch((error) => {
        const err = error.toJSON();
        if (!err.cause) {
          setErrorMessage(err.code.toString()+": "+err.name.toString());
          setErrorMessageVisible(true);           
        } else {
          setErrorMessage(err.cause.message.toString());
          setErrorMessageVisible(true);
        } 
        setLoading(false);
      });
  }  
};      

const handleDebilitateUpdateClick = async (_value) => {
  if (loading) return;
  let tx; let _attackerId;
  gasOptions();
    
  if (!attackerId) {
    _attackerId = _value;
  } else {
    _attackerId = attackerId;
  }
   // Check that ID is not an empty string
   if (_attackerId === "" || defenderId === "") {
    setErrorMessage("IDs cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check IDs are valid integers
  if (!integerRegex.test(_attackerId) || !integerRegex.test(defenderId)) {
    setErrorMessage("Only battledog IDs are accepted.");
    setErrorMessageVisible(true);
    return;
  }
 

  // Check that battledog does not self debilitate
  if (_attackerId == defenderId) {
    setErrorMessage("You cannot inflict self-harm");
    setErrorMessageVisible(true);
    return;
  }

  const totalSupply = allPlayers - 1;
  tx = Number(defenderId); 
  // Check that IDs are greater than 0 and less than or equal to totalSupply
  if (Number(_attackerId) <= 0 || tx <= 0  || Number(_attackerId) > totalSupply || tx > totalSupply) {
    setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }
  
  const enemyBattledog = await readContract({ 
    contract, 
    method: "function players(uint256) view returns (string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)", 
    params: [tx] 
  })

  // Check if the player owns the battledog
  const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === _attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if the Battledog has been activated
  const ownedbattledog = allOwnerOfPlayers.find(x => x.id.toString() === _attackerId);
  if (ownedbattledog.activate.toString() === '0') {
    setLoading(false);
    setErrorMessage("You need to activate your Battledog first!");
    setErrorMessageVisible(true);
    return;
  }

  // Check if enemy battledog has been activated
  if (enemyBattledog[8].toString() === '0') {
    setLoading(false);
    setErrorMessage("Enemy Battledog is not in the Arena!");
    setErrorMessageVisible(true);
    return;
  }

// Check if the Battledog is incapacitated
if (ownedbattledog.defence.toString() === 0) {
  setLoading(false);
  setErrorMessage("Battledog is handicapped. You need to regenerate first!");
  setErrorMessageVisible(true);
  return;
}

// Check if enemy Battledog has been incapacitated 
if (enemyBattledog[4].toString() === 0 ) {
  setLoading(false);
  setErrorMessage("Enemy Battledog is already handicapped!");
  setErrorMessageVisible(true);
  return;
}
  // Everything is valid, debilitate the Battledog
  Debilitate(account.address, _attackerId, defenderId);
};     

// Weaponize component & states ************************************************************************************

async function Weaponize(_account, _tokenId) {
  setLoading(true);    
  if (condition) {

    let tx;  
    try {
      const transaction = prepareContractCall({ 
        contract, 
        method: "function weaponize(uint256 _tokenId) payable", 
        params: [attackerId] 
      });
      sendTransaction(transaction);
  
    } catch (err) {    
      setLoading(false);
      setErrorMessage('Error: ', err);
      setErrorMessageVisible(true);
      setLoading(false);
    }
  } else {

      battledog.methods
      .weaponize(_tokenId)
      .send(gasoptions)
      .once("error", (error) => {
        setLoading(false);
        setErrorMessage(error.message);
        setErrorMessageVisible(true);
        console.error(error);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {
          allOwnerOfPlayersTx();
          handleState(_tokenId);
          setErrorMessage("Weaponized!");
          setErrorMessageVisible(true);
      }
      }).catch((error) => {
        const err = error.toJSON();
        if (!err.cause) {
          setErrorMessage(err.code.toString()+": "+err.name.toString());
          setErrorMessageVisible(true);           
        } else {
          setErrorMessage(err.cause.message.toString());
          setErrorMessageVisible(true);
        } 
        setLoading(false);
      });
      
  }
};

const handleWeaponizeUpdateClick = (e) => {
  e.preventDefault();
  if (loading) return;
  gasOptions();

  const totalSupply = allPlayers;

  // Check the ID is not an empty string
  if (attackerId === "") {
    setErrorMessage("ID cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0 || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if the user owns the Battledog
  const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if the Battledog has been activated
  const ownedbattledog = allOwnerOfPlayers.find(x => x.id.toString() === attackerId);
  if (ownedbattledog.activate === "0") {
    setLoading(false);
    setErrorMessage("You need to activate your Battledog first!");
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, weaponize the Battledog
  Weaponize(account.address, attackerId);
};    

// Regenerate Component & States ***************************************************************************************

async function Regenerate(_account, _tokenId) {
  setLoading(true); 
  if (condition) {

    let tx; 
    try {    
      const transaction = prepareContractCall({ 
        contract, 
        method: "function regenerate(uint256 _tokenId) payable", 
        params: [attackerId] 
      });
      sendTransaction(transaction);
  
    } catch (err) {
      setErrorMessage("Error: ", err);
      setErrorMessageVisible(true);
      setLoading(false);
    }
  } else {  
        battledog.methods
        .regenerate(_tokenId)
        .send(gasoptions)
        .once("error", (err) => {
          setLoading(false);
          console.log(err);
        })
        .then((receipt) => {
          setLoading(false);
          if (!receipt.status) {
            // check receipt.events for error event
            setErrorMessage({errorMessage});
            setErrorMessageVisible(true);
        } else {
            allOwnerOfPlayersTx();
            handleState(_tokenId);
            setErrorMessage("Regenerated!");
            setErrorMessageVisible(true);
        }
        }).catch((error) => {
          const err = error.toJSON();
          if (!err.cause) {
            setErrorMessage(err.code.toString()+": "+err.name.toString());
            setErrorMessageVisible(true);           
          } else {
            setErrorMessage(err.cause.message.toString());
            setErrorMessageVisible(true);
          }  
          setLoading(false);
        });
  }
};

const handleRegenerateUpdateClick = (e) => {
  e.preventDefault();
  if (loading) return;
  gasOptions();

  const totalSupply = allPlayers;

  // Check the ID is not an empty string
  if (attackerId === "") {
    setErrorMessage("ID cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0 || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if the player owns the Battledog
  const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if the Battledog has been activated
  const ownedbattledog = allOwnerOfPlayers.find(x => x.id.toString() === attackerId);
  if (ownedbattledog.activate === "0") {
    setLoading(false);
    setErrorMessage("You need to activate your Battledog first!");
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, regenerate the Battledog
  
  Regenerate(account.address, attackerId);
};        

// ClaimAssault component & States ****************************************************************************************

async function Claimassault(_account, _defenderId) {
  setLoading(true); 
  if (condition) {

    let tx;  
    try {
      const transaction = prepareContractCall({ 
        contract, 
        method: "function claimAssault(uint256 defenderId)", 
        params: [defenderId] 
      });
      sendTransaction(transaction);
      
    } catch (err) {
      setLoading(false);
      setErrorMessage("Error: ", err);
      setErrorMessageVisible(true);
    }
  } else {
    
      battledog.methods
      .claimAssault(_defenderId)
      .send(gasoptions)
      .once("error", (err) => {
        setLoading(false);
        setErrorMessage(err.message);        
        setErrorMessageVisible(true);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {
          
          allOwnerOfPlayersTx();
          handleState(_defenderId);
          setErrorMessage("Claim Success!");
          setErrorMessageVisible(true);
      }
      }).catch((error) => {
        const err = error.toJSON();
        if (!err.cause) {
          setErrorMessage(err.code.toString()+": "+err.name.toString());
          setErrorMessageVisible(true);           
        } else {
          setErrorMessage(err.cause.message.toString());
          setErrorMessageVisible(true);
        } 
        setLoading(false);
      });
  }
};

const handleClaimAssaultUpdateClick = (e) => {
  if (loading) return;
  e.preventDefault();
  gasOptions();

  const totalSupply = allPlayers - 1;

   // Check ID is not an empty string
   if (defenderId === "") {
    setErrorMessage("IDs cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is a valid integer
  if (!integerRegex.test(defenderId)) {
    setErrorMessage("Only battledog IDs are accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is greater than 0 and less than or equal to totalSupply
  if (defenderId <= 0  || defenderId > totalSupply) {
    setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if the player owns the battledog
  const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === defenderId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if battledog has pending rewards 
  const dBattledog = allOwnerOfPlayers.find(x => x.id.toString() === defenderId);
  if (Number(dBattledog.attack.toString()) < 200) {
    setLoading(false);
    setErrorMessage("This Battledog has no pending rewards!");
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, assault the Battledog
  Claimassault(account.address, defenderId);
};      

// claimDebilitate handles & functions **********************************************************************************

async function Claimdebilitate(_account, _defenderId) {
  setLoading(true);    
  if (condition) {

    let tx;
    try {    
      const transaction = prepareContractCall({ 
        contract, 
        method: "function claimDebilitate(uint256 defenderId)", 
        params: [defenderId] 
      });
      sendTransaction(transaction);
  
    } catch (err) {
      setErrorMessage("Error: ", err);
      setErrorMessageVisible(true);
      setLoading(false);
    } 
  } else {
    
      battledog.methods
      .claimDebilitate(_defenderId)
      .send(gasoptions)
      .once("error", (err) => {
        setLoading(false);
        setErrorMessage(err.message);        
        setErrorMessageVisible(true);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {
          allOwnerOfPlayersTx();
          handleState(_defenderId);
          setErrorMessage("Claim success!");
          setErrorMessageVisible(true);
      }
      }).catch((error) => {
        const err = error.toJSON();
        if (!err.cause) {
          setErrorMessage(err.code.toString()+": "+err.name.toString());
          setErrorMessageVisible(true);           
        } else {
          setErrorMessage(err.cause.message.toString());
          setErrorMessageVisible(true);
        } 
        setLoading(false);
      });
  }
};

const handleClaimDebilitateUpdateClick = (e) => {
  e.preventDefault();
  if (loading) return;
  gasOptions();

  const totalSupply = allPlayers - 1;

   // Check ID is not an empty string
   if (defenderId === "") {
    setErrorMessage("IDs cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is a valid integer
  if (!integerRegex.test(defenderId)) {
    setErrorMessage("Only battledog IDs are accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is greater than 0 and less than or equal to totalSupply
  if (defenderId <= 0  || defenderId > totalSupply) {
    setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if the player owns the battledog
  const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === defenderId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if Battledog has pending rewards 
  const dBattledog = allOwnerOfPlayers.find(x => x.id.toString() === defenderId);
  if (Number(dBattledog.defence.toString()) < 200) {
    setLoading(false);
    setErrorMessage("This Battledog has no pending rewards!");
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, assault the Battledog
  Claimdebilitate(account.address, defenderId);
};    

// Levelup components & functions *************************************************************************************

async function Levelup(_account, _attackerId) {
  setLoading(true);    
  if (condition) {

    let tx;
    try {    
      const transaction = prepareContractCall({ 
        contract, 
        method: "function levelUp(uint256 attackerId)", 
        params: [attackerId] 
      });
      sendTransaction(transaction);
      
    } catch (err) {
      setLoading(false);
      setErrorMessage("Error: ", err);
      setErrorMessageVisible(true);
      setLoading(false);
    }
  } else {
    
      battledog.methods
      .levelUp(_attackerId)
      .send(gasoptions)
      .once("error", (err) => {
        setLoading(false);
        setErrorMessage(err.message);        
        setErrorMessageVisible(true);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {      
          allOwnerOfPlayersTx();
          handleState(_attackerId);
          setErrorMessage("Leveled Up!");
          setErrorMessageVisible(true);
      }
        }).catch((error) => {
          const err = error.toJSON();
          if (!err.cause) {
            setErrorMessage(err.code.toString()+": "+err.name.toString());
            setErrorMessageVisible(true);           
          } else {
            setErrorMessage(err.cause.message.toString());
            setErrorMessageVisible(true);
          } 
          setLoading(false);
        });
  }
};

const handleLevelUpUpdateClick = async (e) => {
  if (loading) return;
  let tx1; let tx2;
  e.preventDefault();
  gasOptions();

  const totalSupply = allPlayers - 1;

   // Check ID is not an empty string
   if (attackerId === "") {
    setErrorMessage("ID cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0  || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if the player owns the battledog
  const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if Battledog has unclaimed wins 
  const dBattledog = allOwnerOfPlayers.find(x => x.id.toString() === attackerId);
  if (Number(dBattledog.wins.toString()) < 5) {
    setLoading(false);
    setErrorMessage("This battledog has insufficient wins!");
    setErrorMessageVisible(true);
    return;
  }
    
  // Check if Game is approved
  const call1 = await readContract({ 
    contract: contractGame, 
    method: "function allowance(address owner, address spender) view returns (uint256)", 
    params: [account.address, blockchain.battledogaddress],
  });

  tx1 = ethers.utils.formatEther(call1?.toString());
  tx2 = charge * Number(dBattledog.activate.toString());

  if (Number(tx1) < charge) {
    setLoading(false);
    setErrorMessage(`You need to approve ${tx2} ${blockchain.tokenB} to levelup this battledog!`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if Available Game is sufficient  

  if (gameBalance < tx2) {
    setLoading(false);
    setErrorMessage(`You require ${tx2} ${blockchain.tokenB} to levelup this battledog!`);
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, assault the battledog
  Levelup(account.address, attackerId);
};   
 

// Payout handles & States *******************************************************************************************

async function Payouts(_account, _attackerId) {
  setLoading(true);   
  if (condition) {

    let tx;  
    try { 
      const transaction = prepareContractCall({ 
        contract, 
        method: "function Payouts(uint256 _playerId) payable", 
        params: [attackerId] 
      });
  
      sendTransaction(transaction);
      
    }  catch (err) {    
      // check receipt.events for error event
      setErrorMessage('Error:', err);
      setErrorMessageVisible(true); 
      setLoading(false);  
    }
  } else {
      
    battledog.methods
    .Payouts(_attackerId)
    .send(gasoptions)
    .once("error", (err) => {
      setLoading(false);
      console.log(err);
    })
    .then((receipt) => {
      setLoading(false);
      if (!receipt.status) {
        // check receipt.events for error event
        setErrorMessage({errorMessage});
        setErrorMessageVisible(true);
    } else {
      allOwnerOfPlayersTx();
    }
    }).catch((error) => {
      const err = error.toJSON();
      if (!err.cause) {
        setErrorMessage(err.code.toString()+": "+err.name.toString());
        setErrorMessageVisible(true);           
      } else {
        setErrorMessage(err.cause.message.toString());
        setErrorMessageVisible(true);
      } 
      setLoading(false);
    });
  }
};

const handlePayoutUpdateClick = (e) => {
  e.preventDefault();
  if (loading) return;
  gasOptions();

  const totalSupply = allPlayers - 1;

   // Check ID is not an empty string
   if (attackerId === "") {
    setErrorMessage("ID cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0  || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if the player owns the Battledog
  const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Check if Battledog has required wins 
  const dBattledog = allOwnerOfPlayers.find(x => x.id.toString() === attackerId);
  if (Number(dBattledog.wins.toString()) < 5) {
    setLoading(false);
    setErrorMessage("Battledog doesn't meet the minimum wins required!");
    setErrorMessageVisible(true);
    return;
  }

  // Check if Battledog has required level 
  if (dBattledog.level < 1) {
    setLoading(false);
    setErrorMessage("Battledog doesn't meet the minimum level required!");
    setErrorMessageVisible(true);
    return;
  }

  // Check if Battledog has required payout 
  if (dBattledog.payout === "0") {
    setLoading(false);
    setErrorMessage("Oops, Battledog has no payout!");
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, assault the Battledog
  Payouts(account.address, attackerId);
};  

// Customize handles & components*************************************************************************************
const [type, setType] = useState(false);

async function Customize(_account, _attackerId, _name) {
  setLoading(true); 
  if (condition) {
    let tx;
    tx = Number(attackerId);
    try {
      const transaction = prepareContractCall({ 
        contract, 
        method: "function updateName(uint256 _tokenId, string _newName)", 
        params: [tx, name] 
      });
      sendTransaction(transaction);
      
    } catch (err) {
        setErrorMessage('Error: ', err);        
        setErrorMessageVisible(true);
        setLoading(false);
    }
  } else {
      
    battledog.methods
    .updateName(_attackerId, _name)
    .send(gasoptions)
    .once("error", (err) => {
      setLoading(false);
      setErrorMessage(err.message);        
      setErrorMessageVisible(true);
      console.log(err);
    })
    .then((receipt) => {
      setLoading(false);
      if (!receipt.status) {
        // check receipt.events for error event
        setErrorMessage({ errorMessage });
      } else {
        allOwnerOfPlayersTx();
      }
    }).catch((error) => {
      const err = error.toJSON();
      if (!err.cause) {
        setErrorMessage(err.code.toString()+": "+err.name.toString());
        setErrorMessageVisible(true);           
      } else {
        setErrorMessage(err.cause.message.toString());
        setErrorMessageVisible(true);
      } 
      setLoading(false);
    });
    
  }
};

const handleCustomizeUpdateClick = (e) => {
  e.preventDefault();
  if (loading) return;
  gasOptions();

  // Check the ID is not an empty string
  if (attackerId === "" || name === '') {
    setErrorMessage("Field cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is greater than 0 and less than or equal to totalSupply
  const totalSupply = allPlayers;
  if (attackerId <= 0 || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }
    
  // Check if the player owns the battledog
  const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // All input is valid, update the name
  Customize(account.address, attackerId, name);
};

const toggleType = () => {
  setType(!type);
}

const fileInput = createRef();

const handleImageUpload = async (e) => {
  e.preventDefault(); 
  
  const network = (blockchain.ecosystem).toLowerCase();
  console.log(network);

  // Check that attackerId is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only a battledog ID is accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check that attackerId is greater than 0 and less than or equal to totalSupply
  const totalSupply = allPlayers;
  if (attackerId <= 0 || attackerId > totalSupply) {
    setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }
    
  // Check if the player owns the battledog
  const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  const file = fileInput.current.files[0];
  const fileExtension = file.name.split('.').pop();

  const allowedExtensions = ['jpg', 'png', 'gif'];
  const maxSize = 1 * 1024 * 1024; // 1 megabyte

  if (!allowedExtensions.includes(fileExtension)) {
    setErrorMessage('Invalid file type.');
    setErrorMessageVisible(true);
    return;
  }

  if (file.size > maxSize) {
    setErrorMessage('File exceeds 1MB.');
    setErrorMessageVisible(true);
    return;
  }

  const newFileName = `${attackerId}.${fileExtension}`;
  const formData = new FormData();
  formData.set("avatar", file, newFileName);

  // Append network type string
  formData.append("network", network);

  (async () => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = async function () {
      const width = this.width;
      const height = this.height;
      if (width !== height) {
        setErrorMessage("Wrong Dimensions.");
        setErrorMessageVisible(true);
        return;
      }

      if (width !== 512 && width !== 768 && width !== 1024) {
        setErrorMessage("Must be 512x512, 768x768, or 1024x1024 pixels allowed.");
        setErrorMessageVisible(true);
        return;
      }

      try {
        const response = await fetch(`https://pdflupserver.battledogs.fun/avatar`, { // server uploader goes here
          method: "POST",
          body: formData
        });

        const parsedResponse = await response.json();
        if (response.ok) {
          setErrorMessage('Image uploaded!');
          setErrorMessageVisible(true);
        } else {
          console.log("Error Occured")
        }
      } catch (e) {
        console.error(e.message)
      }
    };
  })();
};


// Harvester component & states*******************************************************************************************
const [stakeType, setStakeType] = useState(false);
const [senderLogs, setSenderLogs] = useState(null);
const [isApproved, setIsApproved] = useState(false)
const [currentAllowance, setCurrentAllowance] = useState('');
const [isWaiting, setIsWaiting] = useState(false);
const [isClaiming, setIsClaiming] = useState(false);
const [isWithdrawing, setIsWithdrawing] = useState(false);
const [isRevoking, setIsRevoking] = useState(false);
const [isFarming, setIsFarming] = useState(false);
const [totalStaked, setTotalStaked] = useState("");
const [currentStake, setCurrentStake] = useState("");
const [poolShares, setPoolShares] = useState("");
const [rewardClaim, setRewardClaim] = useState("");
const [totalClaim, setTotalClaim] = useState("");
const [rewardOwed, setRewardOwed] = useState("");
const [onset, setOnset] = useState();
const [apy, setApy] = useState("");
const [lpbalance, setLPBalance] = useState("");

const handleHover = () => {
  setIsHovered(true);
  playConcrete();
};

const handleOnHover = () => {
  setIsHovered(true);
  playRumble();
};

const StakeColumn = ({ className, children }) => {
  const [active, setActive] = useState(false);
  const columnRef = useRef(null);

  const handleColumnClick = (e) => {
    if (e.target === columnRef.current) {
      setActive(true);
      playWhoosh();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (columnRef.current && !columnRef.current.contains(e.target) && !loading) {
        setActive(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [loading]);

  return (
    <div
      className={`stake-column ${className} ${active ? "active" : ""}`}
      onClick={handleColumnClick}
      ref={columnRef}
    >
      {active && children}
    </div>
  );
};

const MobileColumn = ({ className, children }) => {
  const [active, setActive] = useState(false);
  const columnRef = useRef(null);

  const handleColumnClick = (e) => {
    if (e.target === columnRef.current) {
      setActive(true);
      playWhoosh();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (columnRef.current && !columnRef.current.contains(e.target)) {
        setActive(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      className={`mobile-column ${className} ${active ? "active" : ""}`}
      onClick={handleColumnClick}
      ref={columnRef}
    >
      {active && children}
    </div>
  );
};  
const handleFunction1 = async (_value) => {
    if (!signer) return;
    let tx; let value; let contracts;
    tx = Number(_value);
    value = _value.toString();
    setApproval(value);
  gasOptions();    
  try {
    setSubmitText('Validating Farm Request...');
    if (!stakeType) {
      contracts = contractGame;
    } else {
      contracts = contractLP;
    }

    // Check if approval
    const call1 = await readContract({ 
      contract: contracts, 
      method: "function allowance(address owner, address spender) view returns (uint256)", 
      params: [account.address, rewarderAddress],
    });

    const userallowance = Number(ethers.utils.formatEther(call1?.toString()));

    if (tx > userallowance) {
      approveGameHarvester(value);
    } else {
      addGAME(value);
    }      
  } catch (err) {
    console.error(err);
  }
};

const handleFunction2 = async () => {
  setLoading(true);
  gasOptions();
  const tx = await withdrawGAME(); 
  // await tx;
  fetchFarmData();  
  setLoading(false);
};

const handleFunction3 = async () => {
  setLoading(true);
  gasOptions();
  const tx = await claimRewards();
  // await tx;     
  fetchFarmData();  
  console.log('Claim Succeeded.');
  setLoading(false);
};

const handleHarvestSubmit = async (e, func) => {
  e.preventDefault();

  const Value = e.target.input1.value;
  if (!Value) {
    return;
  }

  try { 
  setLoading(true);     
  await func(Value); 
  fetchFarmData();  
  resetAllowance(); 
  setLoading(false); 

  } catch (err) {
    console.error(err);
  setLoading(false);
  }
};

const handleHarvestSubmitter = async (e, func) => {
  e.preventDefault();
  setLoading(true);

  const digit = e.target.input2.value;
  if (!digit) {
    return;
  }

  try {  
  await func(digit); 
  setShowForm(false); 
  resetAllowance();
  } catch (err) {
    console.error(err);
  }
  setLoading(false);
};
  
  // Claim all Rewards

  const claimRewards = async () => {
    if (isClaiming) return;
    try {
      setIsClaiming(true);   
      submitText(`Claiming harvest...`); 
        
        const transaction = prepareContractCall({ 
          contract: contractHarvester, 
          method: "function claim()", 
          params: [] 
        });
        sendTransaction(transaction);
        // await tx.wait();
        console.log("Claim success");
        playWithdraw();
    } catch (err) {

      if (err.message && err.message.includes("user rejected")) {
        console.log("Transaction Rejected by User");
      }
      setIsClaiming(false);
    }    
  };

const resetAllowance = async () => {
  console.log("calling reset allowance...")
  let tx; let contractr;
  setCurrentAllowance('0');
    if (!stakeType) {
      contractr = contractGame;
    } else {
      contractr = contractLP;
    }
    
    const call1 = await readContract({ 
      contract: contractr, 
      method: "function allowance(address owner, address spender) view returns (uint256)", 
      params: [account.address, rewarderAddress],
    });

    tx = ethers.utils.formatEther(call1?.toString());
    
    setCurrentAllowance(tx);
}

// Approve access to GAME tokens
const approveGameHarvester = async (value) => {  
  if (isWaiting) return;
  try {    
    setSubmitText('Initializing Approval...');
    setIsWaiting(true);
    let contractERC;
    if (!stakeType) {
      contractERC = contractGame;
    } else {
      contractERC = contractLP;
    }

    const approved = ethers.utils.parseEther(value);
    const transaction = prepareContractCall({ 
      contract: contractERC, 
      method: "approve", 
      params: [rewarderAddress, approved] 
    });
    sendTransaction(transaction);
    
  } catch (err) {

    if (err.message && err.message.includes("user rejected")) {
      console.log("Transaction Rejected by User");
    }
    setIsWaiting(false);
    return false;
  }
};

// Revoke access to GAME tokens
const revokeGameHarvester = async () => {
  if (isRevoking) return;
  try {
    setSubmitText("Revoking Allowance...");
    setLoading(true);
    setIsRevoking(true);
    let contractERC;
    if (!stakeType) {
      contractERC = contractGame;
    } else {
      contractERC = contractLP;
    }
    
    const transaction = prepareContractCall({ 
      contract: contractERC, 
      method: "approve", 
      params: [rewarderAddress, 0] 
    });

    // await tx.wait();
    console.log("Revoke Succeeded");
    resetAllowance();    
    setIsRevoking(false);
    setLoading(false);
  } catch (err) {

    if (err.message && err.message.includes("user rejected")) {
      console.log("Transaction Rejected by User");
    setIsRevoking(false);
    setLoading(false);
    }
  }
};

// Add GAME to farming.
const addGAME = async (value) => {
  if (isFarming) return;
    setIsFarming(true); 
      setSubmitText('Initializing Farm...');
      console.log('stored approval: ', approval);
      const amount = ethers.utils.parseEther(value); 
      const _amount = Number(amount);      

      const transaction = prepareContractCall({ 
        contract: contractHarvester, 
        method: "function addGAME(uint256 _amount)", 
        params: [_amount] 
      });
      sendTransaction(transaction); 

      console.log('Executed Farm Call!', _amount);
};

// Withdraw all GAME.
const withdrawGAME = async () => {
  if (!signer || isWithdrawing) return;
  try {
    setIsWithdrawing(true);    
    submitText("Withdraw processing...");
    const transaction = prepareContractCall({ 
      contract: contractHarvester, 
      method: "function withdrawGAME()", 
      params: [] 
    });
    sendTransaction(transaction);

      console.log("Withdraw Succeeded");
      playWithdraw();
  } catch (err) { 
    if (err.message && err.message.includes("user rejected")) {
      console.log("Transaction Rejected by User");
    }
    setIsWithdrawing(false);    
  }   
};

//Fetch Farm Data
const fetchFarmData = async () => {
  if (!provider) return;
  let Decimals;
  
  const divisor = ethers.utils.parseEther((100).toString());

  const block = await provider.getBlock('latest');
  const blockTimeStamp = block.timestamp;
  console.log("blockTimeStamp: ", blockTimeStamp)

  let replenisher;
  let replenishTax;
  let replenished;

  if (!stakeType) {
    Decimals = decimals;

    replenisher = await readContract({ 
      contract: contractHarvester, 
      method: "function replenisher() view returns (bool)", 
      params: []  
    });

    replenishTax = await readContract({ 
      contract: contractHarvester, 
      method: "function replenishTax() view returns (uint256)", 
      params: [] 
    });

    replenished = Number(replenishTax?.toString()) / 100;
  } else {
    Decimals = 10**18;
  }  

  let rps = await readContract({ 
      contract: contractHarvester, 
      method: "function rewardPerStamp() view returns (uint256)", 
      params: [] 
    });    
  const rewardPerStamp = Number(rps?.toString());

  let erl = await readContract({ 
    contract: contractHarvester, 
    method: "function eralength() view returns (uint256)", 
    params: [] 
  });
  const eraLength = Number(erl?.toString());  

  const ts = await readContract({ 
    contract: contractHarvester, 
    method: "function TotalGAMESent() view returns (uint256)", 
    params: [] 
  });
  const tstaked = ethers.utils.formatEther(ts?.toString());
  setTotalStaked(Number(tstaked).toFixed(2));

  const tc = await readContract({ 
    contract: contractHarvester, 
    method: "function totalClaimedRewards() view returns (uint256)", 
    params: [] 
  });
  const tClaim = Number(tc?.toString()) / Decimals;
  setTotalClaim(tClaim.toFixed(blockchain.tofixed));

  const rc = await readContract({ 
    contract: contractHarvester,
    method: "function Claimants(address) view returns (uint256)", 
    params: [account.address]
  });
  const rclaimed = Number(rc) / Decimals;
  setRewardClaim(rclaimed.toFixed(blockchain.tofixed));

  const claimRewardsResult = await readContract({ 
    contract: contractHarvester,
    method: "function claimRewards(address) view returns (uint256 eraAtBlock, uint256 GAMESent, uint256 rewardsOwed)", 
    params: [account.address]
  });

  const [eraAtBlock, GAMESent, rewardsOwed] = claimRewardsResult;
  const dSent = ethers.utils.formatEther(GAMESent?.toString());  

  setCurrentStake(dSent);

  const year = blockTimeStamp + 31556951;
  const RoWed = Number(rewardsOwed?.toString());

  const GAMEPoolShare = (parseFloat(dSent) * 100) / parseFloat(tstaked);
  setPoolShares(GAMEPoolShare);

  let revenue;
  let yrevenue;
  
  const startPeriod = Number(eraAtBlock?.toString());

  let endPeriod;

  let cst = await readContract({ 
    contract: contractHarvester,
    method: "function startTime() view returns (uint256)", 
    params: [] 
  });
  const contractStartTime = Number(cst?.toString());
  console.log("contractStartTime: ", contractStartTime);

  let lvd = await readContract({ 
    contract: contractHarvester,
    method: "function liveDays() view returns (uint256)", 
    params: [] 
  });
  const livedays = Number(lvd?.toString());

  console.log("startPeriod: ", startPeriod);
  let stakeTime = (blockTimeStamp - contractStartTime) / eraLength;
  endPeriod = Math.floor(stakeTime);

  if (startPeriod !== endPeriod) { 
    let reve = RoWed;
    let eraReward;
    for (let i = startPeriod; i < endPeriod; i++) {        
    let eR = await readContract({ 
      contract: contractHarvester,
      method: "function eraRewards(uint256) view returns (uint256)", 
      params: [i]   
    });
    const eraData = Number(eR?.toString());
    console.log("eraData: ", eraData);
    if (eraData > 0) {
    eraReward = eraData;
    } else {
      // determine if 0 is from deduced result or from unintialized query
      if (i <= livedays) {
        eraReward = 0;
      } else {      
        eraReward = rewardPerStamp; 
      }
    }
    
    const rewardsAccrued = eraReward * Number(dSent);
    reve += rewardsAccrued;
    }
    revenue = ((reve ) / Number(divisor) );
    
    const estimatedRewards = Math.abs(revenue) / Decimals;

    if (!stakeType && replenisher) {
      setRewardOwed(estimatedRewards * replenished);
    } else {       
      setRewardOwed(estimatedRewards);
      setOnset(true);
    }

    // const yreve = (Number(rps) * year * Number(dSent)) + Number(RoWed);;
    // const yreven = Math.abs(yreve);
    // yrevenue = yreven / divisor;
  } else {
    setRewardOwed(0);
  }
  const GAMEApy = (yrevenue / 365) * 100;
  setApy(Number(GAMEApy));
};  

const handleStakeTypeChange = (isLPStake) => {
  setStakeType(isLPStake);
  setRewarderAddress(isLPStake ? blockchain.lpharvesteraddress : blockchain.harvesteraddress);
};

async function harvestRun () {  
  await resetAllowance(); 
  await fetchFarmData(); 
}

useEffect(() => {  
  if (!signer || !provider || isRunning) return;
  if (harvester) {
      isRunning = true; 
    harvestRun();
      isRunning = false;
   }
}, [stakeType, harvester]);  

// Mining Component & states ***************************************************************************************
const [unlocker, setUnlocker] = useState(false);
const [unlocker2, setUnlocker2] = useState(false);
const [submitText2, setSubmitText2] = useState("");
const [timelock, setTimeLock] = useState();
const [locks, setLocks] = useState([]);
const [showButton, setShowButton] = useState(true);

const statusLock = async () => {
  const total = activeBattledogs.length;
  let lockmaster = [];
  for (let i = 0; i < total; i++ ) {
    const inquestId = activeBattledogs[i];
    let ms = await readContract({ 
      contract: contractPop, 
      method: "function MinerClaims(uint256) view returns (uint256)", 
      params: [inquestId] 
    });
    const minerState = Number(ms?.toString());
    lockmaster.push(minerState);
  }    
    setLocks(lockmaster);

  let tc = await readContract({ 
    contract: contractPop, 
    method: "function timeLock() view returns (uint256)", 
    params: [] 
  });
  const timeLock = Number(tc?.toString());
  setTimeLock(timeLock);
}

useEffect(() => {
  if (isLocking || !activeBattledogs) return;
    isLocking = true;
  statusLock();
    isLocking = false;
}, [mining]);

const handleButtonClick = () => {
  setShowButton(false);
};

const claim = async (inputValue) => {
  if (isClaiming) return;
  let tx;
  try {
    setIsClaiming(true);
    setSubmitText("Initiating Claim...");
    setUnlocker(true);

    const transaction = prepareContractCall({ 
      contract: contractPop, 
      method: "function mineGAME(uint256[] _nfts)", 
      params: [inputValue] 
    });
    sendTransaction(transaction);

  } catch (err) {
    console.error(err);
    setUnlocker(false);
    setIsClaiming(false);
  }
};

const multiclaim = async (arrayValue) => {
  if (isClaiming) return;
  let tx;
  try {
    setIsClaiming(true);
      setSubmitText2("Initiating Claim...");
      setUnlocker2(true);
      
    const transaction = prepareContractCall({ 
      contract: contractPop, 
      method: "function mineGAME(uint256[] _nfts)", 
      params: [arrayValue] 
    });
    sendTransaction(transaction);
    
  } catch (err) {
    console.error(err);
    setUnlocker2(false);
    setLoading(false);
  }
};

const XboColumn = ({ className, backgroundImage, children }) => {
  const [active, setActive] = useState(false);
  const columnRef = useRef(null);

  const handleColumnClick = (e) => {
    if (e.target === columnRef.current) {
      setActive(true);
      playWhoosh();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (columnRef.current && !columnRef.current.contains(e.target) && !loading) {
        setActive(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [loading]);

  return (
    <div
      className={`xbo-column ${className} ${active ? "active" : ""} col-1`}
      onClick={handleColumnClick}
      ref={columnRef}
      style={{backgroundColor: "black", backgroundImage: backgroundImage ? `url(${backgroundImage})` : `none` }}
    >
      {active && children}
    </div>
  );
};  

const MobileXboColumn = ({ className, backgroundImage, children }) => {
  const [active, setActive] = useState(false);
  const columnRef = useRef(null);

  const handleColumnClick = (e) => {
    if (e.target === columnRef.current) {
      setActive(true);
      playWhoosh();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (columnRef.current && !columnRef.current.contains(e.target) && !loading) {
        setActive(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [loading]);

  return (
    <div className={`mobile-column ${className} ${active ? "active" : ""}`}
    onClick={handleColumnClick}
    ref={columnRef}
    style={{border: `0.25vh solid ${blockchain.theme}`,backgroundColor: "black", backgroundImage: backgroundImage ? `url(${backgroundImage})` : `none` }}
  >
    {active && children}
    </div>
  );
};  

const handleMiningFunction1 = () => {
  setSubmitText("Verifying...");
  gasOptions();
  claim([parseInt(selectedPlayer)]); 
};

const handleMiningFunction2 = () => { 
  // setArrayValue(activeBattledogs);
  setSubmitText("Verifying...");
  gasOptions();
  multiclaim(activeBattledogs);
};  

const handleMiningSubmit = (e, func) => {
  e.preventDefault();
  func();
};

const handleMiningClick = (tokenId) => {
  if (selectedPlayer != tokenId) {
  setSelectedPlayer(tokenId);
  playWhoosh();
  }
};

//ResetTimer component & states *****************************************************************************************

async function ResetTimer(_account, _attackerId) {
  setLoading(true); 
  if (condition) {

    let tx;    
    try {
      const transaction = prepareContractCall({ 
        contract, 
        method: "function resetFunctionCalls(uint256 _playerId)", 
        params: [defenderId] 
      });
      sendTransaction(transaction);
      
      } catch (err) {
      setLoading(false);        
      setErrorMessage("Failed? Contract paused or can't reset timer twice in 24hrs!");       
      setErrorMessageVisible(true);
      } 
  } else {
        
      battledog.methods
      .resetFunctionCalls(_attackerId)
      .send(gasoptions)
      .once("error", (err) => {
        setLoading(false);        
        setErrorMessage("Failed? Contract paused or can't reset timer twice in 24hrs!");       
        setErrorMessageVisible(true);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});       
          setErrorMessageVisible(true);
      } else {
          allOwnerOfPlayersTx();
          handleState(_attackerId);
          setErrorMessage("Reset successful!");
          setErrorMessageVisible(true);
      }
      }).catch((error) => {
        const err = error.toJSON();
        if (!err.cause) {
          setErrorMessage(err.code.toString()+": "+err.name.toString());
          setErrorMessageVisible(true);           
        } else {
          setErrorMessage(err.cause.message.toString());
          setErrorMessageVisible(true);
        } 
        setLoading(false);
      });
      
  }     
};

const handleResetTimerUpdateClick = (e) => {
  e.preventDefault();
  if (loading) return;
  gasOptions();

  const totalSupply = allPlayers - 1;

   // Check ID is not an empty string
   if (attackerId === "") {
    setErrorMessage("IDs cannot be empty.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is a valid integer
  if (!integerRegex.test(attackerId)) {
    setErrorMessage("Only battledog IDs are accepted.");
    setErrorMessageVisible(true);
    return;
  }

  // Check ID is greater than 0 and less than or equal to totalSupply
  if (attackerId <= 0  || attackerId > totalSupply) {
    setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
    setErrorMessageVisible(true);
    return;
  }

  // Check if the player owns the Battledog
  const ownedbattledogs = allOwnerOfPlayers.filter(player => player.id.toString() === attackerId);
  if (ownedbattledogs.length === 0) {
    setErrorMessage("You do not own this Battledog.");
    setErrorMessageVisible(true);
    return;
  }

  // Everything is valid, assault the Battledog
  ResetTimer(account.address, attackerId);
}; 

// Lock component & states*********************************************************************************************

const [allowanceImage, setAllowanceImage] = useState(null);
const [divisionalTitle, setDivisionalTitle] = useState("");
const [query, setQuery] = useState(false);
const [isLoading, setIsLoading] = useState(false);

const handleQuery = () => {
  setQuery(!query);
  playFastwhoosh();
}

  const fetchAllowanceData = async () => {
    try {
      let divisionalTitle = '';
      let msg = userAllowance.toFixed(blockchain.tofixed);

      if (userAllowance === 0) {
        setAllowanceImage("nonapproved");
        divisionalTitle = `All Txs will fail: ${msg} approved of ${balOwned} ${blockchain.tokenA} available for gameplay.`;
      } else if (availBalance === 0) {            
        setAllowanceImage("nonapproved");
        divisionalTitle = `All Txs will fail: ${msg} approved of ${balOwned} ${blockchain.tokenA} available for gameplay.`;
      } else if (userAllowance > healthLimit && availBalance !== 0) {
        setAllowanceImage("approved");
        divisionalTitle = `Healthy: ${msg} approved of ${balOwned} ${blockchain.tokenA} available for gameplay.`;
      } else if (userAllowance > requiredAmount && userAllowance < healthLimit && availBalance !== 0) {
        setAllowanceImage("disapproved");
        divisionalTitle = `Re-Approve: Below healthy limit. ${msg} approved of ${balOwned} ${blockchain.tokenA} available for gameplay.`;
      } else if (userAllowance < requiredAmount && availBalance !==0 ) {
        setAllowanceImage("unapproved");
        divisionalTitle = `Too Low: Less than required! ${msg} approved of ${balOwned} ${blockchain.tokenA} available for gameplay.`;
      }

      setDivisionalTitle(divisionalTitle);

    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  
// Scoreboard component & states *********************************************************************************************
const [scoreAssaultMap, setScoreAssaultMap] = useState(false);
const [scoreDebilitateMap, setScoreDebilitateMap] = useState(false);

const handleScoreSubmit = async (e, func, _player) => {
  e.preventDefault();
  setAttackerId('');
  try {  
    await func(_player); 
  } catch (err) {
    console.error(err);
  }
};  

const handleScoreSubmitter = async (e, func, player) => {
  e.preventDefault();
  setLoading(true);
  try {  
    await func(player); 
  } catch (err) {
    console.error(err);
  }
  setLoading(false);
}; 

const getStats = async (playerId) => {
  let leaderboard = [];
  setShowImage(false);   
  try {
  const player = await readContract({ 
    contract, 
    method: "function players(uint256) view returns (string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)", 
    params: [playerId] 
  });
  leaderboard.push(player);
  setSelectedPlayerStats(leaderboard);
  } catch (err) {    
    console.error(err);
  }
};

const handleView = () => {
  setShowImage(!showImage);
} 

const hideView = () => {
  setSelectedPlayerStats();
  setScoreAssaultMap(false);
  setScoreDebilitateMap(false);
}

const handleScoreAssault = () => {
  setScoreDebilitateMap(false);
  setScoreAssaultMap(true);
}

const handleScoreDebilitate = () => {
  setScoreAssaultMap(false);
  setScoreDebilitateMap(true);
}

const renderAssault = () => {
  return ownedBattledogs && (
      <>
      {ownedBattledogs.map((player, index) => {
        return (
          <div key={index} className={`scoreText twhite scoreassault${loading}`} disabled={loading ? 1 : 0}>
            with<br /> 
            <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftImage"}
             onClick={(e) => handleScoreSubmit(e, handleAssaultUpdateClick, player.id.toString())}
            style={{backgroundColor: "white", margin: "0.5vh", padding: '0.5vh', borderRadius: "25px"}}/><br /> 
            <span>BID {player.id.toString()}</span>
          </div>
        );
      })}    
      </>
    )} 

const renderDebilitate = () => {
  return ownedBattledogs && (
      <>
      {ownedBattledogs.map((player, index) => {
        return (
          <div key={index} className={`scoreText twhite scoredebilitate${loading}`} disabled={loading ? 1 : 0}>
            with<br />
            <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftImage"}
             onClick={(e) => handleScoreSubmit(e, handleDebilitateUpdateClick, player.id.toString())}
            style={{backgroundColor: "white", margin: "0.5vh", padding: '0.5vh', borderRadius: "25px"}}/><br />
            <span>BID {player.id.toString()}</span>
          </div>
        );
      })}    
      </>
    )} 

const LittleColumn = ({ className, children }) => {
  const columnRef = useRef(null);

  const handleColumnClick = (e) => {
    if (e.target === columnRef.current) {
      // selectedPlayer(true);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (columnRef.current && !columnRef.current.contains(e.target)) {
        setSelectedPlayerStats();
        // setShowImage(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [loading]);

  return (
    <div
      className={`little-column ${selectedPlayer ? "selectedPlayer" : null}`}
      onClick={handleColumnClick}
      ref={columnRef}
    >
      {selectedPlayer && children}
    </div>
  );
}; 

// ActivityTracker component & states **********************************************************************************

const [tokenIds, setTokenIds] = useState([]); 
const [assaulters, setAssaulters] = useState([]);
const [debilitators, setDebilitators] = useState([]);
const [names, setNames] = useState([]);
const [selectedEntry, setSelectedEntry] = useState(null);


const getAssaulters = async () => {
  setLoading(true);
    let newAssaulters = [];
    try {
      for (const tokenId of tokenIds) {        
        const result = await contractBattledog.getAssaulters(tokenId);
        newAssaulters.push(result.toString());
        console.log("result: ", result.toString());
      }
      setAssaulters(newAssaulters);
      setLoading(false);
      console.log("newAssaulters: ", newAssaulters);
    } catch (error) {
    console.error("Error fetching assaulters:", error);
    setLoading(true);
  };
}

const getDebilitators = async () => {
  setLoading(true);
    let newDebilitators = [];
    try {
      for (const tokenId of tokenIds) {

        const result = await contractBattledog.getDebilitators(tokenId);
        
        newDebilitators.push(result.toString()); 
        console.log("result: ", result.toString());
      }
      setDebilitators(newDebilitators);
      setLoading(false);
      console.log("newDebilitators: ", newDebilitators);
    } catch (error) {
      console.error("Error fetching assaulters:", error);
      setLoading(true);
    };
};

const handleActivityTrackerSubmit = (_digit) => {
  try {
    const inputTokenIds = _digit.split(",");
    const uniqueTokenIds = [...tokenIds];
    for (const tokenId of inputTokenIds) {
        if (!uniqueTokenIds.includes(tokenId)) {
            uniqueTokenIds.push(tokenId);
        }
      }
      setTokenIds(uniqueTokenIds);
  } catch (e) {
    console.error(e.message)
  }
};

const handleRemove = (_digit) => {
  try {
    const inputTokenIds = _digit.split(",");
    setTokenIds(tokenIds.filter(id => !inputTokenIds.includes(id)));
  } catch (e) {
    console.error(e.message)
  }
};    

const viewAll = (e) => {
  e.preventDefault();
  handleTrackerView();
} 

const handleWipe = (e) => {
  e.preventDefault();
  try {
    const empty = [];
    setTokenIds(empty);
  } catch (e) {
    console.error(e.message);
  }
};

const handleTrackerView = () => (
  <div>
    <Desktop>
    {tokenIds.map((id, index) => (
      <div key={index}> 
        <div className="trackView" title={`Tracking Battledog ${id}`}
          style={{              
            backgroundImage: `url(https://battledogs.fun/engine/${id}.png)`,
            backgroundSize: "cover",
            color: `${blockchain.theme}`,
            border: `1px solid ${blockchain.theme}`
          }}> 
          {id} 
        </div>
      </div>
    ))}
    </Desktop>
    <Mobile>        
    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      {tokenIds.map((id, index) => (
        <div key={index}> 
          <div className="trackView" title={`Tracking Battledog ${id}`}
            style={{              
              backgroundImage: `url(https://battledogs.fun/engine/${id}.png)`,
              backgroundSize: "cover",
              color: `${blockchain.theme}`,
              border: `1px solid ${blockchain.theme}`
            }}> 
            {id}
          </div>
        </div>
      ))}
      <span style={{color: "white", fontSize: "30px"}}><img src={require("./assets/images/eye.gif")} style={{height: '8vh'}} /></span>
    </div>
    </Mobile>
  </div>
); 

const getPlayerNames = async (_attacker, _defender) => {  
  try {
    const attacker = await contractBattledog.players(_attacker);
    const defender = await contractBattledog.players(_defender);

    return {
      attackerName: attacker.name,
      defenderName: defender.name,
    };
   
  } catch (error) {
    console.error("Error fetching player owners:", error);
    // throw error;
    return;
  }
};

const handleAssaulter = async (_attacker, _defender, entry) => {
  const playerNames = await getPlayerNames(_attacker, _defender);
  const namesArray = Object.values(playerNames);
  setNames(namesArray);
  setSelectedEntry(entry);
};

const handleDebilitator = async (_attacker, _defender, entry) => {
  const playerNames = await getPlayerNames(_attacker, _defender);
  const namesArray = Object.values(playerNames);
  setNames(namesArray);
  setSelectedEntry(entry);  
};

const assaultData = [];
assaulters.forEach(entry => {
  const values = entry.split(",");
  for (let i = 0; i < values.length; i += 4) {
    const timestamp = values[i+3];
    const date = new Date(timestamp * 1000);
    const formattedDate = moment(date).format('MM/DD/YYYY');
    const assault = {
      Value: [values[i], values[i+1], values[i+2], formattedDate]
    };
    assaultData.push(assault);
  }
});

const debilitateData = [];
debilitators.forEach(entry => {
  const values = entry.split(",");
  for (let i = 0; i < values.length; i += 4) {
    const timestamp = values[i+3];
    const date = new Date(timestamp * 1000);
    const formattedDate = moment(date).format('MM/DD/YYYY');
    const debilitate = {
      Value: [values[i], values[i+1], values[i+2], formattedDate]
    };
    debilitateData.push(debilitate);
  }
});

const mappedAssaulters = assaultData.reverse().map((assault, index) => {
  const attackerIds = assault.Value[0];
  const defenderIds = assault.Value[1];
  const stolenPoint = assault.Value[2];
  const formattedDate = moment(assault.Value[3]).format("MMMM Do YYYY");

  if (stolenPoint > 0) {

    return (
      <>
        <Desktop>
          <div key={index} className="a-format mkMicro" onClick={() => handleAssaulter(attackerIds, defenderIds, index)}>
          <span style={{border: 'solid 0.2vh white', backgroundColor: `${blockchain.theme}`, borderRadius: '25vh', padding: '0.1vh'}}><GiZeusSword/></span> Battledog <span style={{color: `${blockchain.theme}`}}>{attackerIds}</span> attacked Battledog <span style={{color: `${blockchain.theme}`}}>{defenderIds}</span> for <span style={{color: `${blockchain.theme}`}}>{stolenPoint}</span> points <br /> 
            <span style={{color: `${blockchain.theme}`, fontFamily: 'NexaHeavy', fontSize: '1.5vh'}}>{formattedDate}</span>
            {selectedEntry === index && names.length > 0 && (
              <div className="aBoard mkMicro">
                <div className="fcs">
                  <img src={`https://battledogs.fun/engine/${attackerIds}.png`} className="notifyIcon brblack" /> 
                  <img src={require("./assets/images/vs.gif")} className="vs" /> 
                  <img src={`https://battledogs.fun/engine/${defenderIds}.png`} className="notifyIcon brblack" />
                </div>
                <div style={{alignItems: "center"}} >
                  {names[0]} <span className="gtext">assaults</span> {names[1]}
                </div>
              </div>
            )}
          </div>
        </Desktop>
        <Mobile>
          <div key={index} className="a-format mkNano" onClick={() => handleAssaulter(attackerIds, defenderIds, index)}>
          <span style={{border: 'solid 0.2vh white', backgroundColor: `${blockchain.theme}`, borderRadius: '25vh', padding: '0.1vh'}}><GiZeusSword/></span> Battledog <span style={{color: `${blockchain.theme}`}}>{attackerIds}</span> attacked Battledog <span style={{color: `${blockchain.theme}`}}>{defenderIds}</span> for <span style={{color: `${blockchain.theme}`}}>{stolenPoint}</span> points <br />
            <span style={{color: `${blockchain.theme}`, fontFamily: 'NexaHeavy', fontSize: '1.5vh'}}>{formattedDate}</span> 
            {selectedEntry === index && names.length > 0 && (
              <div className="a-mobile mkNano">
                <div className="fcs">
                  <img src={`https://battledogs.fun/engine/${attackerIds}.png`} width="50%" /> 
                  <img src={require("./assets/images/vs.gif")} className="vsmobile" /> 
                  <img src={`https://battledogs.fun/engine/${defenderIds}.png`} width="50%" />
                </div>
                <div style={{alignItems: "center"}} >
                  {names[0]} <span className="">assaults</span> {names[1]}
                </div>
              </div>
            )}
          </div>
        </Mobile>
      </>     
      );
  }
});

const mappedDebilitators = debilitateData.reverse().map((debilitate, index) => {
  const attackerIds = debilitate.Value[0];
  const defenderIds = debilitate.Value[1];
  const stolenPoint = debilitate.Value[2];
  const formattedDate = moment(debilitate.Value[3]).format("MMMM Do YYYY");

  if (stolenPoint > 0) {

    return (
      <>
        <Desktop>
          <div key={index} className="d-format mkMicro" onClick={() => handleDebilitator(attackerIds, defenderIds, index)}>
            <span style={{border: 'solid 0.2vh white', backgroundColor: `${blockchain.theme}`, borderRadius: '25vh', padding: '0.1vh'}}><Gi3dHammer /></span> Battledog <span style={{color: "cyan"}}>{attackerIds}</span> debilitated Battledog <span style={{color: "cyan"}}>{defenderIds}</span> for <span style={{color: "cyan"}}> {stolenPoint} </span> points <br />
            <span style={{color: "cyan", fontFamily: 'NexaHeavy', fontSize: '1.5vh'}}>{formattedDate}</span> 
            {selectedEntry === index && names.length > 0 && (
            <div className="dBoard mkMicro" style={{color: `${blockchain.theme}`, backgroundImage: `linear-gradient(black, ${blockchain.theme}, black)`}}>
              <div className="fcs">
                <img src={(`https://battledogs.fun/engine/${attackerIds}.png`)} className="notifyIcon brblack" /> 
                <img src={require("./assets/images/vs.gif")} className="vs" /> 
                <img src={(`https://battledogs.fun/engine/${defenderIds}.png`)} className="notifyIcon brblack" />
              </div>
              <div style={{alignItems: "center"}} >
              {names[0]} <span className="gtext">debilitates</span> {names[1]}
              </div>
              
            </div>
          )}
          </div>
        </Desktop>
        <Mobile>
          <div key={index} className="d-format mkNano" onClick={() => handleDebilitator(attackerIds, defenderIds, index)}>
          <span style={{border: 'solid 0.2vh white', backgroundColor: `${blockchain.theme}`, borderRadius: '25vh', padding: '0.1vh'}}><Gi3dHammer /></span> Battledog <span style={{color: "cyan"}}>{attackerIds}</span> debilitated Battledog <span style={{color: "cyan"}}>{defenderIds}</span> for <span style={{color: "cyan"}}> {stolenPoint} </span> points <br /> 
            <span style={{color: "cyan", fontFamily: 'NexaHeavy', fontSize: '1.5vh'}}>{formattedDate}</span> 
            {selectedEntry === index && names.length > 0 && (
            <div className="d-mobile mkNano"  style={{color: `${blockchain.theme}`, backgroundImage: `linear-gradient(black, ${blockchain.theme}, black)`}}>
              <div className="fcs">
                <img src={(`https://battledogs.fun/engine/${attackerIds}.png`)} width="50%" /> 
                <img src={require("./assets/images/vs.gif")} className="vsmobile" /> 
                <img src={(`https://battledogs.fun/engine/${defenderIds}.png`)} width="50%" />
              </div>
              <div style={{alignItems: "center"}} >
              {names[0]} <span className="gtext">debilitates</span> {names[1]}
              </div>
              
            </div>
          )}
          </div>

        </Mobile>
      </>      
    );
  }
});


useEffect(() => {
  if (isStored) return;
  const storedTokenIds = localStorage.getItem("tokenIds");
  if (storedTokenIds) {
      isStored = true;
    setTokenIds(JSON.parse(storedTokenIds));
      isStored = false;
  }
}, []);

useEffect(() => {
  if(isSetting) return;
    isSetting = true;
  localStorage.setItem("tokenIds", JSON.stringify(tokenIds));
    isSetting = false;
}, [tokenIds]);

// Component functions*******************************************************************************************************
  
const welcomeGif = () => {
  return (
    <div>
      <Desktop>        
        <div className="centerAlign" align='center'>
              <img src={require(`./assets/images/connected.gif`)} className="connectedGif" style={{height: "10vh", width: "80vw"}}/> 
        </div>
      </Desktop>
      <Mobile>                          
        <div className="centerAlign" align='center'>
            <img src={require(`./assets/images/connected.gif`)} className="connectedGif" style={{height: "10vw", width: "80vw"}}/>
        </div>
      </Mobile>
    </div>   
  )
};

const ApproveNative = () => {  
  useEffect(() => {
    if (condition && isWaiting && transactionResult) { 
    if (isApproveNative) return;
        isApproveNative = true;
      setErrorMessage("Done!");
      setErrorMessageVisible(true);
        isApproveNative = false;

      setIsWaiting(false);
      }
  },[isWaiting]);
  return (
    <>
      <Desktop>      
      <div className="menu4" style={{color: `${blockchain.theme}`}}>
      <div >
      <div>
      <div title={`Approve Access to ${blockchain.tokenA} tokens`}>
        <img src={require("./assets/images/icons/approval.png")} className={`permissionicons loadbearer${isWaiting}`}  
        onClick={() => {setShowSwap(false); handleShowForm()}} /></div>
      </div>
              {showForm && !showSwap && (
                <form autoComplete="on" onSubmit={(e) => handleSubmitter(e, approve)} className={`loadbearer${isWaiting}`}>
                <div>
                  <input autoFocus  name="input1"
                    className="inputform"
                    style={{ color: "white", border: "3px solid goldenrod" }}
                    type="text"
                    placeholder={`...best over ${blockchain.bestover} ${blockchain.tokenA} tokens`}
                    alt="Approval Amount"
                  />
                </div>
                  <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Submit</button>
                  <button className={"button0"} type="button" onClick={handleHideForm} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
                </form>
              )}
        </div>           
      {blockchain.wrap && (
        <>
          <Wrapper />
        </>
      )}
      <div className=" box3" title={`Revoke Access to your ${blockchain.tokenA} tokens`} >
      <img src={require("./assets/images/icons/revoke.png")} className={`permissionicons loadbearer${isWaiting}`} onClick={revoke} /></div><br /> 
      </div>
      </Desktop>
      <Mobile>
        <div className="spinner" onMouseEnter={handleMouseEnter} align='center' style={{marginTop: "6vh"}}>
        <img src={require("./assets/images/icons/approval.png")} style={{height: "40px"}} 
          onClick={() => {setShowSwap(false); handleShowForm()}} title={`Approve Access to ${blockchain.tokenA} tokens`} className={`loadbearer${isWaiting}`}/>
        <img src={require("./assets/images/icons/revoke.png")}  style={{height: "40px"}} title={`Revoke Access to your ${blockchain.tokenA} tokens`} 
          onClick={revoke} className={`loadbearer${isWaiting}`}/>            
        {blockchain.wrap && (
            <>
              <Wrapper />
            </>
        )}    
              {showForm && !showSwap && (
                <form autoComplete="on" onSubmit={(e) => handleSubmitter(e, approve)} className={`loadbearer${isWaiting}`}>
                  <div>
                    <input autoFocus   name="input1"
                      className={"inputformMb"}
                      style={{ color: "white", border: "3px solid goldenrod" }}
                      type="text"
                      placeholder={`...best over ${blockchain.bestover} ${blockchain.tokenA} tokens`}
                      alt="Approval Amount"
                    />
                  </div>
                  <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Submit</button>
                  <button className={"button0"} type="button" onClick={handleHideForm} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
                </form>
              )}
            </div>
      </Mobile>
    </>
  )
};

const handleSubmitter = async (e, func) => {
  e.preventDefault();
  setLoading(true);
  const digit = e.target.input1.value;
  if (!digit) {
    return;
  }

  try {  
  await func(digit); 
  setShowForm(false); 
  } catch (err) {
    console.error(err);
  }
  setLoading(false);
  setShowForm(false); 
};

const ApproveGame = () => {
  useEffect(() => {
    if (condition && isWaiting && transactionResult) { 
    if (isApproveGame) return;
          isApproveGame = true;
        setErrorMessage("Done!");
        setErrorMessageVisible(true);
          isApproveGame = false;
          
        setIsWaiting(false);
      }
  },[isWaiting]);
  return (
    <>
      <Desktop>
          <div className="menu4" style={{color: `${blockchain.theme}`}}>      
              <div >
                <div onClick={handleShowForm}>
                  <div className="" title="Approve Access to GAME Tokens">
                    <img src={require("./assets/images/icons/approval.png")} className={`permissionicons loadbearer${isWaiting}`}  disabled={isWaiting ? 1 : 0}/>
                  </div>
                </div>
                  {showForm && (
                    <form autoComplete="on" onSubmit={(e) => handleSubmitter(e, approveGame)} className={`loadbearer${isWaiting}`}>
                      <div>
                        <input autoFocus  name="input1"
                          className="inputform"
                          style={{ color: "white", border: "3px solid goldenrod" }}
                          type="text"
                          placeholder="Approve GAME to Level Up"
                          alt="Approval Amount"
                        />
                      </div>
                      <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Submit</button>
                      <button className={"button0"} type="button" onClick={handleHideForm} style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
                    </form>
                  )}
              </div>
                <br />
              <div className="box3" title="Revoke Access to GAME tokens" >
              <img src={require("./assets/images/icons/revoke.png")} className={`permissionicons loadbearer${isWaiting}`} onClick={revokeGame} disabled={isWaiting ? 1 : 0}/><br /> 
              </div>
          </div>
      </Desktop>
      <Mobile>
        <div className='mobileapprove' align="center">
          <div className="spinner">
            <img src={require("./assets/images/icons/approval.png")} style={{height: "40px"}} title="Approve Access to your GAME tokens" onClick={handleShowForm} className={`loadbearer${isWaiting}`} disabled={isWaiting ? 1 : 0}/> 
            <img src={require("./assets/images/icons/revoke.png")} style={{height: "40px"}} title="Revoke Access to your GAME tokens" onClick={revokeGame} className={`loadbearer${isWaiting}`} disabled={isWaiting ? 1 : 0}/>
            {showForm && (
              <form autoComplete="on" onSubmit={(e) => handleSubmitter(e, approveGame)} className={`loadbearer${isWaiting}`} >
                <div>
                  <input autoFocus  name="input1"
                    className="inputformMb"
                    style={{ color: "white", border: "3px solid goldenrod" }}
                    type="text"
                    placeholder="Approve GAME to Level Up"
                    alt="Approval Amount"
                    />
                </div>
                  <button className={"button1"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Submit</button>
                  <button className={"button0"} type="button" onClick={handleHideForm}  style={{border: `2px solid ${blockchain.theme}`}}>Cancel</button>
              </form>
            )}
          </div>
          </div>
      </Mobile>
    </>
  )
};

const StartMenuComponent = () => {
  return (
    <>
      <Desktop> 
        <div className="alignCenter">
            <div className={`startMenu smbkg${startMenuBkg}`} align="center">
              <button className="startMenuBox box1bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(0); setErrorMessage("View Your Battledogs"); setErrorMessageVisible(true)}} onClick={handleArsenal}><span >Arsenal</span></button>
              <button className="startMenuBox box2bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(7); setErrorMessage("Get Started"); setErrorMessageVisible(true)}} onClick={handleStart}><span >Start</span></button>
              <button className="startMenuBox box3bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(2); setErrorMessage("Visit The Arena"); setErrorMessageVisible(true)}} onClick={handleArena}><span >Arena</span></button>
              <button className="startMenuBox box4bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(3); setErrorMessage("Mint a Battledog"); setErrorMessageVisible(true)}} onClick={handleMint}><span >Quick Mint</span></button> 
              <button className="startMenuBox box7bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(14); setErrorMessage("Find other Battledogs"); setErrorMessageVisible(true)}} onClick={handleScoreboard}><span >Score board</span></button>
              <button className="startMenuBox box5bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(4); setErrorMessage("Manage Your Wins"); setErrorMessageVisible(true)}} onClick={handleWinnings}><span >Winnings</span></button>
              <button className="startMenuBox box6bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(5); setErrorMessage("Harvest, Mine or Customize"); setErrorMessageVisible(true)}} onClick={handleTools}><span >Tools</span></button>
              <button className="startMenuBox box8bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(18); setErrorMessage("Track Another Battledog"); setErrorMessageVisible(true)}} onClick={handleActivityTracker}><span >Activity Tracker</span></button>
            </div>
            {/* <div className="mkMicro tgold" align="center">Quick Menu</div> */}
            <div className="expressMenu" align="center">
              <button className="expressMenuBox box1bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(0); setErrorMessage("View Battledogs"); setErrorMessageVisible(true)}} onClick={handleArsenal}><span className="mkAtom twhite">Arsenal</span></button>
              <button className="expressMenuBox mainBackground4" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(7); setErrorMessage("Activate a Battledog"); setErrorMessageVisible(true)}} onClick={handleActivateHelp}><span className="mkAtom twhite">Activate Up</span></button>
              <button className="expressMenuBox mainBackground9" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(11); setErrorMessage("Regenerate a Battledog"); setErrorMessageVisible(true)}} onClick={handleRegenerateHelp}><span className="mkAtom twhite">Regenerate</span></button>
              <button className="expressMenuBox mainBackground8" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(10); setErrorMessage("Weaponize a Battledog"); setErrorMessageVisible(true)}} onClick={handleWeaponizeHelp}><span className="mkAtom twhite">Weaponize</span></button>
              <button className="expressMenuBox mainBackground5" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(3); setErrorMessage("Assault a Battledog"); setErrorMessageVisible(true)}} onClick={handleAssaultHelp}><span className="mkAtom twhite">Assault</span></button>
              <button className="expressMenuBox mainBackground6" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(8); setErrorMessage("Debilitate a Battledog"); setErrorMessageVisible(true)}} onClick={handleDebilitateHelp}><span className="mkAtom twhite">Debilitate</span></button>
              <button className="expressMenuBox mainBackground1" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(15); setErrorMessage(`Harvest ${blockchain.tokenA}`); setErrorMessageVisible(true)}} onClick={handleHarvester}><span className="mkAtom twhite">Harvest</span></button>
              <button className="expressMenuBox mainBackground15" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(16); setErrorMessage("Mine GAME"); setErrorMessageVisible(true)}} onClick={handleMining}><span className="mkAtom twhite">Mining</span></button>
              <button className="expressMenuBox mainBackground12" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(6); setErrorMessage("Level Up a Battledog"); setErrorMessageVisible(true)}} onClick={handleLevelUpHelp}><span className="mkAtom twhite">Level Up</span></button>
              <button className="expressMenuBox mainBackground10" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(12); setErrorMessage("Claim An Assault Win"); setErrorMessageVisible(true)}} onClick={handleClaimAssaultHelp}><span className="mkAtom twhite">Claim Assault</span></button>
              <button className="expressMenuBox mainBackground11" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(13); setErrorMessage("Claim A Debilitate Win"); setErrorMessageVisible(true)}} onClick={handleClaimDebilitateHelp}><span className="mkAtom twhite">Claim Debilitate</span></button>
              <button className="expressMenuBox mainBackground7" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(4); setErrorMessage(`Cashout ${blockchain.tokenA}`); setErrorMessageVisible(true)}} onClick={handlePayoutHelp}><span className="mkAtom twhite">Cashout</span></button>
              <button className="expressMenuBox mainBackground13" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(11); setErrorMessage("Customize a Battledog"); setErrorMessageVisible(true)}} onClick={handleCustomizeHelp}><span className="mkAtom twhite">Customize</span></button>
              <button className="expressMenuBox mainBackground16" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(5); setErrorMessage("Reset Lock Timer"); setErrorMessageVisible(true)}} onClick={handleResetTimerHelp}><span className="mkAtom twhite">Reset Timer</span></button>
            </div>
        </div>
            <div align='center' style={{position: 'absolute', bottom: '0.1vh', width: '100vw', margin: '2vh 0vw 0vh 0vw'}}>
            {errorMessageVisible && 
                    <div className="errordisplay mkMid" style={{color: `${blockchain.theme}`}}>{errorMessage}</div> 
            }
            </div>
      </Desktop>
      <Mobile>
       <div align='center'>
            <div className={`startMenuMobile smbkg${startMenuBkg}`} align="center" style={{backgroundColor: `whitesmoke`}}>
              <button className="startMenuBoxMobile box1bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(0); setErrorMessage("View Your Battledogs"); setErrorMessageVisible(true)}} onClick={handleArsenal}><span className="mkNano twhite">Arsenal</span></button>
              <button className="startMenuBoxMobile box2bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(7); setErrorMessage("Get Started"); setErrorMessageVisible(true)}} onClick={handleStart}><span className="mkNano twhite">Start</span></button>
              <button className="startMenuBoxMobile box3bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(2); setErrorMessage("Visit The Arena"); setErrorMessageVisible(true)}} onClick={handleArena}><span className="mkNano twhite">Arena</span></button>
              <button className="startMenuBoxMobile box4bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(3); setErrorMessage("Mint a Battledog"); setErrorMessageVisible(true)}} onClick={handleMint}><span className="mkNano twhite">Quick Mint</span></button> 
              <button className="startMenuBoxMobile box7bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(14); setErrorMessage("Find other Battledogs"); setErrorMessageVisible(true)}} onClick={handleScoreboard}><span className="mkNano twhite">Score board</span></button>
              <button className="startMenuBoxMobile box5bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(4); setErrorMessage("Manage Your Wins"); setErrorMessageVisible(true)}} onClick={handleWinnings}><span className="mkNano twhite">Winnings</span></button>
              <button className="startMenuBoxMobile box6bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(5); setErrorMessage("Harvest, Mine or Customize"); setErrorMessageVisible(true)}} onClick={handleTools}><span className="mkNano twhite">Tools</span></button>
              <button className="startMenuBoxMobile box8bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(18); setErrorMessage("Track Another Battledog"); setErrorMessageVisible(true)}} onClick={handleActivityTracker}><span className="mkNano twhite">Activity Tracker</span></button>
            </div>
            <div className="mkMicro tblack" align="center">Quick Menu</div>
            <div className="expressMenuMobile" align="center">
              <button className="expressMenuBoxMobile box1bkg" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(0)}} onClick={handleArsenal}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Arsenal</span></button>
              <button className="expressMenuBoxMobile mainBackground4" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(7)}} onClick={handleActivateHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Activate Up</span></button>
              <button className="expressMenuBoxMobile mainBackground9" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(11)}} onClick={handleDebilitateHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Regenerate</span></button>
              <button className="expressMenuBoxMobile mainBackground8" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(10)}} onClick={handleWeaponizeHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Weaponize</span></button>
              <button className="expressMenuBoxMobile mainBackground5" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(3)}} onClick={handleAssaultHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Assault</span></button>
              <button className="expressMenuBoxMobile mainBackground6" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(8)}} onClick={handleDebilitateHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Debilitate</span></button>
              <button className="expressMenuBoxMobile mainBackground1" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(15)}} onClick={handleHarvester}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Harvest</span></button>
              <button className="expressMenuBoxMobile mainBackground15" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(16)}} onClick={handleMining}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Mining</span></button>
              <button className="expressMenuBoxMobile mainBackground12" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(6)}} onClick={handleLevelUpHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Level Up</span></button>
              <button className="expressMenuBoxMobile mainBackground10" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(12)}} onClick={handleClaimAssaultHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Claim Assault</span></button>
              <button className="expressMenuBoxMobile mainBackground11" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(13)}} onClick={handleClaimDebilitateHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Claim Debilitate</span></button>
              <button className="expressMenuBoxMobile mainBackground7" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(4)}} onClick={handlePayoutHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Cashout</span></button>
              <button className="expressMenuBoxMobile mainBackground13" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(11)}} onClick={handleCustomizeHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Customize</span></button>
              <button className="expressMenuBoxMobile mainBackground16" onMouseEnter={() => {setIsHovered(true); setStartMenuBkg(5)}} onClick={handleResetTimerHelp}><span className="mkMolecule twhite" style={{backgroundColor: `${blockchain.theme}`}}>Reset Timer</span></button>
            </div>
       </div>
      </Mobile>
    </>   
  )
} 

  const LoginMenuComponent = () => {
    return (
      <>
        <Desktop>        
          <div className="centerAlign" align='center'>
            <div className="mkSmall">PLAY ON< br />
                <img src={require(`./assets/images/left_arrow.png`)} className={`arrowSelectorLeft leftOff${counter}`} onClick={dropNetwork} style={{height: "10vh"}}/>                  
                  <span className="mkLarge"> {blockchain.ecosystem} </span>          
                <img src={require(`./assets/images/right_arrow.png`)} className={`arrowSelectorRight rightOff${counter}`} onClick={plusNetwork} style={{height: "10vh"}}/>
            </div>
          </div>
        </Desktop>
        <Mobile>                          
          <div className="centerAlign" align='center'>
            <div className="mkSmall" style={{alignItems: "center", justifyContent: "center"}}>PLAY ON< br />           
              <span className="mkMedium">{blockchain.ecosystem}</span><br />  
              <img src={require(`./assets/images/left_arrow.png`)} className={`arrowSelectorLeft leftOff${counter}`} onClick={dropNetwork} style={{height: "10vw", margin: "0vw 1vh 0vw 1vh", backgroundColor: "goldenrod"}}/>
              <img src={require(`./assets/images/right_arrow.png`)} className={`arrowSelectorRight rightOff${counter}`} onClick={plusNetwork} style={{height: "10vw", margin: "0vw 1vh 0vw 1vh", backgroundColor: "goldenrod"}}/>
            </div>
          </div>
        </Mobile>
      </>   
    );
  };

  const ActivateComponent = () => {
      useEffect(()=> {
        if (condition && loading && transactionResult) {
          activateAfterCall();
        } 
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Start" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Go to Arsenal" onClick={handleArsenal}><img src={require("./assets/images/icons/arsenal.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(3)}} title="Mint a Battledog" onClick={handleMint}><img src={require("./assets/images/icons/mint.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(1)}} title="Activate a Battledog" onClick={handleActivateHelp}><img src={require("./assets/images/icons/activate.png")} className="icons" /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
            <div className={"translation"} style={{ display: "flex", flexDirection: "column", alignItems: "center" }} align="center" >
              
              {!attackerId && (
                <img src={require("./assets/images/activate.jpg")} className="portraitHTH" />
              )} 
              {attackerId && (
                <img src={`${TargetA}`} className="portraitHTH" />
              )} 
              
              <div>
                  <div style={{ padding: "2px" }}>
                    <input autoFocus 
                    className={"inputform twhite"}
                    style={{ border: "3px solid rgba(240, 240, 192, 0.7)" }}
                    placeholder="Battledog ID"
                    type="text"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Battledog ID"
                    />

                    {errorMessageVisible && 
                    <div className="errordisplay thatch" onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "15px" }} />
                    </div> } <br />

                    <button
                      className={"buttongold textstyle"}
                      onClick={handleActivateUpdateClick}
                      disabled={loading ? 1 : 0}
                      style={{backgroundColor: `${blockchain.theme}`, border: `2px solid ${blockchain.theme}` }}
                    >
                      {loading ? (<>Activating...</>) : (<>Activate</>)}  
                      
                    </button>
                </div>
              </div>

              </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div onMouseEnter={handleMouseEnter} title="Leave Start" onClick={handleStartMenu}><img src={require("./assets/images/icons/startreturn.png")} style={{height: "50px"}} /></div><br />
                <div onMouseEnter={handleMouseEnter} title="Go to Arsenal" onClick={handleArsenal}><img src={require("./assets/images/icons/arsenal.png")} style={{height: "50px"}} /></div><br />                
                <div onMouseEnter={handleMouseEnter} title="Mint a Battledog" onClick={handleMint}><img src={require("./assets/images/icons/mint.png")} style={{height: "50px"}} /></div><br />
                <div onMouseEnter={handleMouseEnter} title="Activate a Battledog" onClick={handleActivateHelp}><img src={require("./assets/images/icons/activate.png")} style={{height: "50px"}} /></div><br />
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                  <div className='mobilecontent'>
                  {!attackerId && (
                    <img src={require("./assets/images/activate.jpg")} className="portraitHTHMobile"/>
                  )} 
                  {attackerId && (
                    <img src={`${TargetA}`} className="portraitHTHMobile"/>
                  )} 
                  <input autoFocus 
                    className={"inputformMb twhite"}
                    style={{ border: "3px solid rgba(240, 240, 192, 0.7)" }}
                    placeholder="Battledog ID"
                    type="text"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Battledog ID"
                    />
                    
                    {errorMessageVisible && 
                    <div className="errordisplay thatch" onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "15px" }} />
                    </div> } 

                    <button
                      className={"buttongold textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={handleActivateUpdateClick}
                      style={{backgroundColor: `${blockchain.theme}`, border: `2px solid ${blockchain.theme}` }}
                    >
                    {loading ? (<>Activating...</>) : (<>Activate</>)}                        
                    </button>
                    
                  </div>
              </div> 
        </Mobile>
      </>   
    );
  } 

  const ArsenalComponent = () => {    
    return (
      <>
        <Desktop> 
          <div className={`menu2 smbkg${startMenuBkg}`} >
            <div onMouseEnter={handleMouseEnter} title="Return to Home" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div><br />
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(2)}} title="Go To Arena" onClick={handleArena}><img src={require("./assets/images/icons/gameplay.png")} className="icons" /></div><br />
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(4)}} title="Manage Winnings" onClick={handleWinnings}><img src={require("./assets/images/icons/manage.png")} className="icons" /></div><br />
            <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Toolspace" onClick={handleTools}><img src={require("./assets/images/icons/tools.png")} className="icons" /></div><br /> 
            <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
          </div>              
                          
            {allOwnerOfPlayers.length === 0 ? (
              <div className='arsenalDiv' style={{marginTop: "15%", alignItems: "center", justifyContent: "center"}}>
                <div style={{ color: "gold", fontSize: "20px" }} align={"center"}>
                  <img src={require(`./assets/images/progressbar.gif`)} style={{ height: "25px" }} />
                  <br/>
                  <img src={require(`./assets/images/nobdgs-${blockchain.theme}.gif`)} style={{ height: "55px" }} />
                  <br />
                  <p className={"linkbutton twhite"} onClick={handleMint}>Mint Your Battledog</p>                  
                    
                  {errorMessageVisible && 
                    <div className="errordisplay" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "25px" }} />
                    </div> 
                  } <br />

                </div>
              </div>
            ):(
              <div>
                <div className={"nftloader"}> 
                  {allOwnerOfPlayers.map((player, index) => {
                    return (
                      <div key={index} className={"texttheme"} style={{color: `${blockchain.theme}`}}>
                        <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftImage"} 
                          onClick={() => handleSelectedPlayerClick(player.id)} />
                        <br />
                        BID: {(player.id).toString()}
                      </div>
                    );}
                 )}
                </div>
              </div>
            )}
            {selectedPlayer && allOwnerOfPlayers && (
                <div className={"detailLoader"}> 
                    <div className={"texttheme black"}>
                      <img src={(`https://battledogs.fun/engine/${selectedPlayer}.png`)} className={"largeNFT"} />
                      <div className="namenotification namegtext">{allOwnerOfPlayers.find(p => p.id === selectedPlayer).name}</div>

                      <div className="featurespanel">
                        <div className="topfeature tpgtext">
                          {playerData.map((data, index) => (
                            <div key={index} >{currentIndex === index && data.trait_type}</div>
                          ))}
                        </div>
                        <div className={`bottomfeature bp${currentIndex}gtext`}>
                          {playerData.map((data, index) => (
                            <div key={index}>{currentIndex === index && data.value}</div>
                          ))}
                        </div>
                      </div>
                    </div>
                </div>
            )}          
            {selectedPlayer && reservePlayerData && (
                <div className='onchainData' align="center">                
                  <div onClick={handleMint} title="Click to Mint a New Battledog" className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Battledog ID</span><br/>
                    <span className='divgtext'>{selectedPlayer.toString()}</span>
                  </div>
                  <div onClick={handleLevelUpHelp} title="Click to Claim A New Level" className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Current Level</span><br/>
                    <span className='divgtext'>{reservePlayerData[0]}</span>
                  </div>
                  <div onClick={handleAssaultHelp} title="Click to Assault a Battledog" className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Aggression</span><br/>
                  <span className='divgtext'>{reservePlayerData[1]}</span>
                  </div>
                  <div onClick={handleDebilitateHelp} title="Click to Debilitate a Battledog" className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Stamina</span><br/>
                  <span className='divgtext'>{reservePlayerData[2]}</span>
                  </div>
                  <div title="Number of Unrewarded Battles" className='fitiem'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Current Fights</span><br/>
                  <span className='divgtext'>{reservePlayerData[3]}</span> 
                  </div>
                  <div title="Number of Claimed Wins" className='fitiem'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Total Wins</span><br/>
                  <span className='divgtext'>{reservePlayerData[4]}</span>
                  </div>
                  <div onClick={handlePayoutHelp} title={`You have ${((reservePlayerData[5])/reducer).toFixed(blockchain.tofixed)}${blockchain.excess} ${blockchain.tokenA} awaiting payout`} className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Pending Payout</span><br/>
                  <span className='divgtext'>{((reservePlayerData[5])/reducer).toFixed(blockchain.tofixed)}</span>
                  </div>
                  <div onClick={handleActivateHelp} title="Click to Activate a Battledog" className='fitiem point'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Activation Tier</span><br/> 
                  <span className='divgtext'>{reservePlayerData[6]}</span>
                  </div>
                  <div title="Total Number of Fights" className='fitiem'>
                  <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Fight History</span><br/>
                  <span className='divgtext'>{reservePlayerData[7]}</span>
                  </div>
                </div>
                )} 
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>            
                  <div title="Return to Home" onClick={handleStartMenu}><img src={require("./assets/images/icons/home.png")} style={{height: "50px"}}/></div><br />
                  <div title="Go To Arena" onClick={handleArena}><img src={require("./assets/images/icons/gameplay.png")} style={{height: "50px"}} /></div><br />
                  <div title="Manage Winnings" onClick={handleWinnings}><img src={require("./assets/images/icons/manage.png")} style={{height: "50px"}} /></div><br />
                  <div title="Toolspace" onClick={handleTools}><img src={require("./assets/images/icons/tools.png")} style={{height: "50px"}} /></div><br />                     
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"><img src={require("./assets/images/icons/guidebook.gif")} height="50px" /></a> </div>
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>    
                <div> 
                      {allOwnerOfPlayers.length === 0 ? (
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} >
                          <p style={{ color: "gold", fontSize: "20px" }} align="center">
                            <img src={require(`./assets/images/progressbar.gif`)} style={{ height: "30px" }} />
                            <br/>
                            <img src={require(`./assets/images/nobdgs-${blockchain.theme}.gif`)} style={{ height: "50px" }} />
                            <br />
                            <div onClick={handleMint} className={"linkbutton twhite"}>Mint Your Battledog</div>            
                    
                            {errorMessageVisible && 
                              <div className="errordisplay" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                              <img src={require(`./assets/images/retry.gif`)} style={{ height: "15px" }} />
                              </div> 
                            } <br />

                          </p>
                        </div>
                      ) : (
                        <div>
                          <div />
                          <div className={"nftfloater"}>
                            {allOwnerOfPlayers.map((player, index) => {
                              return (
                                <div key={index} className={"texttheme tgold"} style={{color: `${blockchain.theme}`}}>
                                  <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftImageMB"} 
                                  onClick={() => handleSelectedPlayerClick(player.id)} style={{border: `0.5vw solid ${blockchain.theme}`}}/>
                                  <br />
                                  BID: {(player.id).toString()}
                                </div>
                              );
                            })}
                          </div>
                          <div className='nftcarriage'>
                            {selectedPlayer && allOwnerOfPlayers &&
                              <div className={"texttheme black"}>
                                <img src={(`https://battledogs.fun/engine/${selectedPlayer}.png`)} 
                                className={"largeNFTmb"}/>
                                <div className="nftnamer namegtext">{allOwnerOfPlayers.find(p => p.id === selectedPlayer).name}</div>

                              </div>
                            }
                          </div>
                        </div>
                      )}  
                      </div>            
                      {selectedPlayer && reservePlayerData && (
                        <div className="detaildivmobile" align="center">          
                          <div className='mblb'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>BID</span><br/>
                            <span className='divgtext'>{selectedPlayer.toString()}</span>
                          </div>
                          <div className='mblb'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Fights</span><br/>
                            <span className='divgtext'>{reservePlayerData[3]}</span>
                          </div>
                          <div className='mblb'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Wins</span><br/>
                            <span className='divgtext'>{reservePlayerData[4]}</span>
                          </div>
                          <div className='mblb point'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Level</span><br/>
                            <span className='divgtext'>{reservePlayerData[0]}</span>
                          </div>
                          <div className='mblb point'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Aggression</span><br/>
                            <span className='divgtext'>{reservePlayerData[1]}</span>
                          </div>
                          <div className='mblb point'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Payout</span><br/>
                            <span className='divgtext'>{(reservePlayerData[5]/reducer).toFixed(blockchain.tofixed)}${blockchain.excess}</span>
                          </div>
                          <div className='mblb point'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Activation</span><br/>
                            <span className='divgtext'>{reservePlayerData[6]}</span>
                          </div>
                          <div className='mblb point'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>Stamina</span><br/>
                            <span className='divgtext'>{reservePlayerData[2]}</span>
                          </div>
                          <div className='mblb'>
                            <span className='mkNano twhite' style={{color: `${blockchain.theme}`}}>History</span><br/>
                            <span className='divgtext'>{reservePlayerData[7]}</span>
                          </div>
                        </div>
                      )}
                      {selectedPlayer && (
                        <div className="mobilelayer">
                          <div className="featurespanelMB">
                          <div className="topfeature tpgtext">
                            {playerData.map((data, index) => (
                              <div key={index} >{currentIndex === index && data.trait_type}</div>
                            ))}
                          </div>
                          <div className={`bottomfeature bp${currentIndex}gtext`}>
                              {playerData.map((data, index) => (
                                <div key={index}>{currentIndex === index && data.value}</div>
                              ))}
                          </div>
                          </div> 
                        </div>
                      )}
              </div> 
        </Mobile>
      </>   
    )
  } 
    
  const AssaultComponent = () => {
      useEffect(()=> {
        if (condition && loading && transactionResult) { 
            assaultAfterCall();
        }  
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Arena"><img src={require("./assets/images/bg/backarrow.gif")} className="icons"  onClick={handleStartMenu}/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(19)}} title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(8)}} title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(10)}} title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(11)}} title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} className="icons" /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
              <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">                
              {showImage && (
                  <img src={require("./assets/images/assaulter.gif")} className="portraitHTH" />
                )}   
                {!showImage && !defenderId && (                  
                  <div className="portraitHTH" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box", border: "3px solid darkred"}}>
                  <img src={require("./assets/images/notargetfound.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
                )}
                {!showImage && defenderId && (                  
                  <div className="portraitHTH" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box", border: "3px solid darkred"}}>
                  <img src={require("./assets/images/assaultarget.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
                )}     
                
              <div style={{padding: "2px"}}>
                  <input autoFocus  className={"inputform twhite"}
                    placeholder="Your Battledog ID"
                    type="text"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />
              </div>
              
              <div>
                  <input autoFocus  className={"inputform twhite"} style={{marginTop: "0px", border: "3px solid darkred"}}
                    placeholder=" Enemy ID"
                    type="text"
                    value={defenderId}
                    onChange={handleDefenderChange}
                    alt="Insert Enemy ID"
                  />
              </div>           
                    
              {errorMessageVisible && 
                <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                </div> 
              }

              <button className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={handleAssaultUpdateClick}              
                style={{backgroundColor: 'red' }}
              >
              {loading ? (<>Assaulting...</>) : (<>Assault</>)} 
                
              </button>

              </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Arena" onClick={handleStartMenu}><img src={require("./assets/images/icons/gameplayreturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} style={{height: "50px"}} /></div><br />
                <div title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} style={{height: "50px"}} /></div><br />
                <div title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} style={{height: "50px"}} /></div><br />
                <div title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} style={{height: "50px"}} /></div><br />
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                  <div className='mobilecontent' align="center">
                {showImage && (
                  <img src={require("./assets/images/assaulter.gif")} className="portraitHTHMobile"/>
                )}  
                {!showImage && !defenderId && (                  
                  <div className="portraitHTHMobile" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/notargetfound.png")} style={{height: '35vh', width: '35vh'}} />
                  </div>
                )}
                {!showImage && defenderId && (                  
                  <div className="portraitHTHMobile" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/assaultarget.png")} style={{height: '35vh', width: '35vh'}} />
                  </div>
                )}
                    <input autoFocus  className={"inputformMb twhite"} style={{border: "3px solid darkred"}}
                    placeholder="Your Battledog ID"
                    type="text"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />
                  <input autoFocus  className={"inputformMb twhite"} style={{border: "3px solid darkred", marginTop: "0px"}}
                  placeholder=" Enemy ID"
                  type="text"
                  value={defenderId}
                  onChange={handleDefenderChange}
                  alt="Insert Enemy ID"
                  />        
                    
                  {errorMessageVisible && 
                    <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                    </div> 
                  }
    
                    <button className={"buttongold textstyle"}
                    disabled={loading ? 1 : 0}
                    onClick={handleAssaultUpdateClick}
                    style={{backgroundColor: `${blockchain.theme}`, border: `2px solid ${blockchain.theme}` }}
                  >
                  {loading ? (<>Assaulting...</>) : (<>Assault</>)} 
                  </button>          
                 
                  </div>

              </div> 
        </Mobile>
      </>   
    );
  } 

  const ClaimAssaultComponent = () => {    
      useEffect(()=> {
        if (condition && loading && transactionResult) {
          claimAssaultAfterCall();
        }
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(12)}} title="Claim assaults for a win" onClick={handleClaimAssaultHelp}><img src={require("./assets/images/icons/claimAssault.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(13)}} title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(6)}} title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(4)}} title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} className="iconsbkg goldpayout"/></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div> 
            <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
                  {!defenderId && (
                    <img src={require("./assets/images/claimassault.png")} className="portraitHTH" />
                  )} 
                  {defenderId && (
                    <img src={`${TargetB}`} className="portraitHTH" />
                  )}           
                <div>            
                    <input autoFocus  className={"inputform twhite"} style={{border: "3px solid rgb(139, 102, 8)"}}
                      placeholder="Your Battledog ID"
                      type="text"
                      value={defenderId}
                      onChange={handleDefenderChange}
                      alt="Your Battledog ID"
                    />
                </div>        
                    
                    {errorMessageVisible && 
                      <div className="errordisplay tgold" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                      </div> 
                    }

                  <button className={"button1 textstyle"}
                  disabled={loading ? 1 : 0}
                  onClick={handleClaimAssaultUpdateClick}
                  style={{
                    border: `2px solid ${blockchain.theme}`}}>
                {loading ? (<>Claiming...</>) : (<>Claim Assault</>)} 
                </button>
            </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/icons/managereturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Claim assaults for a win" onClick={handleClaimAssaultHelp} ><img src={require("./assets/images/icons/claimAssault.gif")} style={{height: "50px"}} /></div><br />
                <div title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} style={{height: "50px"}} /></div>
                <div title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} style={{height: "50px"}} /></div>
                <div title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                  <div className='mobilecontent'>
                  {!defenderId && (
                    <img src={require("./assets/images/claimassault.png")} className="portraitHTH" />
                  )} 
                  {defenderId && (
                    <img src={`${TargetB}`} className="portraitHTHMobile"/>
                  )}       
                    <input autoFocus  className={"inputformMb twhite"} style={{border: "3px solid rgb(139, 102, 8)"}}
                    placeholder="Your Battledog ID"
                    type="text"
                    value={defenderId}
                    onChange={handleDefenderChange}
                    alt="Your Battledog ID"
                    />         
                    
                    {errorMessageVisible && 
                      <div className="errordisplay tgold" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                      </div> 
                    }    
                    
                    <button className={"button1 textstyle"}
                    disabled={loading ? 1 : 0}
                    onClick={handleClaimAssaultUpdateClick} 
                    style={{
                      border: `2px solid ${blockchain.theme}`}}>
                    {loading ? (<>Claiming...</>) : (<>Claim Assault</>)} 
                    </button>
                  </div>
              </div> 
        </Mobile>
      </>   
    );
  } 

  const ClaimDebilitateComponent = () => { 
      useEffect(()=> {
        if (condition && loading && transactionResult) {
          claimDebilitateAfterCall();
      }
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(12)}} title="Claim assaults for a win" onClick={handleClaimAssaultHelp}><img src={require("./assets/images/icons/claimAssault.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(13)}} title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(6)}} title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(4)}} title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} className="iconsbkg goldpayout"/></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div> 
          <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
              {!defenderId && (
                <img src={require("./assets/images/claimdb.png")} className="portraitHTH" />
              )} 
              {defenderId && (
                <img src={`${TargetB}`} className="portraitHTH" />
              )}                
              <div>
                  <input autoFocus  className={"inputform twhite"} style={{border: "3px solid rgb(74, 99, 108)"}}
                    placeholder="Your Battledog ID"
                    type="text"
                    value={defenderId}
                    onChange={handleDefenderChange}
                    alt="Your Battledog ID"
                  />
              </div>    
                    
              {errorMessageVisible && 
               <div className="errordisplay talmond" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
               <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
               </div> 
              }

                <button className={"button1 textstyle"}
                disabled={loading ? 1 : 0}
                onClick={handleClaimDebilitateUpdateClick} 
                style={{
                  border: `2px solid ${blockchain.theme}`}}>
                {loading ? (<>Claiming...</>) : (<>Claim Debilitate</>)}
              </button>
          </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/icons/managereturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Claim assaults for a win" onClick={handleClaimAssaultHelp} ><img src={require("./assets/images/icons/claimAssault.gif")} style={{height: "50px"}} /></div><br />
                <div title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} style={{height: "50px"}} /></div>
                <div title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} style={{height: "50px"}} /></div>
                <div title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>
                  <div className='mobilecontent'>
                    {!defenderId && (
                      <img src={require("./assets/images/claimdb.png")} className="portraitHTHMobile" />
                    )} 
                    {defenderId && (
                      <img src={`${TargetB}`} className="portraitHTHMobile" />
                    )}  
                    <input autoFocus  className={"inputformMb twhite"} style={{border: "3px solid rgb(74, 99, 108)"}}
                      placeholder="Your Battledog ID"
                      type="text"
                      value={defenderId}
                      onChange={handleDefenderChange}
                      alt="Your Battledog ID"
                    /> 
                    
                    {errorMessageVisible && 
                     <div className="errordisplay talmond" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }
      
                      <button className={"button1 textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={handleClaimDebilitateUpdateClick} // Pass handleUpdateClick as the onClick handler
                      style={{
                        border: `2px solid ${blockchain.theme}`}}>
                      {loading ? (<>Claiming...</>) : (<>Claim Debilitate</>)}
                    </button>        

                  </div> 
              </div> 
        </Mobile>
      </>   
    );
  } 
  
  const DebilitateComponent = () => {  
      useEffect(()=> {
        if (condition && loading && transactionResult) { 
            debilitateAfterCall();
        }  
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Arena"><img src={require("./assets/images/bg/backarrow.gif")} className="icons"  onClick={handleStartMenu}/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(19)}} title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(8)}} title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(10)}} title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(11)}} title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} className="icons" /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
              <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
                {showImage && (
                  <img src={require("./assets/images/debilitate.gif")} className="portraitHTH" />
                )}  
                {!showImage && !defenderId && (                  
                  <div className="portraitHTH" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/notargetfound.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
                )}
                {!showImage && defenderId && (                  
                  <div className="portraitHTH" style={{backgroundImage: `${targetB}`,backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/debilitatetarget.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
                )}  
                
              <div style={{padding: "2px 0px 1px 0px"}}>
                  <input autoFocus  className={"inputform twhite"}
                    placeholder="Your Battledog ID"
                    type="text"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />
              </div>
              <div>                        
                  <input autoFocus  className={"inputform twhite"} style={{border: '3px solid teal'}}
                    placeholder=" Enemy ID"
                    type="text"
                    value={defenderId}
                    onChange={handleDefenderChange}
                    alt="Insert Enemy ID"
                  />
              </div>
                    
              {errorMessageVisible && 
               <div className="errordisplay tdebilitate" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
               <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
               </div> 
              }

                <button className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={handleDebilitateUpdateClick} // Pass handleUpdateClick as the onClick handler
                style={{backgroundColor: `${blockchain.theme}`}}
              >
                {loading ? (<>Debilitating...</>) : (<>Debilitate</>)}                
              </button>

              </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                  <div title="Leave Arena" onClick={handleStartMenu}><img src={require("./assets/images/icons/gameplayreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} style={{height: "50px"}} /></div><br />
                  <div title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} style={{height: "50px"}} /></div><br />
                  <div title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} style={{height: "50px"}} /></div><br />
                  <div title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} style={{height: "50px"}} /></div><br />
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                <div className="mobilecontent" align="center">
                {showImage && (
                    <img className="portraitHTHMobile" src={require("./assets/images/debilitate.gif")}/>
                    )}  
                {!showImage && !defenderId && (                  
                  <div className="portraitHTHMobile" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}} >
                  <img src={require("./assets/images/notargetfound.png")} style={{height: '35vh', width: '35vh'}} />
                  </div>
                )}
                {!showImage && defenderId && (                  
                  <div className="portraitHTHMobile" style={{backgroundImage: `${targetB}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/debilitatetarget.png")} style={{height: '35vh', width: '35vh'}} />
                  </div>
                )}
                    <input autoFocus  className={"inputformMb twhite"}
                    placeholder="Your Battledog ID"
                    type="text"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                    />          
                    <input autoFocus  className={"inputformMb twhite"} 
                      placeholder=" Enemy ID"
                      type="text"
                      value={defenderId}
                      onChange={handleDefenderChange}
                      alt="Insert Enemy ID"
                    />
                    
                    {errorMessageVisible && 
                     <div className="errordisplay tdebilitate" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }
            
                      <button className={"buttongold textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={handleDebilitateUpdateClick} 
                      style={{backgroundColor: `${blockchain.theme}`, border: `2px solid ${blockchain.theme}` }}
                    >
                    {loading ? (<>Debilitating...</>) : (<>Debilitate</>)}
                    </button>  
                  </div>

                </div>
                
        </Mobile>
      </>   
    );
  } 

  const HarvesterComponent = () => {     
    useEffect(()=> {
      if (isWaiting && transactionResult) {
          approveGameAfterCall();
      }        
    },[isWaiting]);

    useEffect(() => {
      if (isFarming && transactionResult) {
        farmAfterCall();
      }  
    },[isFarming]);

    useEffect(() => {
      if (isWithdrawing && transactionResult) {
        setIsWithdrawing(false);
      }  
    },[isWithdrawing]);

    useEffect(() => {
      if (isClaiming && transactionResult) {
        setIsClaiming(false);
      }  
    },[isClaiming]);
    return (
      <>  
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
          <>             
            <div className='switchfarm' align="center"> 
              <img onMouseEnter={handleOnHover} src={require("./assets/images/icons/lpfarming.png")} className={`farmbuttons fb${stakeType}`} onClick={() => handleStakeTypeChange(true)}/>
              <img onMouseEnter={handleOnHover} src={require("./assets/images/icons/gamefarming.png")} className={`farmbuttons fb${!stakeType}`} onClick={() => handleStakeTypeChange(false)} />
            </div>
            <div>
              <div className='info-container'>                
                <div className='display-container'>
                  {!stakeType && (
                    <div> 
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Total Farm</div>            
                    <div className='largetxt'>{formatNumber(Number(totalStaked).toFixed(2))}<span> GAME</span></div>
                    </div>
                    )}
                  {stakeType && (
                    <div>
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Total LP Staked</div> 
                    <div className='largetxt'>{formatNumber(Number(totalStaked))}<span> GAME LP</span></div>
                    </div>
                  )}
                </div>
                <div className='display-container'>
                  {!stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Current Farm</div>
                    <div className='largetxt'>{formatNumber(Number(currentStake).toFixed(2))} GAME</div>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Current LP Farm</div>
                    <div className='largetxt'>{formatNumber(Number(currentStake))} GAME LP</div>
                  </div>  
                  )}
                </div>
                <div className='display-container'> 
                  {!stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Harvested</div>
                    <div className='largetxt'>{formatNumber(rewardClaim)} {blockchain.tokenA}</div>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Harvested</div>
                    <div className='largetxt'>{formatNumber(rewardClaim)} GAME</div>
                  </div>  
                  )}
                </div>
                <div className='display-container'>  
                  {!stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Estimated Rewards</div>
                    <div className='largetxt'>{formatNumber(rewardOwed)} {blockchain.tokenA}  {onset && (<span style={{color: `${blockchain.theme}`}}><FaLock /></span>)}</div>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <div className='smalltxt' style={{color: `${blockchain.theme}`}}>Estimated Rewards</div>
                    <div className='largetxt'>{formatNumber(rewardOwed)} GAME</div>
                  </div>  
                  )}
                </div>
              </div>
            </div>
            <div className="stake-container">
              <StakeColumn className={`column-1 ${loading ? 'wait-cursor' : null}`}>
                  <form autoComplete="on" onSubmit={(e) => handleHarvestSubmit(e,handleFunction1)} className="stake-form">
                  {!stakeType && !isWaiting && !isFarming && (
                    <div align="center">
                    <p className='bar'>Pre-Approved: <br />{formatNumber(currentAllowance)} GAME</p>
                    <input autoFocus  name="input1" type="text" placeholder="Enter Amount" className={`blind-display${loading} `}/>
                    <button type="submit" className={`greenbar blind-display${loading} `}>Farm Now</button>
                    </div>
                  )}
                  {!stakeType && (isWaiting || isFarming) && (
                    <p className='bar'>{submitText}</p>
                  )}
                  {stakeType && !isWaiting && !isFarming && (
                    <div align="center">
                    <p className='bar'>Pre-Approved: <br />{formatNumber(currentAllowance)} GAME LP</p>
                    <input autoFocus  name="input1" type="text" placeholder="Enter Amount" className={`blind-display${loading} `}/>
                    <button type="submit" className={`greenbar blind-display${loading} `}>Farm Now</button>
                    </div>
                  )}
                  {stakeType && (isWaiting || isFarming) && (
                    <p className='bar'>{submitText}</p>
                  )}
                  </form>
              </StakeColumn>
              <StakeColumn className="column-2">
                <p onClick={handleFunction2} className="greenbar stake-form stake-button">                  
                  {isWithdrawing ? (
                    <p className='bar'>{submitText}</p>
                    ):(
                    <>Withdraw Now</>
                    )}
                </p>
              </StakeColumn>
              <StakeColumn className="column-3">
                <p onClick={handleFunction3} className="greenbar stake-form stake-button">                
                {isClaiming ? (
                  <p className='bar'>{submitText}</p>
                ):(
                  <>Harvest Now</>
                )}
                </p>
              </StakeColumn>              

              <div className='notificationsbar'>
              {senderLogs && (
                  <div className="stakenotify texttheme tthematic" style={{color: `${blockchain.theme}`}}>
                    Reward claim successful! 
                    <span style={{color: "white"}}>Receipent:</span> 
                    ...{truncateAddress(senderLogs.sender)} 
                    <span style={{color: "white"}}>Rewards:</span> {senderLogs.rewards/(decimals).toFixed(blockchain.tofixed)}
                    {!stakeType ? (<span> {blockchain.tokenA}</span>):(<span> GAME</span>)}
                  </div>
                )}
              </div>

            </div>
          </>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                  <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                  <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                  <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                  <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>
                <div align="center" style={{display: "flex", width: "10%", marginTop:"4px", marginBottom:"4px", marginLeft: "auto", marginRight: "auto"}}> 
                    <img onMouseEnter={handleOnHover} src={require("./assets/images/icons/lpfarming.png")} className={`farmbuttons fb${stakeType}`} onClick={() => handleStakeTypeChange(true)}/>
                    <img onMouseEnter={handleOnHover} src={require("./assets/images/icons/gamefarming.png")} className={`farmbuttons fb${!stakeType}`} onClick={() => handleStakeTypeChange(false)} />
                </div>
                <div className='mobile-contain-box'>
                  {!stakeType && (
                    <> 
                    <p className='smalltxt'>Total Farm</p>            
                    <p className='largetxt'>{formatNumber(Number(totalStaked).toFixed(2))}<span> GAME</span></p>
                    </>
                    )}  
                  {stakeType && (
                    <>
                    <p className='smalltxt'>Total LP Staked</p> 
                    <p className='largetxt'>{formatNumber(Number(totalStaked))}<span> GAME LP</span></p>
                    </>
                  )}
                </div>
                <div className='mobile-contain-box'>
                  {!stakeType && (
                  <div>        
                    <p className='smalltxt'>Current Farm</p>
                    <p className='largetxt'>{formatNumber(Number(currentStake).toFixed(2))} GAME</p>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <p className='smalltxt'>Current LP Farm</p>
                    <p className='largetxt'>{formatNumber(Number(currentStake))} GAME LP</p>
                  </div>  
                  )}
                </div>
                <div className='mobile-contain-box'>
                  {!stakeType && (
                  <div>        
                    <p className='smalltxt'>Harvested</p>
                    <p className='largetxt'>{formatNumber(rewardClaim)} {blockchain.tokenA}</p>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <p className='smalltxt'>Harvested</p>
                    <p className='largetxt'>{formatNumber(rewardClaim)} GAME</p>
                  </div>  
                  )}        
                </div>
                <div className='mobile-contain-box'>
                  {!stakeType && (
                  <div>        
                    <p className='smalltxt'>Estimated Rewards</p>
                    <p className='largetxt'>{formatNumber(rewardOwed)} {blockchain.tokenA}  {onset &&(<span style={{color: `${blockchain.theme}`}}><FaLock /></span>)}</p>
                  </div>  
                  )}
                  {stakeType && (
                  <div>        
                    <p className='smalltxt'>Estimated Rewards</p>
                    <p className='largetxt'>{formatNumber(rewardOwed)} GAME</p>
                  </div>  
                  )}
                </div>
                <div className='mobilecontent'>
                  <div className="mobile-container">
                        <MobileColumn className="column-4">
                          <form autoComplete="on" onSubmit={(e) => handleHarvestSubmit(e, handleFunction1)} className="stake-form">
                            {!stakeType && !isWaiting && !isFarming && (
                              <div align="center">
                              <div className='barMb'>Pre-Approved: <br />{formatNumber(Number(currentAllowance))} GAME</div>
                              <input autoFocus  name="input1" type="text" placeholder="Enter Amount" className={`blind-display${loading}`}/>
                              <button type="submit" className={`greenbarMb blind-display${loading}`}>Farm Now</button>
                              </div>
                            )}
                            {!stakeType && (isWaiting || isFarming) && (
                              <p className='barMb'>{submitText}</p>
                            )}
                            {stakeType && !isWaiting && !isFarming && (
                              <div align="center">
                              <div className='barMb'>Pre-Approved: <br />{formatNumber(Number(currentAllowance))} GAME LP</div>
                              <input autoFocus  name="input1" type="text" placeholder="Enter Amount" className={`blind-display${loading}`}/>
                              <button type="submit" className={`greenbarMb blind-display${loading}`}>Farm Now</button>
                              </div>
                            )}
                            {stakeType && (isWaiting || isFarming) && (
                              <p className='barMb'>{submitText}</p>
                            )}
                          </form>
                        </MobileColumn>
                        <MobileColumn className="column-5">
                          <p onClick={handleFunction2} className="greenbar stake-form stake-button">                                             
                          {isWithdrawing ? (
                            <p className='bar'>{submitText}</p>
                            ):(
                            <>Withdraw Now</>
                          )}
                          </p>
                        </MobileColumn>
                        <MobileColumn className="column-6" style={{backgroundPosition: "top center"}}>
                          <p onClick={handleFunction3} className="greenbar stake-form stake-button">                 
                          {isClaiming ? (
                            <p className='bar'>{submitText}</p>
                            ):(
                            <>Harvest Now</>
                          )}
                          </p>
                        </MobileColumn>
                  </div>
                </div>
              </div>  
        </Mobile>
      </>   
    );
  } 

  const ScoreboardComponent = () => {
      useEffect(() => {
        if (condition && loading && transactionResult) {  
                if (isScoreboard) return   
            const scoreboardAfterCall = async() => {
              if (scoreAssaultMap) {
                    isScoreboard = true;  
                  await allOwnerOfPlayersTx();
                  setErrorMessage("Success!");
                  setErrorMessageVisible(true);
                  await handleState(attackerId);
                  await handleState(defenderId);
                    isScoreboard = false;

                  setLoading(false);     
              } else if (scoreDebilitateMap) {
                    isScoreboard = true;
                  await allOwnerOfPlayersTx();
                  playLonghowl();
                  setErrorMessage("Success!");
                  setErrorMessageVisible(true);
                  await handleState(attackerId);
                  await handleState(defenderId);
                    isScoreboard = false;

                  setLoading(false);
              }
            }
            scoreboardAfterCall();
        }
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
        <div className={"activityboard"} align={"center"}><div>
              <div className="actheader" onClick={handleActivityTracker} align={"center"}>
                <img className='scoreboardImg' src={require("./assets/images/scoreboard.png")} style={{backgroundColor: `${blockchain.theme}`}}/> 
              </div>
            <div className="lbtable1">
              {activePlayersList.length === 0 ? (
                <p className="leaderboard" style={{ padding: '2vh', margin: '2vh', width: 'auto', color: `${blockchain.theme}`, border: `1px solid ${blockchain.theme}`, justifyContent: 'center'}} align='center'>
                  No players available on the leaderboard.
                </p>
              ) : (
                <>
                  {activePlayersList && (
                    <>
                    {activePlayersList.map((player, index) => (
                          <li key={index}>
                            <div className="leaderboard jlb" 
                            onClick={(e) => handleScoreSubmitter(e, getStats, player)}
                            style={{color: `${blockchain.theme}`, border: `1px solid ${blockchain.theme}` }}><span className="lbSmall">Battledog</span><br /> <span className="lbLarge">{player}</span></div>
                          </li>
                        ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>      
        </div>
          {selectedPlayerStats && (
          <div className="leader-container" style={{border: `1vh solid ${blockchain.theme}`, padding: '1vh', borderRadius: '20px 0px 0px 20px'}}>
              {selectedPlayerStats.map((player, index) => (
                <div key={index} className="player-info" style={{color: `${blockchain.theme}`}}>
                  <div className="profile-pic">
                  <div className="player-details-namebox bp5gtext">
                        <div className="lbSmall jlb">{player[0]}</div>
                      </div>
                    {showImage ? (
                    <img className="player-profile-pic" src={(`https://battledogs.fun/engine/${player[1].toString()}.png`)} 
                    onClick={handleView}
                    style={{border: `1px solid ${blockchain.theme}` }}
                    alt=""/>
                    ):(
                      <div className="blank-profile-pic lbMidtxt"
                      style={{color: `black`}}
                      onClick={handleView}
                      align="center">Click To <br/> View</div>
                    )}
                      <div className="player-details-idbox">
                        <div className="lbMid jlb">{player[1].toString()}</div>
                      </div>
                    <div className="player-details lbtable2">
                      <div className="player-details-box">
                        <div className="lbSmall jlb">Level</div>
                        <div className="lbMid jlb twhite">{player[2].toString()}</div>
                      </div>
                      <div className="player-details-box">
                        <div className="lbSmall jlb">Fights</div>
                        <div className="lbMid jlb twhite">{player[5].toString()}</div>
                      </div>
                      <div className="player-details-box">
                        <div className="lbSmall jlb">Aggression</div>
                        <div className="lbMid jlb twhite">{player[3].toString()}</div>
                      </div>
                      <div className="player-details-box" style={{backgroundColor: "black"}}>
                       <div className="lbSmall jlb" style={{opacity: "0.4"}}>Payout</div>
                        <div className="lbMid jlb bp5gtext" >?</div>
                      </div>
                      <div className="player-details-box">
                        <div className="lbSmall jlb">Activation</div>
                        <div className="lbMid jlb twhite">{player[8].toString()}</div>
                      </div>
                      <div className="player-details-box">
                        <div className="lbSmall jlb">Wins</div>
                        <div className="lbMid jlb twhite">{player[6].toString()}</div>
                      </div>
                      <div className="player-details-box">
                        <div className="lbSmall jlb">Stamina</div>
                        <div className="lbMid jlb twhite">{player[4].toString()}</div>
                      </div>
                      <div className="player-details-box">
                        <div className="lbSmall jlb">History</div>
                        <div className="lbMid jlb twhite">{player[9].toString()}</div>
                      </div>
                    </div>
                  </div>  
                  <div className="leader-return-text" align="center" onClick={hideView}><img src={require("./assets/images/circle-xmark.png")} style={{height: "6vh"}}/></div>
                <div className='quickAttack' align='center'>
                  <button className={`scorebutton scoreassault${scoreAssaultMap}`} onClick={() => {setDefenderId(player[1].toString()); handleScoreAssault()}}>Assault This Battledog</button> 
                  <PiInfinityLight style={{color: 'whitesmoke', padding: "0vh 1vh 0vh 1vh"}}/> 
                  <button className={`scorebutton scoredebilitate${scoreDebilitateMap}`} onClick={() => {setDefenderId(player[1].toString()); handleScoreDebilitate()}}> Debilitate This Battledog</button>
                  <div className='scoreAttackDiv' align='center'>
                    {scoreAssaultMap && 
                    <>{renderAssault()}</>}
                    {scoreDebilitateMap && 
                    <>{renderDebilitate()}</>}
                  </div>
                </div>                
                    
                {errorMessageVisible && 
                  <div className="errordisplay tlevel" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}</div> 
                }
                </div>
              ))}
          </div>
        )}
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                  <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                  <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                  <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                  <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>
                  <div className='mobilecontent' align="center">
                    <div>
                      <div onClick={handleActivityTracker}> 
                        <img className='scoreboardImgMobile' src={require("./assets/images/scoreboard.png")} style={{backgroundColor: `${blockchain.theme}`}}/>
                      </div>
                      <div className="lbtable1Mb">
                        {activePlayersList.length === 0 ? (
                          <p className="leaderboard lbMobile" style={{ margin: '1vh 1vw 1vh 1vw', color: `${blockchain.theme}`, border: `1px solid ${blockchain.theme}`, width: '80vw'}}>
                            No players available on the leaderboard.
                          </p>
                        ) : (
                         <>
                         {activePlayersList && (
                          <>
                            {activePlayersList.map((player, index) => (
                                <li key={index}>
                                  <div className="leaderboard lbSmallMb jlb"
                                  onClick={(e) => handleScoreSubmitter(e, getStats, player)}
                                  style={{color: `${blockchain.theme}`, border: `1px solid ${blockchain.theme}` }}>
                                    BID <br /> 
                                    <span className="lbLargeMb" >{player}</span></div>
                                </li>
                              ))}
                          </>
                         )}
                         </>
                        )}
                            
          {selectedPlayerStats && (
            <div className="little-container" style={{border: `1vh solid ${blockchain.theme}`, padding: '1vh', borderRadius: '20px 20px 20px 20px'}}>
              {selectedPlayerStats.map((player, index) => (
                <div key={index} className="player-info-mb" style={{color: `${blockchain.theme}`}}>
                  <div className="profile-pic-mb" style={{color: `${blockchain.theme}`}}>

                    <div className="player-details-mb">
                      <div className="player-details-box-mb">
                        <div className="lbSmallMb jlb">Level</div>
                        <div className="lbLargeMb jlb twhite">{player[2].toString()}</div>
                      </div> 
                      <div className="player-details-box-mb">
                        <div className="lbSmallMb jlb">Attack</div>
                        <div className="lbLargeMb jlb twhite">{player[3].toString()}</div>
                      </div>                      
                      <div className="player-details-box-mb">
                        <div className="lbSmallMb jlb">Defence</div>
                        <div className="lbLargeMb jlb twhite">{player[4].toString()}</div>
                      </div>
                      <div className="player-details-box-mb">
                        <div className="lbSmallMb jlb">Wins</div>
                        <div className="lbLargeMb jlb twhite">{player[6].toString()}</div>
                      </div>                 
                    </div>

                    <div>                   
                          <div className="player-details-namebox-mb bp5gtext">
                              <div className="lbSmallMb jlb">{player[0].toString()}</div>
                          </div>

                          {showImage ? (
                          <img className="player-profile-pic-mb" src={(`https://battledogs.fun/engine/${player[1].toString()}.png`)} 
                          onClick={handleView}
                          style={{border: `1px solid ${blockchain.theme}` }}
                          alt=""/>
                          ):(
                            <div className="blank-profile-pic-mb lbMidtxt" 
                            style={{color: `black`}}
                            onClick={handleView}
                            align="center">Click To <br/> View</div>
                          )}

                          <div className="player-details-idbox-mb">
                            <div className="lbMid jlb">{player[1].toString()}</div>
                          </div> 
                    </div>  

                    <div className="player-details-mb">                      
                    <div className="player-details-box-mb">
                        <div className="lbSmallMb jlb">Tier</div>
                        <div className="lbLargeMb jlb twhite">{player[8].toString()}</div>
                      </div>
                      <div className="player-details-box-mb">
                        <div className="lbSmallMb jlb">Fights</div>
                        <div className="lbLargeMb jlb twhite">{player[5].toString()}</div>
                      </div>                      
                      <div className="player-details-box-mb">
                        <div className="lbSmallMb jlb">History</div>
                        <div className="lbLargeMb jlb twhite">{player[9].toString()}</div>
                      </div>                      
                      <div className="player-details-box-mb" style={{backgroundColor: "black"}}>
                       <div className="lbSmallMb jlb" style={{opacity: "0.4"}}>Payout</div>
                        <div className="lbLargeMb jlb bp5gtext" >?</div>
                      </div>
                    </div>

                    <div className="player-details-mb">
                    </div>
                  </div>
                  <div className="little-return-text" align="center" onClick={hideView}><img src={require("./assets/images/circle-xmark.png")} style={{height: "4vh"}}/> </div>
                
                <div className='quickAttack' align='center'>
                  <button className={`scorebutton scoreassault${scoreAssaultMap}`} style={{color: `${blockchain.theme}`}} onClick={() => {setAttackerId(player[1].toString()); handleScoreAssault()}}>Assault This Battledog</button> <br />
                  <PiInfinityLight style={{color: 'whitesmoke', padding: "0vh 1vh 0vh 1vh"}}/> <br />
                  <button className={`scorebutton scoredebilitate${scoreDebilitateMap}`}  style={{color: `${blockchain.theme}`}} onClick={() => {setAttackerId(player[1].toString()); handleScoreDebilitate()}}> Debilitate This Battledog</button>
                  <div className='scoreAttackDiv' align='center'>
                    {scoreAssaultMap && 
                    <>{renderAssault()}</>}
                    {scoreDebilitateMap && 
                    <>{renderDebilitate()}</>}
                  </div>
                </div>                
                    
                {errorMessageVisible && 
                  <div className="errordisplay tlevel" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}</div> 
                }
                </div>
              ))}
            </div>
          )}
                      </div>
                    </div>                
                  </div>

              </div> 
        </Mobile>
      </>   
    );
  } 

  const LevelUpComponent = () => {  
      useEffect(()=> {
        if (condition && loading && transactionResult) {
          levelUpAfterCall();
        }  
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(12)}} title="Claim assaults for a win" onClick={handleClaimAssaultHelp}><img src={require("./assets/images/icons/claimAssault.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(13)}} title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(6)}} title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(4)}} title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} className="iconsbkg goldpayout"/></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div> 
              <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
                {!attackerId && (
                  <img src={require("./assets/images/leveler.png")} className="portraitHTH" />
                )} 
                {attackerId && (
                  <img src={`${TargetA}`} className="portraitHTH" />
                )}               
                    <input autoFocus  className={"inputform twhite"} style={{border: "3px solid orange"}}
                      type="text"
                      placeholder="Your Battledog ID"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Your Battledog ID"
                    />
                    
                    {errorMessageVisible && 
                     <div className="errordisplay tlevel" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }

                  <button className={"buttongold textstyle"} 
                    disabled={loading ? 1 : 0}
                    onClick={handleLevelUpUpdateClick}
                    style={{backgroundColor: `${blockchain.theme}`}}
                  >
                  {loading ? (<>Setting...</>) : (<>Level Up</>)}
                  </button>  
              </div>                                
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/icons/managereturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Claim assaults for a win" onClick={handleClaimAssaultHelp} ><img src={require("./assets/images/icons/claimAssault.gif")} style={{height: "50px"}} /></div><br />
                <div title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} style={{height: "50px"}} /></div>
                <div title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} style={{height: "50px"}} /></div>
                <div title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                  <div className='mobilecontent'>
                    {!attackerId && (
                      <img src={require("./assets/images/leveler.png")} className="portraitHTHMobile"/>
                    )} 
                    {attackerId && (
                      <img src={`${TargetA}`} className="portraitHTHMobile"/>
                    )}               
                    <input autoFocus  className={"inputformMb twhite"} style={{border: "3px solid orange"}}
                    type="text"
                    placeholder="Your Battledog ID"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                   />
                    
                   {errorMessageVisible && 
                    <div className="errordisplay tlevel" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                    </div> 
                   }
                    
                    <button className={"buttongold textstyle"}
                    disabled={loading ? 1 : 0}
                    onClick={handleLevelUpUpdateClick}
                    style={{backgroundColor: `${blockchain.theme}`}}
                    >
                    {loading ? (<>Setting...</>) : (<>Level Up</>)}
                    </button>  
                  </div>
              </div> 
        </Mobile>
      </>   
    );
  } 

  const MiningComponent = () => {
    useEffect(() => {
      if (isClaiming && transactionResult) {
      miningAfterCall();
      }
    },[isClaiming]);
    return (
      <>
        <Desktop>
           <>
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
              <div className="xbo-container">
                    <div className="xbo-display-column">
                      {ownedBattledogs && (
                        <>
                        {ownedBattledogs.map((player, index) => {
                          return (
                            <div key={index} className={"texttheme tgold"} style={{color: `${blockchain.theme}`}}>
                              <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftImage"} onClick={() => handleMiningClick(player.id)} 
                              style={{backgroundColor: "white", margin: "0.5vh", padding: '0.5vh', borderRadius: "25px"}}/> 
                              <br />
                              {timelock && (
                                <div style ={{color: `${blockchain.theme}`, backgroundColor: "black", 
                                margin: "0.5vh", padding: "0.5vh", borderRadius: "25px"}}>
                                  {' '}
                                  {Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400) < 0
                                    ? `${Math.abs(Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400))} days left`
                                    : Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400) > 1000
                                      ? 'Claim Now'
                                      : `${Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400)} days. Claim Now.`
                                  }
                                </div>
                              )}
                            </div>
                          );
                        })}    
                        </>
                      )} 
                    </div>
                    <XboColumn backgroundImage={
                      selectedPlayer ? (`https://battledogs.fun/engine/${selectedPlayer}.png`) : (require(`./assets/images/batdog.jpg`))}>
                        {unlocker ? (
                        <div className="minertext" style={{backgroundColor: `${blockchain.theme}`}}>{submitText}</div>
                        ) : (
                        <button onClick={(e) => handleMiningSubmit(e, handleMiningFunction1)} className="xbo-form xbo-button" style={{fontFamily: 'NexaHeavy', backgroundColor: `${blockchain.theme}`}}>Claim {selectedPlayer}</button>        
                        )}
                        
                    </XboColumn>
                    <XboColumn backgroundImage={require(`./assets/images/superdog.jpg`)}>
                        {unlocker2 ? (
                        <div className="minertext" style={{backgroundColor: `${blockchain.theme}`}}>{submitText2}</div>
                        ) : (
                      <button onClick={(e) => handleMiningSubmit(e, handleMiningFunction2)} className="xbo-form xbo-button" style={{fontFamily: 'NexaHeavy', backgroundColor: `${blockchain.theme}`}}>
                        Claim All ({activeBattledogs && (<>{activeBattledogs.length}</>)})
                      </button>                      
                        )}
                    </XboColumn>
              </div>              
            
              <div className='notificationsbar'>
                    {senderLogs && (
                        <div className="stakenotify texttheme tthematic" style={{color: `${blockchain.theme}`}}>
                          Reward claim successful!  
                          <span style={{color: "white"}}>Receipent:</span> 
                          ...{truncateAddress(senderLogs.sender)} 
                          <span style={{color: "white"}}>Rewards:</span> {senderLogs.rewards/(10**18).toFixed(2)} GAME
                        </div>
                      )}
              </div>
            </> 
        </Desktop>
        <Mobile>
           <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                  <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                  <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                  <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                  <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div> 
                <div className='mobilenotify' align="center">
                  {senderLogs && (
                      <div style={{display: "flex", flexDirection: "column", color: `${blockchain.theme}`}}>
                        Reward claim successful!  
                        <span style={{color: "white"}}>Receipent:</span> 
                        ...{truncateAddress(senderLogs.sender)} 
                        <span style={{color: "white"}}>Rewards:</span> {senderLogs.rewards/(10**18).toFixed(2)} GAME
                      </div>
                    )}                    
                </div>
                <div className='mobilecontent'>
                    <div className="mobile-container">
                        <div className="xbo-display-column-mb">                          
                      {ownedBattledogs && (
                        <>
                        {ownedBattledogs.map((player, index) => {
                          return (
                            <div key={index} className={"texttheme tthematic"} style={{color: `${blockchain.theme}`, justifyContent: "center", alignItems: "center"}}>
                              <img src={`https://battledogs.fun/engine/${player.id}.png`} className={"nftMineMB"} onClick={() => handleMiningClick(player.id)}  
                                style={{backgroundColor: "white", margin: "0.5vh", padding: '0.5vh', borderRadius: "25px"}}/> 
                              <br />
                              {timelock && (
                                <div style ={{color: `${blockchain.theme}`, backgroundColor: "black", 
                                margin: "0.5vh", padding: "0.5vh", borderRadius: "25px"}}>
                                  {' '}
                                  {Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400) < 0
                                    ? `${Math.abs(Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400))} days left`
                                    : Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400) > 1000
                                      ? 'Claim Now'
                                      : `${Math.floor(((Date.now() / 1000) - (locks[index] + timelock)) / 86400)} days due.`
                                  }
                                </div>
                              )}
                            </div>
                          );
                        })}
                        </>
                      )}
                        </div>
                        <MobileXboColumn backgroundImage={selectedPlayer ? (`https://battledogs.fun/engine/${selectedPlayer}.png`) : (require(`./assets/images/batdog.jpg`))}>                          
                            {unlocker ? (
                            <div className="minertext" style={{backgroundColor: `${blockchain.theme}`}}>{submitText}</div>
                            ) : (
                              <button onClick={(e) => handleMiningSubmit(e, handleMiningFunction1)} className="xbo-form xbo-button" style={{fontFamily: 'NexaHeavy', backgroundColor: `${blockchain.theme}`}}>Claim {selectedPlayer}</button>        
                              )}
                        </MobileXboColumn>
                        <MobileXboColumn backgroundImage={require(`./assets/images/superdog.jpg`)}>
                          {unlocker2 ? (
                          <div className="minertext" style={{backgroundColor: `${blockchain.theme}`}}>{submitText2}</div>
                          ) : (
                            <button onClick={(e) => handleMiningSubmit(e, handleMiningFunction2)} className="xbo-form xbo-button" style={{fontFamily: 'NexaHeavy', backgroundColor: `${blockchain.theme}`}}>
                              Claim All ({activeBattledogs && (<>{activeBattledogs.length}</>)})
                            </button>  
                          )}
                        </MobileXboColumn>
                    </div>                    
                </div> 
            </div>
        </Mobile>
      </>   
    );
  } 

  const MintComponent = () => { 
      useEffect(() => {
        if (condition && loading && transactionResult) {
          mintAfterCall();
        }
      },[loading]);
    return (
      <>
      <MintParameter />
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Start" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Go to Arsenal" onClick={handleArsenal}><img src={require("./assets/images/icons/arsenal.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(3)}} title="Mint a Battledog" onClick={handleMint}><img src={require("./assets/images/icons/mint.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(1)}} title="Activate a Battledog" onClick={handleActivateHelp}><img src={require("./assets/images/icons/activate.png")} className="icons" /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>              
              <div className="translation" style={{ display: "flex", flexDirection: "column", alignItems: "center" }} align="center">
                {showImage ? (
                  <img src={require("./assets/images/morphs.gif")} className="portraitHTH" />
                ) : (
                  <div className="portraitHTH" style={{backgroundImage: `${newMint}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/availabletomint.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
                )}
                  <div style={{ padding: "2px" }}>
                    <input autoFocus 
                      className={"inputform twhite slimBorder"}
                      type="text"
                      placeholder="Name Your Battledog"
                      value={name}
                      onChange={handleNameChange}
                      alt="Name Your Battledog"
                    />
                    
                    {errorMessageVisible && 
                     <div className="errordisplay twhitesmoke" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }

                    <br />
                    <button
                      className={"buttongold textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={handleMintClick} 
                      style={{backgroundColor: `${blockchain.theme}`}}
                    >
                    {loading ? (<>Spawning...</>) : (<>Spawn</>)}
                    </button>
                  </div>
              </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div onMouseEnter={handleMouseEnter} title="Leave Start" onClick={handleStartMenu}><img src={require("./assets/images/icons/startreturn.png")} style={{height: "50px"}} /></div><br />
                <div onMouseEnter={handleMouseEnter} title="Go to Arsenal" onClick={handleArsenal}><img src={require("./assets/images/icons/arsenal.png")} style={{height: "50px"}} /></div><br />
                <div onMouseEnter={handleMouseEnter} title="Mint a Battledog" onClick={handleMint}><img src={require("./assets/images/icons/mint.png")} style={{height: "50px"}} /></div><br />
                <div onMouseEnter={handleMouseEnter} title="Activate a Battledog" onClick={handleActivateHelp}><img src={require("./assets/images/icons/activate.png")} style={{height: "50px"}} /></div><br />
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>
                <div className='mobilecontent' align="center">
                {showImage ? (
                  <img src={require("./assets/images/morphs.gif")} className="portraitHTHMobile"/>
                ) : (
                  <div className="portraitHTHMobile" style={{backgroundImage: `${newMint}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}}>
                  <img src={require("./assets/images/availabletomint.png")} style={{height: '35vh', width: '35vh'}} />
                  </div>
                )}
                        <input autoFocus 
                        className={"inputformMb twhite"}
                        type="text"
                        placeholder="Name Your Battledog"
                        value={name}
                        onChange={handleNameChange}
                        alt="Name Your Battledog"
                        />
                    
                        {errorMessageVisible && 
                         <div className="errordisplay twhitesmoke" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                         <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                         </div> 
                        }
          
                        <button
                          className={"buttongold textstyle"}
                          disabled={loading ? 1 : 0}
                          onClick={handleMintClick} // Pass handleMintClick as the onClick handler
                          style={{backgroundColor: `${blockchain.theme}`}}
                        >
                        {loading ? (<>Spawning...</>) : (<>Spawn</>)}
                        </button>                                                
                      </div> 
              </div> 
        </Mobile>
      </>   
    );
  } 

  const PayoutComponent = () => {
      useEffect(() => {
        if (condition && loading && transactionResult) {
          payoutAfterCall();
        }
      },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(12)}} title="Claim assaults for a win" onClick={handleClaimAssaultHelp}><img src={require("./assets/images/icons/claimAssault.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(13)}} title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(6)}} title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(4)}} title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} className="iconsbkg goldpayout"/></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div> 
          <div className={"translation"} 
              style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
              {!attackerId && (
                <img src={require("./assets/images/cashingout.png")} className="portraitHTH" />
              )} 
              {attackerId && (
                <img src={`${TargetB}`} className="portraitHTH" />
              )} 
                  <div>            
                    <input autoFocus  className={"inputform"} style={{color: "white", border: "3px solid goldenrod"}}
                      placeholder="Your Battledog ID"
                      type="text"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Your Battledog ID"
                    />
                    
                    {errorMessageVisible && 
                     <div className="errordisplay tblue" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }

                </div>              
                    <button id="spawn-button" className={"button1 textstyle"} 
                    disabled={loading ? 1 : 0}
                    onClick={handlePayoutUpdateClick} // Pass handleUpdateClick as the onClick handler
                    style={{marginTop: "8px", border: "2px solid goldenrod"}} alt="Cashout button">
                    {loading ? (<>Requesting...</>) : (<>Cash out</>)}
                    </button>               
                  </div>
              <div className="notificationsData">
                <Notifications />
              </div>
          {blockchain.wrap && (
            <>  
              <Unwrapper />
            </>
          )}    
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Winnings" onClick={handleStartMenu}><img src={require("./assets/images/icons/managereturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Claim assaults for a win" onClick={handleClaimAssaultHelp} ><img src={require("./assets/images/icons/claimAssault.gif")} style={{height: "50px"}} /></div><br />
                <div title="Claim debilitations for a win" onClick={handleClaimDebilitateHelp}><img src={require("./assets/images/icons/claimDebilitate.gif")} style={{height: "50px"}} /></div>
                <div title="Level up a Battledog" onClick={handleLevelUpHelp}><img src={require("./assets/images/icons/levelup.gif")} style={{height: "50px"}} /></div>
                <div title={`Withdraw Your ${blockchain.tokenA} Winnings`} onClick={handlePayoutHelp}><img src={require("./assets/images/icons/wallet.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                  <div className='mobilecontent'>
                    {!attackerId && (
                      <img src={require("./assets/images/cashingout.png")} className="portraitHTHMobile" />
                    )} 
                    {attackerId && (
                      <img src={`${TargetA}`} className="portraitHTHMobile" />
                    )} 
                    <input autoFocus  className={"inputformMb twhite"} style={{border: "3px solid goldenrod"}}
                      placeholder="Your Battledog ID"
                      type="text"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Your Battledog ID"
                    />
                    
                    {errorMessageVisible && 
                     <div className="errordisplay tblue" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }
                    
                      <button id="spawn-button" className={"button1 textstyle"} 
                      disabled={loading ? 1 : 0}
                      onClick={handlePayoutUpdateClick} 
                      style={{marginTop: "8px", border: "2px solid goldenrod"}} alt="Cashout button">
                      {loading ? (<>Requesting...</>) : (<>Cash out</>)}
                      </button>
                  </div>                  
                  {blockchain.wrap && (
                      <>  
                      <Unwrapper />
                      </>
                  )} 
              </div> 
        </Mobile>
      </>   
    );
  } 

  const RegenerateComponent = () => {
    useEffect(() => {
        if (condition && loading && transactionResult) {
          regenerateAfterCall();
        }  
    },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Arena"><img src={require("./assets/images/bg/backarrow.gif")} className="icons"  onClick={handleStartMenu}/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(19)}} title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(8)}} title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(10)}} title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(11)}} title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} className="icons" /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
          <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
              {!attackerId && (
                <img src={require("./assets/images/regen.png")} className="portraitHTH" />
              )} 
              {attackerId && (
                <img src={`${TargetA}`} className="portraitHTH" />
              )}                       
              <div>
                  <input autoFocus  className={"inputform twhite"} style={{border: "3px solid rgb(122, 61, 17)"}}
                    type="text"
                    placeholder="Your Battledog ID"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />
                    
                  {errorMessageVisible && 
                   <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                    <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                   </div> 
                  }

              </div>
                <button className="button1 textstyle"
                disabled={loading ? 1 : 0}
                onClick={handleRegenerateUpdateClick} // Pass handleUpdateClick as the onClick handler
                style={{border: "2px solid rgb(122, 61, 17)"}}>
                {loading ? (<>Regenerating...</>) : (<>Regenerate</>)}                  
              </button>
          </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                  <div title="Leave Arena" onClick={handleStartMenu}><img src={require("./assets/images/icons/gameplayreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} style={{height: "50px"}} /></div><br />
                  <div title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} style={{height: "50px"}} /></div><br />
                  <div title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} style={{height: "50px"}} /></div><br />
                  <div title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} style={{height: "50px"}} /></div><br />
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>                
                  <div className='mobilecontent'>
              {!attackerId && (
                <img src={require("./assets/images/regen.png")} className="portraitHTHMobile"/>
              )} 
              {attackerId && (
                <img src={`${TargetA}`} className="portraitHTHMobile"/>
              )}                    
                    <input autoFocus  className={"inputformMb twhite"} style={{border: "3px solid rgb(122, 61, 17)"}}
                      type="text"
                      placeholder="Your Battledog ID"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Your Battledog ID"
                    />
                    
                    {errorMessageVisible && 
                     <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }

                    <button className={"button1 textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={handleRegenerateUpdateClick}
                      style={{
                        border: "2px solid rgb(122, 61, 17)"}}>
                      {loading ? (<>Regenerating...</>) : (<>Regenerate</>)} 
                    </button>       
                    
                  </div> 
              </div> 
        </Mobile>
      </>   
    );
  } 

  const ResetTimerComponent = () => {
    useEffect(() => {
        if (condition && loading && transactionResult) {
          if (isResetTimer) return;
            isResetTimer = true;
          setErrorMessage("Reset successful!");
          setErrorMessageVisible(true);
            isResetTimer = false;

          setLoading(false); 
        }  
    },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
          <div className={"translation"} 
              style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              {!attackerId && (
                <img src={require("./assets/images/timer.gif")} className="portraitHTH" />
              )} 
              {attackerId && (
                <img src={`${TargetA}`} className="portraitHTH" />
              )}              
                  <div align="center" >
                  <input autoFocus  className={"inputform twhite"} style={{border: "3px solid rgb(113, 50, 2)"}}
                    type="text"
                    placeholder="Your Battledog ID"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />

                  {errorMessageVisible && 
                  <div className="errordisplay tdebilitate" onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                  <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                  </div> }
                  
              </div>
                <button className={"button1 textstyle"}
                disabled={loading ? 1 : 0}
                onClick={handleResetTimerUpdateClick}
                style={{
                  border: `2px solid ${blockchain.theme}`}}>
                {loading ? (<>Resetting...</>) : (<>Reset</>)}                
              </button> 
              </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>
                  <div className='mobilecontent'>
                    {!attackerId && (
                      <img src={require("./assets/images/timer.gif")} className="portraitHTHMobile"/>
                    )} 
                    {attackerId && (
                      <img src={`${TargetA}`} className="portraitHTHMobile"/>
                    )}                            
                    <input autoFocus  className={"inputformMb twhite"} style={{border: "3px solid rgb(113, 50, 2)"}}
                    type="text"
                    placeholder="Your Battledog ID"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />

                  {errorMessageVisible && 
                  <div className="errordisplay tdebilitate" onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                  <img src={require(`./assets/images/retry.gif`)} style={{ height: "15px" }} />
                  </div> }
                  <button className={"button1 textstyle"}
                  disabled={loading ? 1 : 0}
                  onClick={handleResetTimerUpdateClick}
                  style={{
                    border: `2px solid ${blockchain.theme}`}}>
                  {loading ? (<>Resetting...</>) : (<>Reset</>)} 
                  </button> 
                    
                  </div>

              </div> 
        </Mobile>
      </>   
    );
  } 

  const CustomizeComponent = () => {
      useEffect(()=> {
        if (condition && loading && transactionResult) {
          customizeAfterCall();
        }
      },[loading])
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
      <div className="translation" style={{ display: "flex", flexDirection: "column", alignItems: "center" }} align="center">
      <div className={`updatebox updatebox${type}`} align="center" style={{margin: '2vh 0vw 0vh 0vw'}}>
        <p className={`namebox namebox${type}`} onClick={toggleType}>SET NAME</p>
        <p className={`imagebox imagebox${type}`} onClick={toggleType}>SET AVATAR</p>
      </div>
        {!type && (   
          <div id="updatename">          
          {!attackerId && !type && (
            <img src={require("./assets/images/customize.gif")} className="portraitHTH opacity8" />
          )} 
          {attackerId && !type &&(
            <img src={`${TargetA}`} className="portraitHTH" />
          )}             

            <div style={{ padding: "2px" }}>
              <input autoFocus 
                className={"inputform twhite"}
                style={{ border: "3px solid cyan"}}
                type="text"
                placeholder="Your Battledog ID"
                value={attackerId}
                onChange={handleAttackerChange}
                alt="Your Battledog ID"
              />
            </div>
            <div>
                <input autoFocus 
                  className={"inputform twhite"}
                  style={{ border: "3px solid cyan"}}
                  type="text"
                  placeholder=" New Battledog Name"
                  value={name}
                  onChange={handleNameChange}
                  alt="New Battledog Name"
                />
              </div>
                    
                    {errorMessageVisible && 
                     <div className="errordisplay tcyan" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                     <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                     </div> 
                    }
            
              <button
                className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={handleCustomizeUpdateClick}
                style={{backgroundColor: `${blockchain.theme}` }}
              >
              {loading ? (<>Customizing...</>) : (<>Customize</>)} 
              </button>
            </div>
        )}        
        {type && (  
          <div id="updateimage">          
          {!attackerId && type && (                   
                  <div style={{backgroundColor: `${blockchain.theme}`}} className="nameback portraitHTH">
                  <img src={require("./assets/images/rename-custom.png")}  style={{height: '35vh', width: '35vh'}}/>
                  </div>
          )} 
          {attackerId && type && (                  
                  <div style={{backgroundImage: `${targetA}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}} className="portraitHTH">
                  <img src={require("./assets/images/update-avatar-image.png")} style={{height: '31vh', width: '31vh', margin: '4vh 0vw 0vh 0vw'}} />
                  </div>
          )}     
                   
          <div>
              <div className="upnfont" style={{color: 'white'}}> Acceptable: </div>
              <div className="upnfont" style={{color: `${blockchain.theme}`}}>512 x 512 px , 768 x 768 px , 1024 x 1024 px </div>
              <div className="upnfont" style={{color: 'white'}}> Size Limit: 1 MB </div>
          </div> 
          
          <div style={{ padding: "2px" }}>
              <input autoFocus 
                className={"inputform twhite"}
                style={{border: "3px solid cyan" }}
                type="text"
                placeholder="Your Battledog ID"
                value={attackerId}
                onChange={handleAttackerChange}
                alt="Your Battledog ID"
              />
            </div>
            <div className="updateinput">
              <input autoFocus 
                type="file"
                name={'avatar'}
                ref={fileInput}
              />
            
              <button
                className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={handleImageUpload} 
                style={{backgroundColor: `${blockchain.theme}`}}
              >
              {loading ? (<>Customizing...</>) : (<>Customize</>)} 
              </button>
            </div>
                    
                {errorMessageVisible && 
                  <div className="errordisplay tcyan" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                  <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                  </div> 
                }

            </div>
        )}

        </div>
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div>
                  <div className='mobilecontent' align="center">
      <div className={`updatebox updatebox${type}`} align="center">
        <p className={`namebox namebox${type}`} onClick={toggleType}>SET NAME</p>
        <p className={`imagebox imagebox${type}`} onClick={toggleType}>SET AVATAR</p>
      </div>
        {!type && (   
          <div id="updatename">          
          {!attackerId && !type && (
            <img src={require("./assets/images/customize.gif")} className="portraitHTHMobile opacity8" />
          )} 
          {attackerId && !type &&(
            <img src={`${TargetA}`} className="portraitHTHMobile" />
          )}             

            <div style={{ padding: "2px" }}>
              <input autoFocus 
                className={"inputformMb twhite"}
                style={{border: "3px solid cyan"}}
                type="text"
                placeholder="Your Battledog ID"
                value={attackerId}
                onChange={handleAttackerChange}
                alt="Your Battledog ID"
              />
            </div>
            <div>
                <input autoFocus 
                  className={"inputformMb twhite"}
                  style={{border: "3px solid cyan", marginTop: "0px" }}
                  type="text"
                  placeholder=" New Battledog Name"
                  value={name}
                  onChange={handleNameChange}
                  alt="New Battledog Name"
                />
              </div>
                    
                    {errorMessageVisible && 
                      <div className="errordisplay tcyan" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                      </div> 
                    }
            
              <button
                className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={handleCustomizeUpdateClick}
                style={{backgroundColor: `${blockchain.theme}`, border: `2px solid ${blockchain.theme}` }}
              >
              {loading ? (<>Customizing...</>) : (<>Customize</>)} 
              </button>
            </div>
        )}
        
        {type && (  
          <div id="updateimage">          
          {!attackerId && type && (                   
                  <div style={{backgroundColor: `${blockchain.theme}`}} className="nameback portraitHTHMobile">
                  <img src={require("./assets/images/rename-custom.png")} style={{height: '35vh', width: '35vh'}}/>
                  </div>
          )} 
          {attackerId && type && (                  
                  <div style={{backgroundImage: `${targetA}`, backgroundRepeat: "norepeat", backgroundSize: "contain", 
                    backgroundOrigin: "content-box"}} className="portraitHTHMobile">
                  <img src={require("./assets/images/update-avatar-image.png")} style={{height: '45vh', width: '45vh'}} />
                  </div>
          )}     
                   
          <div>
              <div className="upnfont" style={{color: 'white'}}> Acceptable: </div>
              <div className="upnfont" style={{color: `${blockchain.theme}`}}>512 x 512 px , 768 x 768 px <br /> 1024 x 1024 px </div>
              <div className="upnfont" style={{color: 'white'}}> Size Limit: 1 MB </div>
          </div> 
          
          <div style={{ padding: "2px" }}>
              <input autoFocus 
                className={"inputformMb"}
                style={{ color: "white", border: "3px solid cyan", marginBottom: "0px" }}
                type="text"
                placeholder="Your Battledog ID"
                value={attackerId}
                onChange={handleAttackerChange}
                alt="Your Battledog ID"
              />
            </div>
            <div className="updateinput">
              <input autoFocus 
                type="file"
                name={'avatar'}
                ref={fileInput}
              />
            
              <button
                className={"buttongold textstyle"}
                disabled={loading ? 1 : 0}
                onClick={handleImageUpload} 
                style={{backgroundColor: `${blockchain.theme}` }}
              >
              {loading ? (<>Customizing...</>) : (<>Customize</>)} 
              </button>
            </div>
                    
                    {errorMessageVisible && 
                      <div className="errordisplay tcyan" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                      </div> 
                    }

            </div>
        )}

                  </div>

              </div> 
        </Mobile>
      </>   
    );
  } 

  const WeaponizeComponent = () => {
    useEffect(() => {
        if (condition && loading && transactionResult) {
          weaponizeAfterCall();
      }  
    },[loading]);
    return (
      <>
        <Desktop> 
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={handleMouseEnter} title="Leave Arena"><img src={require("./assets/images/bg/backarrow.gif")} className="icons"  onClick={handleStartMenu}/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(19)}} title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(8)}} title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(10)}} title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(11)}} title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} className="icons" /></div>
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
            <div className={"translation"} 
              style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
              {!attackerId && (
                <img src={require("./assets/images/ninja.png")} className="portraitHTH" />
              )} 
              {attackerId && (
                <img src={`${TargetA}`} className="portraitHTH" />
              )}              
                    <div>
                    <input autoFocus  className={"inputform twhite"} style={{border: "3px solid rgb(240, 240, 192)"}}
                    type="text"
                    placeholder="Your Battledog ID"
                    value={attackerId}
                    onChange={handleAttackerChange}
                    alt="Your Battledog ID"
                  />
                    
                    {errorMessageVisible && 
                      <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "30px" }} />
                      </div> 
                    }

                </div>
                  <button className={"buttongold textstyle"}
                  disabled={loading ? 1 : 0}
                  onClick={handleWeaponizeUpdateClick} // Pass handleUpdateClick as the onClick handler
                  style={{
                    border: `2px solid ${blockchain.theme}`}}>
                {loading ? (<>Weaponizing...</>) : (<>Weaponize</>)}  
                </button>
              </div>     
              <div className="notificationsData">
                <Notifications />
              </div>
        </Desktop>
        <Mobile>
              <div className="arsenalDivMobile">               
                <div className='mobilenavigate'>
                <div title="Leave Arena" onClick={handleStartMenu}><img src={require("./assets/images/icons/gameplayreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assault a Battledog" onClick={handleAssaultHelp}><img src={require("./assets/images/icons/assault.png")} style={{height: "50px"}} /></div><br />
                  <div title="Debilitate a Battledog" onClick={handleDebilitateHelp}><img src={require("./assets/images/icons/debilitate.png")} style={{height: "50px"}} /></div><br />
                  <div title="Weaponize Your Battledog" onClick={handleWeaponizeHelp}><img src={require("./assets/images/icons/weaponize.png")} style={{height: "50px"}} /></div><br />
                  <div title="Regenerate a Battledog" onClick={handleRegenerateHelp}><img src={require("./assets/images/icons/regenerate.png")} style={{height: "50px"}} /></div><br />
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                <div className='mobilenotify' align="center">
                    <Notifications />
                </div> 
                
                <div className='mobilecontent' align="center">
                  {!attackerId && (
                    <img src={require("./assets/images/ninja.png")} className="portraitHTHMobile"/>
                  )} 
                  {attackerId && (
                    <img src={`${TargetA}`} className="portraitHTHMobile"/>
                  )}  
                    <input autoFocus  className={"inputformMb twhite"} style={{border: "3px solid rgb(240, 240, 192)"}}
                      type="text"
                      placeholder="Your Battledog ID"
                      value={attackerId}
                      onChange={handleAttackerChange}
                      alt="Your Battledog ID"
                    />
                    
                    {errorMessageVisible && 
                      <div className="errordisplay tred" style={{color: `${blockchain.theme}`}} onClick={allOwnerOfPlayersTx}>{errorMessage}<br />
                      <img src={require(`./assets/images/retry.gif`)} style={{ height: "15px" }} />
                      </div> 
                    }

                    <button className={"buttongold textstyle"}
                      disabled={loading ? 1 : 0}
                      onClick={handleWeaponizeUpdateClick} 
                      style={{
                        border: `2px solid ${blockchain.theme}`}}>
                          {loading ? (<>Weaponizing...</>) : (<>Weaponize</>)}                      
                    </button>                           
                  </div>
              </div> 
        </Mobile>
      </>   
    );
  } 

  function LockComponent () {
    return(
      <>
        <Desktop>
          {!query && (
              <div className="menu1" title={divisionalTitle} onClick={handleQuery}>
              {allowanceImage && <img src={require(`./assets/images/icons/${allowanceImage}.png`)} className="icons" style={{width: "7.5vw"}} />}
              </div>
          )}
          {query && (
            <div onClick={handleQuery}>
              <div className="aplist" title={`Found ${activePlayers.length} Battledogs in the Arena`} onClick={handleScoreboard}>{formatNumber(activePlayers.length)}</div>
              <div className="menu1 wolfslash" title={`Currently ${allPlayers} Battledog NFTs exist`} >
              </div>
              <div className="allPlayersText" title={`Currently ${allPlayers} Battledog NFTs exist`} style={{color: `${blockchain.theme}`}} >
                {formatNumber(allPlayers)}</div>
            </div>
          )}
        </Desktop>
        <Mobile> 
          <div  >
          {!query && (
            <div className="mobile-approval-limit" title={divisionalTitle} align="center" onClick={handleQuery}>          
            {allowanceImage && (<p>{divisionalTitle} <br/> <img src={require(`./assets/images/icons/${allowanceImage}.png`)} style={{height: "40px"}}/>
            </p>)}
            </div>
          )}
          {query && (
            <div className="mobile-approval-limit" align="center" onClick={handleQuery}>
            <div className="wolfslashmb" title={`Currently ${allPlayers} Battledog NFTs exist`} style={{color: `${blockchain.theme}`}} >Currently {formatNumber(allPlayers)} Battledog NFTs exist<br/> <img src={require('./assets/images/wolfslash.png')} style={{height: "40px"}} /></div>
            <div className="aplistmb" title={`Found ${activePlayers.length} Battledogs in the Arena`} onClick={handleScoreboard}>Found {formatNumber(activePlayers.length)} Battledogs in the Arena</div>
            </div>
          )}
          </div>
        </Mobile>
      </>
    );
  }

  const LayoutComponent = () => {
    return (
      <>
      <Desktop>
        <div className='layoutDiv mkMicro' style={{color: `${blockchain.theme}`}} align='center'>
            <NativeBalance /> 
          <PiInfinityLight style={{color: 'whitesmoke', margin: "0vh 1vh 0vh 1vh"}}/>
            <GameBalance />
          {claims && (
            <div onClick={handleMining}>
              <PiInfinityLight style={{color: 'whitesmoke', margin: "0vh 1vh 0vh 1vh"}}/>
              <span className="layoutBox mkMicro" style={{color: 'black', backgroundColor: `${blockchain.theme}`, cursor: 'pointer'}}>{claims} Unclaimed</span>
                {remainingTime > 0 ? (
                <span title={`Locked Claim: Claimable only after ${new Date(remainingTime).toLocaleString()}`}><BsShieldLock /></span>
                ) : (
                <span title={"No locked claims"}><BsShieldLock /></span>
                )} 
            </div>
          )}
        </div>
      </Desktop>
      <Mobile>
        <div className='layoutDivMobile mkNano' style={{color: `${blockchain.theme}`}} align='center'>
            <NativeBalance /> 
          <PiInfinityLight style={{margin: "0vh 1vh 0vh 1vh"}}/>
            <GameBalance />
          {claims && (
            <div onClick={handleMining}>
              <PiInfinityLight style={{color: 'whitesmoke', margin: "0vh 1vh 0vh 1vh"}}/>
              <span className="layoutBoxMobile mkNano" style={{color: 'black', backgroundColor: `${blockchain.theme}`, cursor: 'pointer'}}>{claims} Unclaimed</span>
            </div>
          )}
        </div>
        <div align='center' style={{textShadow: '2px 2px 8px black', margin: '0px 0px 1vh 0px'}}>
          {remainingTime > 0 ? (
            <div className='mkNano' style={{color: `${blockchain.theme}`}}>Locked Claim: Claimable only after {new Date(remainingTime).toLocaleString()} <BsShieldLock /></div>
          ) : (
            <div className='mkNano' style={{color: `${blockchain.theme}`}}><BsShieldLock /> No locked claims <BsShieldLock /></div>
          )} 
        </div>
      </Mobile>
      </>
    );
  }

  const Notifications = () => {  
    return (
    <>
    <Desktop>
      <>
        <div>
          <div align='center'><button className={"tblack nbmisc"} onClick={handleHideNotifications}
          style={buttonStyle}> {hideNotifications ? "Hide Notifications" : "View Notifications"}</button></div>
          <div className={"textstyle twhitesmoke"} style={{fontWeight: "bold"}}>Monitor Feeds: Yours, Allies or Enemies </div>
          <div className={"notifications"} style={{display: hideNotifications ? 'flex' : 'none'}}>

          {mintLogs.length === 0 ? null : (
                mintLogs.map((log, index) => (
                  <div key={index} className={"notify"} style={{backgroundColor: 'violet'}}>               
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>                  
                  <p className={"notifyText"} ><GiWarPick/></p>   
                  <img className={"notifyImg brPink"} src={(`https://battledogs.fun/engine/${log.tokenId}.png`)} alt="Minter" />
                  <p className={"notifyText"}><span style={{color:'maroon', fontFamily: 'mkMyth'}}> {log.name} Arrives </span>!</p>               
                  </div>
                  </div>
                ))
              )
          }

          {assaultLogs.length === 0 ? null : (
              assaultLogs.map((log, index) => (
                <div key={index} className={"notify notifyText"} style={{backgroundColor: "rgb(82, 58, 50)"}}>
                    <GiZeusSword/>
                    <img className={"notifyImg brwhite"} src={(`https://battledogs.fun/engine/${log.attackerId}.png`)} alt="Attacker" />
                    <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.attackerId} </span> <span style={{color: 'white'}}>VS</span> <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.defenderId} </span>
                    <img className={"notifyImg brwhite"} src={(`https://battledogs.fun/engine/${log.defenderId}.png`)} alt="Defender" />
                    for <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.stolenPoints} </span> points!
                </div>       
              ))
            )
          }

          {assaultWins.length === 0 ? null : (
            assaultWins.map((log, index) => {
              // Calculate the result by dividing log.payreward by reducer
              const result = log.payreward / reducer;

              return (
                <div key={index} className={"notify"} style={{backgroundColor: "gold"}}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p className={"notifyText"}><GiTrophyCup/></p>
                    <img className={"notifyImg brGold"} src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="AssaultPayout" />
                    {log.playerId}
                    <p className={"notifyText"}> just claimed <span style={{color: `${blockchain.theme}`}}>{result} ${blockchain.tokenA}</span>!</p>
                  </div>
                </div>
              );
            })
          )}

          {debilitateLogs.length === 0 ? null : (
              debilitateLogs.map((log, index) => (
                <div key={index} className={"notify"} style={{backgroundColor: "teal"}}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p className={"notifyText"}><Gi3dHammer/></p>
                    <img className={"notifyImg brBlue"} src={(`https://battledogs.fun/engine/${log.attackerId}.png`)} alt="Attacker" />
                    <p className={"notifyText"}> <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.attackerId} </span> 
                    <span style={{color: "white"}}>VS</span> 
                    <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.defenderId} </span></p>
                    <img className={"notifyImg brBlue"} src={(`https://battledogs.fun/engine/${log.defenderId}.png`)} alt="Defender" />
                    <p className={"notifyText"}> for <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.stolenPoints} </span> points!</p>
                </div>
                </div>
              ))
            )
          }

          {debilitateWins.length === 0 ? null : (
            debilitateWins.map((log, index) => {
              // Calculate the result by dividing log.payreward by reducer
              const result = log.payreward / reducer;

              return (
                <div key={index} className={"notify"} style={{backgroundColor: "indigo"}}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p className={"notifyText twhite"}><GiLaurelsTrophy/></p>
                    <img className={"notifyImg brwhite twhite"} src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="DebilitatePayout" />
                    {log.playerId}
                    <p className={"notifyText twhite"}> just claimed <span style={{color: "white"}}>{result} ${blockchain.tokenA}</span>!</p>
                  </div>
                </div>
              );
            })
          )}

          {levelLogs.length === 0 ? null : (
              levelLogs.map((log, index) => (
                <div key={index} className={"notify"} style={{backgroundColor: "orange"}}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p className={"notifyText"}><GiStarMedal/></p>
                <img className={"notifyImg brOrange"} src={(`https://battledogs.fun/engine/${log.playerId}.png`)} alt="LevelUp" />
                  {log.playerId}
                  <p className={"notifyText"}> levels up to <span style={{color: `${blockchain.theme}`}}>{log.level}</span>!</p>
                </div>
                </div>
              ))
            )
          }

          {payoutsLog.length === 0 ? null : (
            payoutsLog.map((log, index) => {
              // Calculate the result by dividing log.amount by 10^2
              const result = log.amount / decimals;

              return (
                <div key={index} className={"notify"} style={{backgroundColor: "gold"}}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p className={"notifyText"}><GiWallet/></p>   
                    <img className={"notifyImg brMagenta"} src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="CashOut" />
                    {log.playerId}
                    <p className={"notifyText"}> just cashed out <span style={{color: "gold"}}>{result} ${blockchain.tokenA}</span>!
                    </p>
                  </div>
                </div>
              );
            })
          )}
          </div>        
        </div> 
      </>
    </Desktop>
    <Mobile>
      <>        
      {mintLogs.length === 0 ? null : (
                mintLogs.map((log, index) => (
                  <div key={index} className={"notify"} style={{backgroundColor: 'violet'}}>               
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>                  
                  <p className={"notifyText"} ><GiWarPick/></p>   
                  <img className={"notifyImg brPink"} src={(`https://battledogs.fun/engine/${log.tokenId}.png`)} alt="Minter" />
                  <p className={"notifyText"}><span style={{color:'maroon', fontFamily: 'mkMyth'}}> {log.name} Arrives </span>! </p>               
                  </div>
                  </div>
                ))
              )
          }

          {assaultLogs.length === 0 ? null : (
              assaultLogs.map((log, index) => (
                <div key={index} className={"notify notifyText"} style={{backgroundColor: "rgb(82, 58, 50)"}}>
                    <GiZeusSword/>
                    <img className={"notifyImg brwhite"} src={(`https://battledogs.fun/engine/${log.attackerId}.png`)} alt="Attacker" />
                    <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.attackerId} </span> 
                    <span style={{color: 'white'}}>VS</span> 
                    <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.defenderId} </span>
                    <img className={"notifyImg brwhite"} src={(`https://battledogs.fun/engine/${log.defenderId}.png`)} alt="Defender" />
                    for <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.stolenPoints} </span> points!
                  </div> 
              ))
            )
          }

          {assaultWins.length === 0 ? null : (
            assaultWins.map((log, index) => {
              // Calculate the result by dividing log.payreward by reducer
              const result = log.payreward / reducer;

              return (
                <div key={index} className={"notify"} style={{backgroundColor: "gold"}}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p className={"notifyText"}><GiTrophyCup/></p>
                    <img className={"notifyImg brGold"} src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="AssaultPayout" />
                    {log.playerId}
                    <p className={"notifyText"}> just claimed <span style={{color: `${blockchain.theme}`}}>{result} ${blockchain.tokenA}</span>!</p>
                  </div>
                </div>
              );
            })
          )}

          {debilitateLogs.length === 0 ? null : (
              debilitateLogs.map((log, index) => (
                <div key={index} className={"notify"} style={{backgroundColor: "teal"}}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p className={"notifyText"}><Gi3dHammer/></p>
                    <img className={"notifyImg brBlue"} src={(`https://battledogs.fun/engine/${log.attackerId}.png`)} alt="Attacker" />
                    <p className={"notifyText"}> <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.attackerId} </span> 
                    <span style={{color: "white"}}>VS</span> 
                    <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.defenderId} </span></p>
                    <img className={"notifyImg brBlue"} src={(`https://battledogs.fun/engine/${log.defenderId}.png`)} alt="Defender" />
                    <p className={"notifyText"}> for <span style={{color: `${blockchain.theme}`, fontFamily: 'mkMyth', fontSize: '2.75vh'}}> {log.stolenPoints} </span> points!</p>
                </div>
                </div>
              ))
            )
          }

          {debilitateWins.length === 0 ? null : (
            debilitateWins.map((log, index) => {
              // Calculate the result by dividing log.payreward by reducer
              const result = log.payreward / reducer;

              return (
                <div key={index} className={"notify"} style={{backgroundColor: "purple"}}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p className={"notifyText twhite"}><GiLaurelsTrophy/></p>
                    <img className={"notifyImg brwhite twhite"} src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="DebilitatePayout" />
                    {log.playerId}
                    <p className={"notifyText twhite"}> just claimed <span style={{color: `${blockchain.theme}`}}>{result} ${blockchain.tokenA}</span>!</p>
                  </div>
                </div>
              );
            })
          )}

          {levelLogs.length === 0 ? null : (
              levelLogs.map((log, index) => (
                <div key={index} className={"notify"} style={{backgroundColor: "orange"}}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p className={"notifyText"}><GiStarMedal/></p>
                <img className={"notifyImg brOrange"} src={(`https://battledogs.fun/engine/${log.playerId}.png`)} alt="LevelUp" />
                  {log.playerId}
                  <p className={"notifyText"}> levels up to <span style={{color: `${blockchain.theme}`}}>{log.level}</span>!</p>
                </div>
                </div>
              ))
            )
          }

          {payoutsLog.length === 0 ? null : (
            payoutsLog.map((log, index) => {
              // Calculate the result by dividing log.amount by 10^2
              const result = log.amount / decimals;

              return (
                <div key={index} className={"notify"} style={{backgroundColor: "gold"}}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p className={"notifyText"}><GiWallet/></p>   
                    <img className={"notifyImg brMagenta"} src={`https://battledogs.fun/engine/${log.playerId}.png`} alt="CashOut" />
                    {log.playerId}
                    <p className={"notifyText"}> just cashed out <span style={{color: "gold"}}>{result} ${blockchain.tokenA}</span>!
                    </p>
                  </div>
                </div>
              );
            })
          )}      
        </>
    </Mobile>
    </>
    );
  }
  
  const ActivityTrackerComponent = () => {
    return (
      <>
      <Desktop>
              <div className={`menu2 smbkg${startMenuBkg}`}>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(0)}} title="Leave Tools" onClick={handleStartMenu}><img src={require("./assets/images/bg/backarrow.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} className="icons" /></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(16)}} title="Mine GAME token from your gameplay"onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} className="icons" /></div> 
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(15)}} title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} className="icons"/></div>
                <div onMouseEnter={() => {handleMouseEnter(); setIsHovered(true); setStartMenuBkg(5)}} title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} className="icons" /></div>               
                <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} className="icons" /></a> </div>
              </div>
        <div className={"activitytracker"} align={"center"}>
            <div>
            <div className="actheader" onClick={handleScoreboard}>
              <img className='scoreboardImg' src={require("./assets/images/activitytracker.png")}
              style={{backgroundColor: `${blockchain.theme}`, height: "6vh"}}/></div>
              <div>
              <button className={"buttongold tred"} onClick={getAssaulters} disabled={loading ? 1 : 0} style={{backgroundColor: 'pink'}}>Track Assaulters</button>
              <button className={"buttongold tblue"} onClick={getDebilitators} disabled={loading ? 1 : 0} style={{backgroundColor: 'blue'}}>Track Debilitators</button>
              </div>             
          {mappedAssaulters}
          {mappedDebilitators}
          </div>
        
        </div>

        <div className={"notifyInput"} align={"center"}>
        <div className={"texttheme twhite"} style={{fontWeight: "bold"}}>Track Battledog Activity</div>
        <form autoComplete="on" onSubmit={(e) => {handleSubmitter(e, handleActivityTrackerSubmit)}}> 
          <input autoFocus  name="input1" 
          className={"inputform"} style={{color: "white", border: "3px solid goldenrod", fontSize: '1.5vh'}}
                type="text" 
                placeholder="Enter ID(s) separated only by commas"
          />
          <button className={"button0 txt"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Monitor</button>
          <button className="button1 txt" onClick={viewAll} style={{border: `2px solid ${blockchain.theme}`}}>View All</button>
         </form>

        <div className={"texttheme twhite"}style={{fontWeight: "bold"}}>Ignore Battledog</div>
        <form autoComplete="on" onSubmit={(e) => {handleSubmitter(e, handleRemove)}}> 
            <input autoFocus  name="input1" 
            className={"inputform"} style={{color: "white", border: "3px solid goldenrod", fontSize: '1.5vh'}}
            type="text"
            placeholder="Enter ID(s) separated only by commas"
            />  
            <button className={"button0 txt"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Remove</button>
            <button className="button1 txt" onClick={handleWipe} style={{
  border: `2px solid ${blockchain.theme}`}}>Wipe All</button>
        </form>
            
          

      </div>  
     <div className="hunted" style={{color: `${blockchain.theme}`}}>
     <span style={{color: "white", fontSize: "30px"}}><img src={require("./assets/images/eye.gif")} style={{height: '10vh'}} /></span>
     {handleTrackerView()}
     </div>
      </Desktop>
      <Mobile>             
                <div className='mobilenavigate'>
                  <div title="Leave Toolspace" onClick={handleStartMenu}><img src={require("./assets/images/icons/toolsreturn.png")} style={{height: "50px"}} /></div><br />
                  <div title="Assign another nickname" onClick={handleCustomizeHelp}><img src={require("./assets/images/icons/customize.gif")} style={{height: "50px"}} /></div>
                  <div title="Mine GAME token from your gameplay" onClick={handleMining}><img src={require("./assets/images/icons/mine.png")} style={{height: "50px"}}/></div>
                  <div title={`Stake GAME to Harvest ${blockchain.tokenA}`} onClick={handleHarvester}><img src={require("./assets/images/icons/harvest.png")} style={{height: "50px"}}/></div>
                  <div title="Reset Your Call Limit" onClick={handleResetTimerHelp}><img src={require("./assets/images/icons/resetTimer.gif")} style={{height: "50px"}} /></div>
                  <div className="spinner" title="Read Guidebook"><a href={`https://guide.${blockchain.ecosystem}.battledogs.fun`} target="_blank"> <img src={require("./assets/images/icons/guidebook.gif")} style={{height: "50px"}} /></a></div><br />                     
                </div>           
                  <img className="activityTrackerImgMobile" src={require("./assets/images/activitytracker.png")} 
                      style={{backgroundColor: `${blockchain.theme}`}}/>
              <div className="mobileye" align="center" style={{alignItems: "center", justifyContent: "center"}}>
              <span style={{color: "white", fontSize: "30px"}}><img src={require("./assets/images/eye.gif")} style={{height: '8vh'}} /></span>
              {handleTrackerView()}
              </div>
              <div className='mobilecontent' align="center">
                <div>
                <div onClick={handleScoreboard}></div>
                  {mappedAssaulters}
                  {mappedDebilitators}
                    <div>
                    <button className={"buttongold brPink tred"} onClick={getAssaulters} disabled={loading ? 1 : 0} style={{backgroundColor: 'pink'}}>Track Assaulters</button>
                    <button className={"buttongold brBlue tblue"} onClick={getDebilitators} disabled={loading ? 1 : 0} style={{backgroundColor: 'blue'}}>Track Debilitators</button>
                  </div> 
                </div>                    
              </div>
              <div className="mobilecontent" align="center">
                <p className={"texttheme twhite"} style={{fontFamily: "NexaHeavy", fontWeight: "bold", marginTop: "4px"}}>Track Battledog Activity</p>
                <form autoComplete="on" onSubmit={(e) => {handleSubmitter(e, handleActivityTrackerSubmit)}}> 
                <input autoFocus  name="input1" 
                  className={"inputformMb"} style={{color: "white", border: "3px solid goldenrod"}}
                        type="text" 
                        placeholder="Enter ID(s) separated by commas"
                        />
                </form>
                <div style={{marginBottom: "4px"}}>
                  <button className={"button0 txt"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Monitor</button>
                  <button className="button1 txt" onClick={viewAll} style={{border: `2px solid ${blockchain.theme}`}}>View All</button>
                </div>
                <p className={"texttheme twhite"} style={{fontFamily: "NexaHeavy", fontWeight: "bold", marginTop: "4px"}}>Ignore Battledog</p>
                <form autoComplete="on" onSubmit={(e) => {handleSubmitter(e, handleRemove)}}> 
                    <input autoFocus  name="input1" 
                    className={"inputformMb"} style={{color: "white", border: "3px solid goldenrod"}}
                    type="text"
                    placeholder="Enter ID(s) separated by commas"
                    />  
                </form>
                <div style={{marginBottom: "4px"}}>
                  <button className={"button0 txt"} type="submit" style={{border: `2px solid ${blockchain.theme}`}}>Remove</button>
                  <button className="button1 txt" onClick={handleWipe} style={{border: `2px solid ${blockchain.theme}`}}>Wipe All</button>
                </div>
              </div>        
      </Mobile>
      </>
    );
  }

  // Help components

  const StartHelpComponent = () => {
    return (
      <>
      <Desktop> 
        <div className="alignCenter" >
          <div className="mkSmall tblack" align="center">Quick Tutorial</div>
            <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleMint}>
              <div className="helpCol"  >
                <p className="mkSmall"><span className="tmint">1.</span> Mint a Battledog NFT </p>
                <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
              </div>                
                <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">2.</span> Approve enough {blockchain.tokenA} for gameplay  </p>
                <img src={require('./assets/images/approve-vis.png')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
              </div>                
              </div>
              <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <div className="mkSmall"><span className="tmint">3.</span> Activate your Battledog NFT </div> 
                <div className="mkNano tmint">Hint: You can mine more {blockchain.tokenB} than other players by forfeiting wins</div>
                <img src={require('./assets/images/activate.jpg')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
              </div>                
              </div>              
            </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="alignCenter" >
          <div className="mkMicro tblack" align="center">Quick Tutorial</div>
            <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleMint}>
                <p className="mkNano"><span className="tmint">1.</span> Mint a Battledog NFT </p>
                <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">2.</span> Approve enough {blockchain.tokenA} for gameplay  </p>
                <img src={require('./assets/images/approve-vis.png')} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">3.</span> Activate your Battledog NFT.</p>
                <div className="mkNano tmint">Hint: You can mine more {blockchain.tokenB} <br />than others by forfeiting wins</div>
                <img src={require('./assets/images/activate.jpg')} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
            </div>
        </div>
      </Mobile>
      </>   
    );
  } 

    // Help components

    const ActivateHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Activate </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleActivate}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> There are two main tiers of Activation </p>
                  <div className="mkNano tmint">Hint: <span className='torange'>First tier</span> is the very first time you activate a Battledog. The <span className='torange'>Other Tiers</span> are subsequent times you chose to Activate Up That Battledog</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Pay {(activatingAmount / decimals)} {blockchain.tokenA} For 1st Time Activations </p>
                  <div className="mkNano tmint">Hint: This is a <span className='torange'>One-time-fee</span> required to enter your Battledog NFT into the Arena</div>
                  <img src={require('./assets/images/one.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/guidance.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span><span className='torange'> Burn </span> at least {charge} {blockchain.tokenB} to Activate Up </p> 
                  <div className="mkNano tmint">Hint: Whenever you choose, you can <span className='torange'>burn</span> multiple fees in {blockchain.tokenB} forever to boost your Battledogs</div>
                  <img src={require('./assets/images/four.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center">How to Activate</div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleActivate}>
                  <p className="mkNano"><span className="tmint">1.</span> There are two types of Activation </p>
                  <div className="mkNano tmint">Hint: <span className='torange'>First tier</span> is the very first time you activate a Battledog. The <span className='torange'>Other Tiers</span> are subsequent times you chose to Activate Up That Battledog</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Pay {(activatingAmount / decimals)} {blockchain.tokenA} For 1st Time Activations </p>
                  <div className="mkNano tmint">Hint: This is a <span className='torange'>One-time-fee</span> required to enter your Battledog NFT into the Arena</div>
                  <img src={require('./assets/images/one.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/swap.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span><span className='torange'> Burn </span> at least {charge} {blockchain.tokenB} to Activate Up</p>
                  <div className="mkNano tmint">Hint: Whenever you choose, you can <span className='torange'>burn</span> multiple fees in {blockchain.tokenB} forever to boost your Battledogs</div>
                  <img src={require('./assets/images/four.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }
  
    const AssaultHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Attack </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleAssault}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> There are only two types of Attacks </p>
                  <div className="mkNano tmint">Hint: <span style={{color: `${blockchain.theme}`}}>First type</span> is called an Assault. The <span style={{color: `${blockchain.theme}`}}>Second Type</span> is known as a debilitation</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Ensure you have {(requiredAmount / decimals)} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each assault or debilitation, you pay <span style={{color: `${blockchain.theme}`}}>{(requiredAmount / decimals)} {blockchain.tokenA}</span> onchain. Visit the Scoreboard to find other <span style={{color: `${blockchain.theme}`}}>battledogs</span> in the Arena </div>
                  <img src={require('./assets/images/icons/archery.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> 24-Hour Cool Down Period For  Attacks</p> 
                  <div className="mkNano tmint">Hint: You can only assault or debilitate a specific Battledog every <span style={{color: `${blockchain.theme}`}}>24 hours</span> in the Arena </div>
                  <img src={require('./assets/images/icons/assault.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center">How to Attack</div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleAssault}>
                  <p className="mkNano"><span className="tmint">1.</span> There are only two types of Attacks </p>
                  <div className="mkNano tmint">Hint: <span style={{color: `${blockchain.theme}`}}>First type</span> is called an Assault. The <span style={{color: `${blockchain.theme}`}}>Second Type</span> is known as a debilitation</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Ensure you have {(requiredAmount / decimals)} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each assault or debilitation, you pay <span style={{color: `${blockchain.theme}`}}>{(requiredAmount / decimals)} {blockchain.tokenA}</span> onchain.  Visit the Scoreboard to find other <span style={{color: `${blockchain.theme}`}}>battledogs</span> in the Arena </div>
                  <img src={require('./assets/images/icons/archery.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> 24-Hour Cool Down Period For  Attacks</p>
                  <div className="mkNano tmint">Hint: You can only assault or debilitate a specific Battledog every <span style={{color: `${blockchain.theme}`}}>24 hours</span> in the Arena </div>
                  <img src={require('./assets/images/icons/assault.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }
  
    const DebilitateHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Attack </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleDebilitate}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> There are only two types of Attacks </p>
                  <div className="mkNano tmint">Hint: <span style={{color: `${blockchain.theme}`}}>First type</span> is called an Assault. The <span style={{color: `${blockchain.theme}`}}>Second Type</span> is known as a debilitation</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Ensure you have {(requiredAmount / decimals)} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each assault or debilitation, you pay <span style={{color: `${blockchain.theme}`}}>{(requiredAmount / decimals)} {blockchain.tokenA}</span> onchain. Visit the Scoreboard to find other <span style={{color: `${blockchain.theme}`}}>battledogs</span> in the Arena </div>
                  <img src={require('./assets/images/icons/slow-cooker.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> 24-Hour Cool Down Period For  Attacks</p> 
                  <div className="mkNano tmint">Hint: You can only assault or debilitate a specific Battledog every <span style={{color: `${blockchain.theme}`}}>24 hours</span> in the Arena </div>
                  <img src={require('./assets/images/icons/snail.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center">How to Attack</div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleDebilitate}>
                  <p className="mkNano"><span className="tmint">1.</span> There are only two types of Attacks </p>
                  <div className="mkNano tmint">Hint: <span style={{color: `${blockchain.theme}`}}>First type</span> is called an Assault. The <span style={{color: `${blockchain.theme}`}}>Second Type</span> is known as a debilitation</div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Ensure you have {(requiredAmount / decimals)} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each assault or debilitation, you pay <span style={{color: `${blockchain.theme}`}}>{(requiredAmount / decimals)} {blockchain.tokenA}</span> onchain.  Visit the Scoreboard to find other <span style={{color: `${blockchain.theme}`}}>battledogs</span> in the Arena </div>
                  <img src={require('./assets/images/icons/slow-cooker.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> 24-Hour Cool Down Period For  Attacks</p>
                  <div className="mkNano tmint">Hint: You can only assault or debilitate a specific Battledog every <span style={{color: `${blockchain.theme}`}}>24 hours</span> in the Arena </div>
                  <img src={require('./assets/images/icons/snail.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    } 
  
    const WeaponizeHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> Quick Tutorial </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleWeaponize}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> You can Weaponize Any Battledog </p>
                  <div className="mkNano tmint">Hint: This is usually done when you're <span style={{color: `${blockchain.theme}`}}>out</span> of <span style={{color: `${blockchain.theme}`}}>Aggression</span></div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Ensure you have {(requiredAmount / decimals)} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each weaponization, you pay <span style={{color: `${blockchain.theme}`}}>{(requiredAmount / decimals)} {blockchain.tokenA}</span> onchain. </div>
                  <img src={require('./assets/images/swords_no.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> No Limits or Rewards to weaponization </p> 
                  <div className="mkNano tmint">Hint: There is <span style={{color: `${blockchain.theme}`}}>no limit</span> to the number of times you can weaponize a battledog, but <span style={{color: `${blockchain.theme}`}}>no rewards</span> for it either. </div>
                  <img src={require('./assets/images/swords.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> Quick Tutorial </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleWeaponize}>
                  <p className="mkNano"><span className="tmint">1.</span> You can weaponize any battledog </p>
                  <div className="mkNano tmint">Hint: This is usually done when you're <span style={{color: `${blockchain.theme}`}}>out</span> of <span style={{color: `${blockchain.theme}`}}>Aggression</span></div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Ensure you have {(requiredAmount / decimals)} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each weaponization, you pay <span style={{color: `${blockchain.theme}`}}>{(requiredAmount / decimals)} {blockchain.tokenA}</span> onchain. </div>
                  <img src={require('./assets/images/swords_no.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> No Limits or Rewards to weaponization </p>
                  <div className="mkNano tmint">Hint: There is <span style={{color: `${blockchain.theme}`}}>no limit</span> to the number of times you can weaponize a battledog, but <span style={{color: `${blockchain.theme}`}}>no rewards</span> for it either. </div>
                  <img src={require('./assets/images/swords.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }  
  
    const RegenerateHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Regenerate </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleRegenerate}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> You can Regenerate Any Battledog </p>
                  <div className="mkNano tmint">Hint: This is usually done when you're <span style={{color: `${blockchain.theme}`}}>out</span> of <span style={{color: `${blockchain.theme}`}}>Stamina</span></div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Ensure you have {(requiredAmount / decimals)} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each regeneration, you pay <span style={{color: `${blockchain.theme}`}}>{(requiredAmount / decimals)} {blockchain.tokenA}</span> onchain. </div>
                  <img src={require('./assets/images/coca.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> No Limits or Rewards to Regeneration </p> 
                  <div className="mkNano tmint">Hint: There is <span style={{color: `${blockchain.theme}`}}>no limit</span> to the number of times you can regenerate a battledog, but <span style={{color: `${blockchain.theme}`}}>no rewards</span> for it either. </div>
                  <img src={require('./assets/images/pottery.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Regenerate </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleRegenerate}>
                  <p className="mkNano"><span className="tmint">1.</span> You can regenerate any battledog </p>
                  <div className="mkNano tmint">Hint: This is usually done when you're <span style={{color: `${blockchain.theme}`}}>out</span> of <span style={{color: `${blockchain.theme}`}}>Stamina</span></div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Ensure you have {(requiredAmount / decimals)} {blockchain.tokenA} approved. </p>
                  <div className="mkNano tmint">Hint: For each regeneration, you pay <span style={{color: `${blockchain.theme}`}}>{(requiredAmount / decimals)} {blockchain.tokenA}</span> onchain. </div>
                  <img src={require('./assets/images/coca.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> No Limits or Rewards to Regeneration </p>
                  <div className="mkNano tmint">Hint: There is <span style={{color: `${blockchain.theme}`}}>no limit</span> to the number of times you can regenerate a battledog, but <span style={{color: `${blockchain.theme}`}}>no rewards</span> for it either. </div>
                  <img src={require('./assets/images/pottery.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }   
  
    const ClaimAssaultHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Claim A Win </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleClaimAssault }>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Claiming Wins Onchain Come With Great Rewards </p>
                  <div className="mkNano tmint">Hint: Every win in essence is <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} payable to your wallet.</span> So claim as many wins as you possibly can. </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> For every 100 points you earn, you can claim a win </p>
                  <div className="mkNano tmint">Hint: Be it an <span style={{color: `${blockchain.theme}`}}>Assault or Debilitate</span> win, a win only registers to your Battledog when you choose to claim it onchain </div>
                  <img src={require('./assets/images/icons/claimAssault.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Wins are cumulative or net positive by design </p> 
                  <div className="mkNano tmint">Hint: Remember a win is <span style={{color: `${blockchain.theme}`}}>100 additional points</span> which means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Claim A Win </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleClaimAssault}>
                  <p className="mkNano"><span className="tmint">1.</span> Claiming Wins Onchain Come With Great Rewards </p>
                  <div className="mkNano tmint">Hint: Every win in essence is <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} payable to your wallet.</span> So claim as many wins as you possibly can. </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> For every 100 points you earn, you can claim a win </p>
                  <div className="mkNano tmint">Hint: Be it an <span style={{color: `${blockchain.theme}`}}>Assault or Debilitate</span> win, a win only registers to your Battledog when you choose to claim it onchain </div>
                  <img src={require('./assets/images/icons/claimAssault.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Wins are cumulative or net positive by design </p>
                  <div className="mkNano tmint">Hint: Remember a win is <span style={{color: `${blockchain.theme}`}}>100 additional points</span> which means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }    
  
    const ClaimDebilitateHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Claim A Win </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleClaimDebilitate}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Claiming Wins Onchain Come With Great Rewards </p>
                  <div className="mkNano tmint">Hint: Every win in essence is <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} payable to your wallet.</span> So claim as many wins as you possibly can. </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> For every 100 points you earn, you can claim a win </p>
                  <div className="mkNano tmint">Hint: Be it a <span style={{color: `${blockchain.theme}`}}>Debilitate or Assault</span> win, a win only registers to your Battledog when you choose to claim it onchain </div>
                  <img src={require('./assets/images/icons/claimDebilitate.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Wins are cumulative or net positive by design </p> 
                  <div className="mkNano tmint">Hint: Remember a win is <span style={{color: `${blockchain.theme}`}}>100 additional points</span> which means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Claim A Win </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleClaimDebilitate}>
                  <p className="mkNano"><span className="tmint">1.</span> Claiming Wins Onchain Come With Great Rewards </p>
                  <div className="mkNano tmint">Hint: Every win in essence is <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} payable to your wallet.</span> So claim as many wins as you possibly can. </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> For every 100 points you earn, you can claim a win </p>
                  <div className="mkNano tmint">Hint: Be it a <span style={{color: `${blockchain.theme}`}}>Debilitate or Assault</span> win, a win only registers to your Battledog when you choose to claim it onchain </div>
                  <img src={require('./assets/images/icons/claimDebilitate.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Wins are cumulative or net positive by design </p>
                  <div className="mkNano tmint">Hint: Remember a win is <span style={{color: `${blockchain.theme}`}}>100 additional points</span> which means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }     
  
    const LevelUpHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Level Up </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleLevelUp}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Leveled Up Battledogs are hard to defeat </p>
                  <div className="mkNano tmint">Hint: The onchain game theory behind this is because battledogs of <span style={{color: `${blockchain.theme}`}}>higher levels earn 20 points</span> for attacks on Battledogs of lower levels, while the reverse earns the attacker only 10 points </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> You need to <span className='tOrange'>burn</span> {blockchain.tokenB} to Level Up </p>
                  <div className="mkNano tmint">Hint: Burning {blockchain.tokenB} to level up isn't a fixed amount but a fixed ratio calculated as the direct multiple of your <span style={{color: `${blockchain.theme}`}}>current Activation Level</span> against {charge} {blockchain.tokenB} </div>
                  <img src={require('./assets/images/progress.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Ensure you have sufficient {blockchain.tokenB} approved </p> 
                  <div className="mkNano tmint">Hint: All {blockchain.tokenB} spent while leveling up your Battledog is burnt forever, completely and transparently onchain. <span style={{color: `${blockchain.theme}`}}>100 additional points</span> which means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Level Up </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleLevelUp}>
                  <p className="mkNano"><span className="tmint">1.</span> Leveled Up Battledogs are hard to defeat </p>
                  <div className="mkNano tmint">Hint: The onchain game theory behind this is because battledogs of <span style={{color: `${blockchain.theme}`}}>higher levels earn 20 points</span> for attacks on Battledogs of lower levels, while the reverse earns the attacker only 10 points </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> You need to <span className='tOrange'>burn</span> {blockchain.tokenB} to Level Up </p>
                  <div className="mkNano tmint">Hint: Burning {blockchain.tokenB} to level up isn't a fixed amount but a fixed ratio calculated as the direct multiple of your <span style={{color: `${blockchain.theme}`}}>current Activation Level</span> against {charge} {blockchain.tokenB} </div>
                  <img src={require('./assets/images/progress.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Ensure you have sufficient {blockchain.tokenB} approved </p>
                  <div className="mkNano tmint">Hint: All {blockchain.tokenB} spent while leveling up your Battledog is burnt forever, completely and transparently onchain. <span style={{color: `${blockchain.theme}`}}>100 additional points</span> means every point you lose to the enemy is a point you have to earn back. </div>
                  <img src={require('./assets/images/fireworks.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }     
  
    const PayoutHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Cash Out </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handlePayout}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Cashing out {blockchain.tokenA} to your wallet is easy. </p>
                  <div className="mkNano tmint">Hint: As long as all conditions for winning are met, you can choose to cash out all <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} won</span> while playing Battledogs Arena </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Level Up Your Battledog <span className='tOrange'>At Least Once</span> </p>
                  <div className="mkNano tmint">Hint: To Ensure Players Are Actual Players, We Require the Battledog withdrawing its earnings in {blockchain.tokenA} to level up at least once in the game</div>
                  <img src={require('./assets/images/icons/wallet_no.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Have At Least 5 Wins under its belt </p> 
                  <div className="mkNano tmint">Hint: The Minimum Threshold for making a withdrawal in {blockchain.tokenA} is <span style={{color: `${blockchain.theme}`}}>5 wins</span>  This is spent for each withdrawal.</div>
                  <img src={require('./assets/images/icons/wallet.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Cash Out </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handlePayout}>
                  <p className="mkNano"><span className="tmint">1.</span> Cashing out {blockchain.tokenA} to your wallet is easy. </p>
                  <div className="mkNano tmint">Hint: As long as all conditions for winning are met, you can choose to cash out all <span style={{color: `${blockchain.theme}`}}>{blockchain.tokenA} won</span> while playing Battledogs Arena </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Level Up Your Battledog <span className='tOrange'>At Least Once</span> </p>
                  <div className="mkNano tmint">Hint: Burning {blockchain.tokenB} to level up isn't a fixed amount but a fixed ratio calculated as the direct multiple of your <span style={{color: `${blockchain.theme}`}}>current Activation Level</span> against {charge} {blockchain.tokenB} </div>
                  <img src={require('./assets/images/icons/wallet_no.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Have At Least 5 Wins under its belt </p>
                  <div className="mkNano tmint">Hint: The Minimum Threshold for making a withdrawal in {blockchain.tokenA} is <span style={{color: `${blockchain.theme}`}}>5 wins</span> This is spent for each withdrawal.</div>
                  <img src={require('./assets/images/icons/wallet.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }     
  
    const ResetTimerHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> How to Reset </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleResetTimer}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Our Reset is an anti-bot Feature</p>
                  <div className="mkNano tmint">Hint: Once a Battledog's transactions exceed close to <span style={{color: `${blockchain.theme}`}}>1000 transactions during gameplay</span> that Battledog needs to reset itself to play </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> A Reset Can Be Done At Any Time </p>
                  <div className="mkNano tmint">Hint: Although a reset feature is present, to Avoid Abuse There is also a <span style={{color: `${blockchain.theme}`}}>blacklist feature</span> in Battledogs Arena </div>
                  <img src={require('./assets/images/icons/resetTimer.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Anti-Bot Protection & Blacklisting </p> 
                  <div className="mkNano tmint">Hint: If we notice an <span style={{color: `${blockchain.theme}`}}>abuse of gameplay</span> or our reset feature, our blacklist mechanism intercedes </div>
                  <img src={require('./assets/images/robot.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> How to Reset </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleResetTimer}>
                  <p className="mkNano"><span className="tmint">1.</span> Our Reset is an anti-bot feature</p>
                  <div className="mkNano tmint">Hint: Once a Battledog's transactions exceed close to <span style={{color: `${blockchain.theme}`}}>1000 transactions during gameplay</span> that Battledog needs to reset itself to play </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> A Reset Can Be Done At Any Time </p>
                  <div className="mkNano tmint">Hint: Although a reset feature is present, to Avoid Abuse There is also a <span style={{color: `${blockchain.theme}`}}>blacklist feature</span> in Battledogs Arena </div>
                  <img src={require('./assets/images/icons/resetTimer.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Anti-Bot Protection & Blacklisting </p>
                  <div className="mkNano tmint">Hint: If we notice an <span style={{color: `${blockchain.theme}`}}>abuse of gameplay</span> or our reset feature, our blacklist mechanism intercedes </div>
                  <img src={require('./assets/images/robot.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }     
  
    const CustomizeHelpComponent = () => {
      return (
        <>
        <Desktop> 
          <div className="alignCenter" >
            <div className="mkSmall tblack" align="center"> NFT Customization </div>
              <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleCustomize}>
                <div className="helpCol"  >
                  <p className="mkSmall"><span className="tmint">1.</span> Our Gaming NFTs are fully customizable </p>
                  <div className="mkNano tmint">Hint: A more self-tailored experience for players despite being on an immutable blockchain </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}} />
                </div>                
                  <img src={require(`./assets/images/arrows.gif`)} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">2.</span> Rename Any time, All the time, for free </p>
                  <div className="mkNano tmint">Hint: Scare off attackers with a diehard name. Your Name Stays Onchain </div>
                  <img src={require('./assets/images/notebook.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>
                  <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
                <div>
                <div className="helpCol">
                  <p className="mkSmall"><span className="tmint">3.</span> Customizable Battledog Avatar Soon </p> 
                  <div className="mkNano tmint">Hint: A coming feature is to allow you customize & upload your own Battledog art. Artwork you feel best expresses or represents you </div>
                  <img src={require('./assets/images/customize_no.gif')} className="helpMenuBox vis-1" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}/>
                </div>                
                </div>              
              </div>
          </div>
        </Desktop>
        <Mobile>
          <div className="alignCenter" >
            <div className="mkMicro tblack" align="center"> NFT Customization </div>
              <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleCustomize}>
                  <p className="mkNano"><span className="tmint">1.</span> Our Gaming NFTs are fully customizable </p>
                  <div className="mkNano tmint">Hint: A more self-tailored experience for players despite being on an immutable blockchain </div>
                  <img src={require(`./assets/images/collectable/${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">2.</span> Rename Any time, All the time, for free </p>
                  <div className="mkNano tmint">Hint: Scare off attackers with a diehard name. Your Name Stays Onchain </div>
                  <img src={require('./assets/images/notebook.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                  <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                  <p className="mkNano"><span className="tmint">3.</span> Customizable Battledog Avatar Soon </p>
                  <div className="mkNano tmint">Hint: A coming feature is to allow you customize & upload your own Battledog art. Artwork you feel best expresses or represents you </div>
                  <img src={require('./assets/images/customize_no.gif')} className="helpMenuBoxMobile vis-1" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
              </div>
          </div>
        </Mobile>
        </>   
      );
    }

  const ArenaHelpComponent = () => {
    return (
      <>
      <Desktop> 
        <div className="alignCenter" >
          <div className="mkSmall tblack" align="center">Quick Tutorial</div>
            <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleAssault}>
              <div className="helpCol mkNano">
                <p className="mkSmall"><span className="tmint">1.</span> Assault another NFT</p>
                <img src={require('./assets/images/img-2a.png')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div> to steal points from its aggression </div>
              </div>                
                <img src={require('./assets/images/guidance.gif')} style={{height: "10vh"}} />
              <div>
              <div className="helpCol mkNano">
                <p className="mkSmall"><span className="tmint">2.</span> Debilitate another NFT</p>
                <img src={require('./assets/images/img-1a.png')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div> to steal points from its Stamina </div>
              </div>                
              </div>
              <img src={require('./assets/images/arrows.gif')} style={{height: "10vh"}} />
              <div>
              <div className="helpCol mkNano">
                <p className="mkSmall"><span className="tmint">3.</span> For every 100 points </p>
                <img src={require('./assets/images/startfighters.png')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div>you can claim a win</div>
                <div className=' tmint'>either an Assault Win or Debilitate win </div>
              </div>                
              </div>              
            </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="alignCenter" >
          <div className="mkMicro tblack" align="center">Quick Tutorial</div>
            <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleAssault}>
                <p className="mkNano"><span className="tmint">1.</span> Assault another NFT</p>
                <img src={require('./assets/images/img-2a.png')} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className='mkNano'> to steal points from its aggression </div>
                <img src={require('./assets/images/swap.gif')} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">2.</span> Debilitate another NFT </p>
                <img src={require('./assets/images/img-1a.png')} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className='mkNano'> to steal points from its Stamina </div>
                <img src={require('./assets/images/down-arrow.gif')} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">3.</span> For every 100 points </p>
                <img src={require('./assets/images/startfighters.png')} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className="mkNano">you can claim a win</div>
                <div className='mkNano tmint'>either an Assault Win or Debilitate win </div>
            </div>
        </div>
      </Mobile>
      </>   
    );
  } 
  
  const WinningsHelpComponent = () => {
    return (
      <>
      <Desktop> 
        <div className="alignCenter" >
          <div className="mkSmall tblack" align="center">Quick Tutorial</div>
            <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handlePayout}>
              <div className="helpCol"  >
                <p className="mkSmall"><span className="tmint">1.</span> Levelups win more game points</p>
                <img src={require('./assets/images/leveler.png')} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Trade in 5 wins & burn {blockchain.tokenB} to level up. </div>
              </div>                
                <img src={require(`./assets/images/one-way.gif`)} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">2.</span> Winners get rewarded with {blockchain.tokenA}</p>
                <img src={require(`./assets/images/coins.jpg`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Trade in 5 wins using Payout to cashout {blockchain.tokenA}.  </div>
              </div>                
              </div>
              <img src={require(`./assets/images/peso.gif`)} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">3.</span> Expend 5 wins to cash out or upgrade </p>
                <img src={require(`./assets/images/leader.jpg`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Hint: for every Activation Tier up, you mine more {blockchain.tokenB} than others. </div>
              </div>                
              </div>              
            </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="alignCenter" >
          <div className="mkMicro tblack" align="center">Quick Tutorial</div>
            <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handlePayout}>
                <p className="mkNano"><span className="tmint">1.</span> Levelups win more game points</p>
                <img src={require(`./assets/images/leveler.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Trade in 5 wins & burn {blockchain.tokenB} to level up. </div>
                <img src={require(`./assets/images/one-way.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">2.</span> Winners get rewarded with {blockchain.tokenA}</p>
                <img src={require(`./assets/images/coins.jpg`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Trade in 5 wins using Payout to cashout {blockchain.tokenA}.</div>
                <img src={require(`./assets/images/peso.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">3.</span> Expend 5 wins to cash out or upgrade </p>
                <img src={require(`./assets/images/leader.jpg`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className="mkNano tmint">Hint: for every Activation Tier up, you mine more {blockchain.tokenB} than others. </div>
            </div>
        </div>
      </Mobile>
      </>   
    );
  } 
  
  const ToolsHelpComponent = () => {
    return (
      <>
      <Desktop> 
        <div className="alignCenter" >
          <div className="mkSmall tblack" align="center">Quick Tutorial</div>
            <div className="helpDiv" align="center" style={{color: `${blockchain.theme}`}} onClick={handleHarvester}>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">1.</span> Farm {blockchain.tokenA} or mine {blockchain.tokenB} </p>
                <img src={require(`./assets/images/farmer.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
              </div>                
                <img src={require(`./assets/images/tools.gif`)} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">2.</span> Farm {blockchain.tokenA} by staking {blockchain.tokenB}</p>
                <img src={require(`./assets/images/networks/network${counter}.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
              </div>                
              </div>
              <img src={require(`./assets/images/tools.gif`)} style={{height: "10vh"}} />
              <div>
              <div className="helpCol">
                <p className="mkSmall"><span className="tmint">3.</span> Mine {blockchain.tokenB} weekly </p>
                <img src={require(`./assets/images/miner.png`)} className="helpMenuBox" style={{boxShadow: `5px 5px 15px ${blockchain.theme}`}}  />
                <div className='mkNano tmint'>By playing more frequently <br />with each Battledog NFT</div>
              </div>                
              </div>              
            </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="alignCenter" >
          <div className="mkMicro tblack" align="center">Quick Tutorial</div>
            <div className="helpDivMobile" align="center" style={{color: `${blockchain.theme}`}} onClick={handleHarvester}>
                <p className="mkNano"><span className="tmint">1.</span> Farm {blockchain.tokenA} or mine {blockchain.tokenB} </p>
                <img src={require(`./assets/images/farmer.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">2.</span> Farm {blockchain.tokenA} by staking your {blockchain.tokenB} tokens </p>
                <img src={require(`./assets/images/networks/network${counter}.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <img src={require(`./assets/images/down-arrow.gif`)} style={{height: "5vh"}} />
                <p className="mkNano"><span className="tmint">3.</span> Mine {blockchain.tokenB} weekly</p>
                <img src={require(`./assets/images/miner.png`)} className="helpMenuBoxMobile" style={{boxShadow: `2.5px 2.5px 7.5px ${blockchain.theme}`}}  />
                <div className='mkNano tmint'>By playing more frequently <br/>with each Battledog NFT</div>
            </div>
        </div>
      </Mobile>
      </>   
    );
  } 

  //sound States & Effects **************************************************************************************************
  const [soundtrack, setSoundtrack] = useState();
  const [audioPlaying, setAudioPlaying] = useState(true);
  const [volumeLevel, setVolumeLevel] = useState(0.5);
  const [volIcon, setVolIcon] = useState('vol-mute');
  const [isHovered, setIsHovered] = useState(false);
     
  const EffectWelcome = new Howl({ 
    src: [soundeffects.Welcome],
    loop: false, 
  });

  const EffectLogin = new Howl({ 
    src: [soundeffects.Login],
    loop: false, 
  });
      
  const EffectLogoff = new Howl({
    src: [soundeffects.Logoff],
    loop: false, 
  });
  
  const EffectPop = new Howl({
    src: [soundeffects.Pop],
    loop: false, 
  });
    
  const EffectWhoosh = new Howl({
    src: [soundeffects.Whoosh],
    loop: false, 
  });

  const EffectBrick = new Howl({
    src: [soundeffects.Brick],
    loop: false, 
  });

  const EffectButton = new Howl({
    src: [soundeffects.Button],
    loop: false, 
  });
  
  const EffectConcrete = new Howl({
    src: [soundeffects.Concrete],
    loop: false, 
  });
  
  const EffectActivate = new Howl({
    src: [soundeffects.Activate],
    loop: false, 
  });
  
  const EffectLonghowl = new Howl({
    src: [soundeffects.Longhowl],
    volume: 5,
    loop: false, 
  });
    
  const EffectLurkinggrowl = new Howl({
    src: [soundeffects.Lurkinggrowl],
    volume: 50,
    loop: false, 
  });
      
  const EffectTrailinggrowl = new Howl({
    src: [soundeffects.Trailinggrowl],
    volume: 30,
    loop: false, 
  });
  
  const EffectClaim = new Howl({
    src: [soundeffects.Claim],
    volume: volumeLevel,
    loop: false, 
  });

  const EffectRumble = new Howl({
    src: [soundeffects.Rumble],
    volume: volumeLevel,
    loop: false, 
  });
  
  const EffectStake = new Howl({
    src: [soundeffects.Stake],
    volume: volumeLevel,
    loop: false, 
  });
    
  const EffectWithdraw = new Howl({
    src: [soundeffects.Withdraw],
    volume: volumeLevel,
    loop: false, 
  });
  
  const EffectEnergy = new Howl({
    src: [soundeffects.Energy],
    loop: false, 
  });
  
  const EffectFastwhoosh = new Howl({
    src: [soundeffects.Fastwhoosh],
    loop: false, 
  });
  
  const EffectThunder = new Howl({
    src: [soundeffects.Thunder],
    loop: false, 
  });

  const playThunder = () => {
    EffectThunder.play();
  }


  const playEnergy = () => {
    EffectEnergy.play();
  }

  const playFastwhoosh = () => {
    EffectFastwhoosh.play();
  }

  const playRumble = () => {
    EffectRumble.play();
  }

  const playStake = () => {
    EffectStake.play();
  }

  const playWithdraw = () => {
    EffectWithdraw.play();
  }

  const playClaim = () => {
    EffectClaim.play();
  }

  const playLurkinggrowl = () => {
    EffectLurkinggrowl.play();
  }

  const playLonghowl = () => {
    // Howler.volume(volumeLevel); // Set the volume
    EffectLonghowl.play();
  };

  const playTrailinggrowl = () => {
    EffectTrailinggrowl.play();
  }

  const playWelcome = () => {
    ("playing welcome");
    EffectWelcome.play();
  };

  const playLogin = () => {
    // Howler.volume(volumeLevel); // Set the volume
    EffectLogin.play();
  };

  const playLogoff = () => {
    EffectLogoff.play();
  };
  
  const playPop = () => {
    EffectPop.play();
  };

  const playWhoosh = () => {
    EffectWhoosh.play();
  }
  
  const playBrick = () => {
    EffectBrick.play();
  }
  
  const playButton = () => {
    EffectButton.play();
  }
  
  const playConcrete = () => {
    EffectConcrete.play();
  }
  
  const playActivate = () => {
    EffectActivate.play();
  }
  
  const playAudio = () => {
    setAudioPlaying(true);
  };

  const stopAudio = () => {
    setAudioPlaying(false);
  };

  const increaseVolume = () => {
    const newVolumeLevel = volumeLevel + 0.1;
    if (newVolumeLevel <= 1) {
      setVolumeLevel(newVolumeLevel);
      if (newVolumeLevel <= 0.8) {
      Howler.volume(newVolumeLevel + 0.2);
      }
    }
  };

  const decreaseVolume = () => {
    const newVolumeLevel = volumeLevel - 0.1;
    if (newVolumeLevel >= 0) {
      setVolumeLevel(newVolumeLevel);
      if (newVolumeLevel >= 0.2) {
      Howler.volume(newVolumeLevel - 0.2);
      }
    }
  }; 
  
  const cycleVolume = () => {
    if (volumeLevel < 0.1) {
      setVolumeLevel(0.3);
      Howler.volume(0.3);
      playAudio();
    } else if (volumeLevel >= 0.1 && volumeLevel <= 0.3) {
      setVolumeLevel(0.6);
      Howler.volume(0.6);
    } else if (volumeLevel > 0.3 && volumeLevel <= 0.6) {
      setVolumeLevel(1);
      Howler.volume(1);
    } else {
      setVolumeLevel(0);
      Howler.volume(0);
      stopAudio();
    }
  }; 

  const handleMouseEnter = () => {
    setIsHovered(true);
    playPop();
  };

  useEffect(() => {
     if (isVolIcon) return;
      isVolIcon = true;
    if (volumeLevel < 0.1) {
      setVolIcon('vol-mute');
    } else if (volumeLevel >= 0.1 && volumeLevel <= 0.3) {
      setVolIcon('vol-low');
    } else if (volumeLevel > 0.3 && volumeLevel <= 0.6) {
      setVolIcon('vol-mid');
    } else {
      setVolIcon('vol-max');
    }
      isVolIcon = false;
  }, [volumeLevel]);

  useEffect(() => {  
    if (arsenal || activate || scoreboard) { 
      if (isAAS) return;
        isAAS = true;
        if (!allPlayers) {
          allDataCall();
        }    
        getActivePlayers();
        isAAS = false;
      }
  }, [activate, scoreboard, arsenal]);

useEffect(() => {
  if (loading) {
    if (isProcessed) return;
      isProcessed = true;
    setErrorMessageVisible(false);
      isProcessed = false;
  }
},[loading])
  
  useEffect(() => {
    if (errorMessageVisible) {
      const timer = setTimeout(() => {
        setErrorMessageVisible(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessageVisible]); 

  
//Events & Notifications **************************************************************************************************
const [mintLogs, setMintLogs] = useState([]);
const [levelLogs, setLevelLogs] = useState([]);  
const [payoutsLog, setPayoutsLog] = useState([]); 
const [assaultLogs, setAssaultLogs] = useState([]);   
const [debilitateLogs, setDebilitateLogs] = useState([]); 
const [assaultWins, setAssaultWins] = useState([]);
const [debilitateWins, setDebilitateWins] = useState([]);
const [hideNotifications, setHideNotifications] = useState(true);
const [claims, setClaims] = useState("");
const [remainingTime, setRemainingTime] = useState(null);

const unclaimed = async () => { 
  if (!ownedBattledogs) return;
    // Step 1: Get the timelock variable value
    const tm = await readContract({ 
      contract: contractPop, 
      method: "function timeLock() view returns (uint256)", 
      params: [] 
    });
    const timelock = Number(tm?.toString());

    const mlt = await readContract({ 
      contract: contractPop, 
      method: "function multiplier() view returns (uint256)", 
      params: [] 
    });

    const multiplier = Number(mlt?.toString());

    const ab = await readContract({ 
      contract: contractPop, 
      method: "function activatebonus() view returns (uint256)", 
      params: [] 
    });
    const activatebonus = Number(ab?.toString());

    const lb = await readContract({ 
      contract: contractPop, 
      method: "function levelbonus() view returns (uint256)", 
      params: [] 
    });
    const levelbonus = Number(lb?.toString());

    const wb = await readContract({ 
      contract: contractPop, 
      method: "function winsbonus() view returns (uint256)", 
      params: [] 
    });
    
    const winsbonus = Number(wb?.toString());

    const fb = await readContract({ 
      contract: contractPop, 
      method: "function fightsbonus() view returns (uint256)", 
      params: [] 
    });
    const fightsbonus = Number(fb?.toString());
     
    const hb = await readContract({ 
      contract: contractPop, 
      method: "function historybonus() view returns (uint256)", 
      params: [] 
    });
    
    const historybonus = Number(hb?.toString());
    
    // Step 2: Loop through each ID and calculate the unclaimed amount
    
    let unclaimedAmount = 0;
    let timeclock = 0;
    const activebattledogs = ownedBattledogs.map(x => x.id);
    for (const activeBattledogId of activebattledogs) {
      // Find the corresponding ownedBattledog using the activeBattledogId
      const player = ownedBattledogs.find(battledog => battledog.id === activeBattledogId);
    
      if (!player) {
        continue; // Skip if not found, handle this case as needed
      }
    
      const activate = Number(player.activate.toString());
      const level = Number(player.level.toString());
      const wins = Number(player.wins.toString());
      const fights = Number(player.fights.toString());
      const history = Number(player.history.toString());

      let pid = Number(player.id.toString())

      const playerData = await readContract({ 
        contract: contractPop, 
        method: "function Collectors(uint256) view returns (string name, uint256 id, uint256 level, uint256 attack, uint256 defence, uint256 fights, uint256 wins, uint256 payout, uint256 activate, uint256 history)", 
        params: [pid] 
      })
      
      const minerClaims = await readContract({ 
        contract: contractPop, 
        method: "function MinerClaims(uint256) view returns (uint256)", 
        params: [pid] 
      })
      
      const claimDate = Number(minerClaims?.toString());

      
      if (Number(minerClaims) > 0 ) {
        timeclock = (claimDate + Number(timelock)) * 1000;
      }
      const currentTime = Date.now();

      const activatefactor = activatebonus * (activate - 1);
          
        if (claimDate === 0) {
          // If the user has never claimed before
          const claimable = ((Number(player.activate.toString()) - 1) * multiplier) + 
            (((level - Number(playerData[2].toString())) * levelbonus) + activatefactor) + 
            (((wins - Number(playerData[6].toString())) * winsbonus) + activatefactor) + 
            (((fights - Number(playerData[5].toString())) * fightsbonus) + activatefactor) + 
            (((history - Number(playerData[9].toString())) * historybonus) + activatefactor);
          unclaimedAmount += claimable;
          setRemainingTime(0);
          
        } else if ( currentTime >= timeclock ) {
          // If the user has claimed before and the timelock has expired
          const claimable = ((Number(player.activate.toString()) - 1) * multiplier) + 
          (((level - Number(playerData[2].toString())) * levelbonus) + activatefactor) + 
          (((wins - Number(playerData[6].toString())) * winsbonus) + activatefactor) + 
          (((fights - Number(playerData[5].toString())) * fightsbonus) + activatefactor) + 
          (((history - Number(playerData[9].toString())) * historybonus) + activatefactor);
          unclaimedAmount += claimable;
          setRemainingTime(timeclock);
        } else {
          // If the user has claimed before but the timelock has not expired
          setRemainingTime(timeclock);
          const claimable = ((Number(player.activate.toString()) - 1) * multiplier) + 
          (((level - Number(playerData[2].toString())) * levelbonus) + activatefactor) + 
          (((wins - Number(playerData[6].toString())) * winsbonus) + activatefactor) + 
          (((fights - Number(playerData[5].toString())) * fightsbonus) + activatefactor) + 
          (((history - Number(playerData[9].toString())) * historybonus) + activatefactor);
          unclaimedAmount += claimable;
      }
    }    
    // Step 3: Set the state of unclaimedEntry to the total unclaimed amount
    setClaims(unclaimedAmount.toString());
};

const handleHideNotifications = () => {
  setHideNotifications(!hideNotifications);
  setListener(!listener);
};

const buttonStyle = {
backgroundColor: hideNotifications ? "gray" : `${blockchain.theme}`
};

// Listeners ************************************************************************************************
const allListeners = () => {
  mintListener();
  assaultListener();
  debilitateListener();
  assaultWinsListener();
  debilitateWinsListener();
  levelUpListener();
  payoutListener();
}

const mintListener = () => {
  let mintlisten;
  console.log("listening for mints...");
  contractBattledog.on("TokenMinted", (name, tokenId, event) => {
    let mintdata = {
      name: name.toString(), tokenId: tokenId.toString(), event
    };
    if (mintlisten) return;
    mintlisten = true;
    setMintLogs((oldState) => [...oldState, mintdata]);
    mintlisten = false;
  });
}

const assaultListener = () => {
  let assaultlisten;
  console.log("listening for assaults...");
  contractBattledog.on("AssaultEvent", (attackerId, defenderId, stolenPoints, timestamp, event) => {
    let assaultdata = {
      attackerId: attackerId.toString(), defenderId: defenderId.toString(), 
      stolenPoints: stolenPoints.toString(), timestamp: timestamp.toString(), event
    };
    if (assaultlisten) return;
    assaultlisten = true;
    setAssaultLogs((oldState) => [...oldState, assaultdata]);
    assaultlisten = false;
  });
}

const debilitateListener = () => {
  let debilitatelisten;
  console.log("listening for debilitations...");
    contractBattledog.on("DebilitateEvent", (attackerId, defenderId, stolenPoints, timestamp, event) => {
      let debilitatedata = {
        attackerId: attackerId.toString(), defenderId: defenderId.toString(), 
        stolenPoints: stolenPoints.toString(), timestamp: timestamp.toString(), event
      };
      if (debilitatelisten) return;
      debilitatelisten = true;
      setDebilitateLogs((oldState) => [...oldState, debilitatedata]);
      debilitatelisten = false;
    });
}

const assaultWinsListener = () => {
  let assaultwinslisten;
  console.log("listening for assault wins...");
  contractBattledog.on("AssaultPayoutClaimed", (playerId, payreward, event) => {
    let assaultpayoutdata = {
      playerId: playerId.toString(), payreward: payreward.toString(), event
    };
    if (assaultwinslisten) return;
    assaultwinslisten = true;
    setAssaultWins((oldState) => [...oldState, assaultpayoutdata]);
    assaultwinslisten = false;
  });
}

const debilitateWinsListener = () => {
  let debilitatewinslisten;
  console.log("listening for debilitate wins...");
  contractBattledog.on("DebilitatePayoutClaimed", (playerId, payreward, event) => {
    let debilitatepayoutdata = {
      playerId: playerId.toString(), payreward: payreward.toString(), event
    };
    if (debilitatewinslisten) return;
    debilitatewinslisten = true;
    setDebilitateWins((oldState) => [...oldState, debilitatepayoutdata]);
    debilitatewinslisten = false;
  });
}

const levelUpListener = () => {
  let leveluplisten;
  console.log("listening for levelups...");
  contractBattledog.on("LevelUpEvent", (playerId, level, event) => {
    let leveldata = {
      playerId: playerId.toString(), level: level.toString(), event
    };
    if (leveluplisten) return;
    leveluplisten = true;
    setLevelLogs((oldState) => [...oldState, leveldata]);
    leveluplisten = false;
  });
}

const payoutListener = () => {
  let payoutlisten;
  console.log("listening for payouts...");
  contractBattledog.on("PayoutsClaimed", (playerId, amount, event) => {
    let payoutsdata = {
      playerId: playerId.toString(), amount: amount.toString(), event
    };
    if (payoutlisten) return;
    payoutlisten = true;
    setPayoutsLog((oldState) => [...oldState, payoutsdata]);
    payoutlisten = false;
  });
}

// event AfterCalls

async function activateAfterCall () {
  if (isActivate) return;
    isActivate = true;
    playActivate();
    setErrorMessage("Activated!");
    setErrorMessageVisible(true);
    await handleUpload(attackerId);
    await handleState(attackerId);
    await allOwnerOfPlayersTx();
    isActivate = false;

    setLoading(false);
  }
  
  async function claimAssaultAfterCall () {
    if (isClaimAssault) return;
        isClaimAssault = true;
        await allOwnerOfPlayersTx();
      setErrorMessage("Claim Success!");
      setErrorMessageVisible(true);
      await handleState(defenderId);
      isClaimAssault = true;

      setLoading(false);
    }

  async function assaultAfterCall () {
      if (isAssault) return; 
        isAssault = true;  
        await allOwnerOfPlayersTx();
        setErrorMessage("Success!");
        setErrorMessageVisible(true);
        await handleState(attackerId);
        await handleState(defenderId);

        isAssault = false;
        setLoading(false);    
      }

function approveGameAfterCall () {
  if (isProcessing) return;
  isProcessing = true;
  
  resetAllowance();
  setSubmitText('Approved!');
  console.log("Approval Succeeded"); 
  setTimeout(() => {
    addGAME(approval);
    isProcessing = false;
  }, 9400); 

  setIsWaiting(false); 
}  

async function debilitateAfterCall () {
  if (isDebilitate) return;
      isDebilitate = true;
      await allOwnerOfPlayersTx();
    playLonghowl();
    setErrorMessage("Success!");
    setErrorMessageVisible(true);
    await handleState(attackerId);
    await handleState(defenderId);
      isDebilitate = false;

    setLoading(false);
}

async function claimDebilitateAfterCall () {
  if (isClaimDebilitate) return;
      isClaimDebilitate = true;
      await allOwnerOfPlayersTx();
    setErrorMessage("Claim success!");
    setErrorMessageVisible(true);  
    await handleState(defenderId);
      isClaimDebilitate = false;

    setLoading(false);    
}

async function levelUpAfterCall () {
  if (isLevelUp) return;
      isLevelUp = true;
      await allOwnerOfPlayersTx();
    setErrorMessage("Leveled Up!");
    setErrorMessageVisible(true);  
    await handleState(attackerId);
      isLevelUp = false;

    setLoading(false);  
  }

function mintAfterCall() {
  if (isMint) return
    isMint = true;
  console.log('Mint Succeeded');
  setErrorMessage("Fresh Mint!");
  allOwnerOfPlayersTx();
  setErrorMessageVisible(true);
  playWelcome();
    isMint = false;

  setLoading(false);
} 

function payoutAfterCall() {  
  if (isPayout) return;
  isPayout = true;
  allOwnerOfPlayersTx();
playClaim();
setErrorMessage("Paid!");
setErrorMessageVisible(true);
  isPayout = false;    

setLoading(false); 
}  

async function regenerateAfterCall () {
  if (isRegenerate) return;
      isRegenerate = true;
      await allOwnerOfPlayersTx();
    setErrorMessage("Regenerated!");
    setErrorMessageVisible(true);
    await handleState(attackerId);
      isRegenerate = false;

    setLoading(false);
}

function customizeAfterCall () {  
  if (isCustomize) return;
  isCustomize = true;
playWelcome();
setErrorMessage("Updated!");
setErrorMessageVisible(true); 
  isCustomize = false;

setLoading(false);            
}

async function weaponizeAfterCall () {
  if (isWeaponize) return;
      isWeaponize = true;
      await allOwnerOfPlayersTx();
    setErrorMessage("Weaponized!");
    setErrorMessageVisible(true);
    await handleState(attackerId);  
      isWeaponize = false;

    setLoading(false); 
}        

function miningAfterCall () {
  if (isProcess) return;
  isProcess = true;
  playClaim();
  statusLock();
  setUnlocker(false);
  setUnlocker2(false);
  isProcess = false; 
  console.log("GAME Mined");
  setSubmitText("Claim Succeeded"); 

  setIsClaiming(false);  
}

function farmAfterCall () {
  if (isProcess) return;
  isProcess = true;
  console.log('Farming Succeeded');
  setSubmitText("Farming Succeeded"); 
  fetchFarmData();       
  playStake();
  isProcess = false; 

  setIsFarming(false);  
}

function wrapAfterCall () {
  if (isWrap) return;
  isWrap = true;
  allDataCall();
  isWrap = false; 
}

// Harvester Event
useEffect (() => {
  if (!account) return;
  if (harvester) {
    const readHarvesterData = async () => {
    const contractHE = new ethers.Contract(rewarderAddress, contractData.harvester.abi, signer);
    contractHE.on("RewardClaimedByUser", (sender, rewards, event) => {
      let data0 = {
        sender: sender.toString(),
        rewards: rewards.toString(), 
        event,
      };
      let rec = sender.toString().toLowerCase();
      if (rec === account.address.toLowerCase()) {
        setSenderLogs(...data0);
      }
    }); 
  }
  
  readHarvesterData();
  }
},[harvester]);

// Mining Event
useEffect(() => {
  if (!account) return;
  if (mining) {
    const readData = async () => {  
    const contractME = new ethers.Contract(blockchain.popaddress, contractData.pop.abi, signer);
    contractME.on("RewardClaimedByMiner", (sender, rewards, event) => {
      let data1 = {
        sender: sender.toString(),
        rewards: rewards.toString(), 
        event,
      };
      let rec = sender.toString().toLowerCase();
      if (rec === account.address.toLowerCase()) {
        setSenderLogs(...data1);
      }
    });  
  }
  
  readData();
  }
},[mining]);

  return (
    <>
    <ReactPlayer className="soundwidth"
        url={soundtrack}
        playing={audioPlaying}
        volume={volumeLevel}
        loop={true}
        controls={false}
      />     
      
      <>       
        <Desktop>
          <div className={`background mainBackground${backgroundImage}`}>
            <div className="topLogin">
              <Connector />
            </div>
              <div className="topLogo">
                <img src={require(`./assets/images/logo.png`)} style={{height: "10vh"}}/>
              </div>
          {!wallet || !account ? (
            <LoginMenuComponent />
           ) : ( 
            <>
          <LayoutComponent />
          <div className="swdesktop">            
            <div className='soundicon theight tutorial' onClick={handleTutorial} style={{backgroundColor: `white`}}> Tutorial Mode: <span style={{color: `${blockchain.theme}`}}>{textTutor}</span> </div>
            <img src={require("./assets/images/icons/vol+.png")} className='soundicon sheight' onClick={increaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
            <img src={require(`./assets/images/icons/${volIcon}.png`)} className='soundicon theight' onClick={cycleVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
            <img src={require("./assets/images/icons/vol-.png")} className='soundicon sheight' onClick={decreaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>     
          </div> 
            </>
          )}   
          {account && startMenu && (
            <>
            <StartMenuComponent />
            </>
          )}       
          {account && activityTracker && (
            <>
              <ActivityTrackerComponent />
              <LockComponent />      
            </>
          )}           
          {account && activate && (
            <>
              <ActivateComponent />  
              <ApproveNative /> 
              <LockComponent />      
            </>
          )}           
          {account && activateHelp && (
            <>
              <ActivateHelpComponent />  
            </>
          )}        
          {account && arena && (
            <>
            <ArenaHelpComponent />
            </>
          )}     
          {account && arsenal && (
            <>
              <ArsenalComponent />  
              <ApproveNative /> 
              <LockComponent />      
            </>
          )}         
          {account && assault && (
            <>
              <AssaultComponent />  
              <ApproveNative />
              <LockComponent />       
            </>
          )}          
          {account && assaultHelp && (
            <>
              <AssaultHelpComponent />       
            </>
          )}        
          {account && claimAssault && (
            <>
              <ClaimAssaultComponent /> 
              <LockComponent /> 
            </>
          )}         
          {account && claimAssaultHelp && (
            <>
              <ClaimAssaultHelpComponent />  
            </>
          )}         
          {account && claimDebilitate && (
            <>
              <ClaimDebilitateComponent />
              <LockComponent />    
            </>
          )}            
          {account && claimDebilitateHelp && (
            <>
              <ClaimDebilitateHelpComponent />    
            </>
          )}         
          {account && debilitate && (
            <>
              <DebilitateComponent />  
              <ApproveNative />   
              <LockComponent />    
            </>
          )}         
          {account && debilitateHelp && (
            <>
              <DebilitateHelpComponent /> 
            </>
          )}           
          {account && harvester && (
            <>
              <HarvesterComponent /> 
              <LockComponent />
            </>
          )}         
          {account && scoreboard && (
            <>
              <ScoreboardComponent /> 
              <LockComponent />
            </>
          )}         
          {account && levelUp && (
            <>
              <LevelUpComponent />  
              <ApproveGame />   
              <LockComponent />    
            </>
          )}         
          {account && levelUpHelp && (
            <>
              <LevelUpHelpComponent /> 
            </>
          )}
          {account && mining && (
            <>
            <MiningComponent />
            </>
          )}
          {account && mint && (
            <>
            <MintComponent />
            <ApproveNative />
            <LockComponent />
            </>
          )}          
          {account && payout && (
            <>
            <PayoutComponent />
            <LockComponent />
            </>
          )}          
          {account && payoutHelp && (
            <>
            <PayoutHelpComponent />
            </>
          )}
          {account && regenerate && (
            <>
            <RegenerateComponent />
            <LockComponent />
            </>
          )}
          {account && regenerateHelp && (
            <>
            <RegenerateHelpComponent />
            </>
          )}
          {account && resetTimer && (
            <>
            <ResetTimerComponent />
            <LockComponent />
            </>
          )}
          {account && resetTimerHelp && (
            <>
            <ResetTimerHelpComponent />
            </>
          )}
          {account && start && (
            <div>
            <StartHelpComponent />
            </div>
          )}
          {account && tools && (
            <div>
            <ToolsHelpComponent />
            </div>
          )}
          {account && customize && (
            <>
            <CustomizeComponent />
            <LockComponent />
            </>
          )}
          {account && customizeHelp && (
            <>
            <CustomizeHelpComponent />
            </>
          )}
          {account && winnings && (
            <>
            <WinningsHelpComponent />
            </>
          )}
          {account && weaponize && (
            <>
            <WeaponizeComponent />
            <LockComponent />
            </>
          )}
          {account && weaponizeHelp && (
            <>
            <WeaponizeHelpComponent />
            </>
          )}
          </div>
        </Desktop>
        <Mobile>
          <div className={`backgroundMobile mainBackground${backgroundImage}`}>
            <div className="topLogoMobile" align="center">            
              <img src={require(`./assets/images/logo.png`)} style={{height: "10vh"}}/>
            </div>
            <div className="topLoginMobile" align="center">
              <Connector />
            </div>
            {!account ? (
              <LoginMenuComponent />
            ):(
              <> 
              <LayoutComponent />
              <div className="swmobile">                
                <div className='soundicon theightmb tutorial' onClick={handleTutorial} style={{backgroundColor: "white"}}> Tutorial Mode: <span style={{color: `${blockchain.theme}`}}>{textTutor}</span></div>
                <img src={require("./assets/images/icons/vol-.png")} className='soundicon sheightmb' onClick={decreaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
                <img src={require(`./assets/images/icons/${volIcon}.png`)} className='soundicon theightmb' onClick={cycleVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
                <img src={require("./assets/images/icons/vol+.png")} className='soundicon sheightmb' onClick={increaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
              </div>
              </>
            )}   
            {account && startMenu && (
              <>
              <StartMenuComponent />
              </>
            )}    
            {account && activityTracker && (
              <>
                <ActivityTrackerComponent />  
                <LockComponent />    
              </>
            )}          
            {account && activate && (
              <>
                <ActivateComponent />  
                <ApproveNative />     
                <LockComponent />  
              </>
            )}            
            {account && activateHelp && (
              <>
                <ActivateHelpComponent />  
              </>
            )}         
            {account && arena && (
              <>
              <ArenaHelpComponent />
              </>
            )} 
            {account && arsenal && (
              <>
                <ArsenalComponent />  
                <ApproveNative />     
                <LockComponent />  
              </>
            )}         
            {account && assault && (
              <>
                <AssaultComponent />  
                <ApproveNative />   
                <LockComponent />    
              </>
            )}           
            {account && assaultHelp && (
              <>
                <AssaultHelpComponent />       
              </>
            )}         
            {account && claimAssault && (
              <>
                <ClaimAssaultComponent />  
                <LockComponent />
              </>
            )}         
            {account && claimAssaultHelp && (
              <>
                <ClaimAssaultHelpComponent />  
              </>
            )}            
            {account && claimDebilitate && (
              <>
                <ClaimDebilitateComponent />   
                <LockComponent /> 
              </>
            )}            
            {account && claimDebilitateHelp && (
              <>
                <ClaimDebilitateHelpComponent />    
              </>
            )}         
            {account && debilitate && (
              <>
                <DebilitateComponent />  
                <ApproveNative />       
                <LockComponent />
              </>
            )}         
            {account && debilitateHelp && (
              <>
                <DebilitateHelpComponent /> 
              </>
            )}         
            {account && harvester && (
              <>
                <HarvesterComponent /> 
              </>
            )}         
            {account && scoreboard && (
              <>
                <ScoreboardComponent /> 
                <LockComponent />
              </>
            )}         
            {account && levelUp && (
              <>
                <LevelUpComponent />  
                <ApproveGame />       
                <LockComponent />
              </>
            )} 
            {account && levelUpHelp && (
              <>
                <LevelUpHelpComponent /> 
              </>
            )}
            {account && mining && (
              <>
              <MiningComponent />
              </>
            )}
            {account && mint && (
              <>
              <MintComponent />
              <ApproveNative />
              <LockComponent />
              </>
            )}          
            {account && payout && (
              <>
              <PayoutComponent />
              <LockComponent />
              </>
            )}          
            {account && payoutHelp && (
              <>
              <PayoutHelpComponent />
              </>
            )}
            {account && regenerate && (
              <>
              <RegenerateComponent />
              <LockComponent />
              </>
            )}
            {account && regenerateHelp && (
              <>
              <RegenerateHelpComponent />
              </>
            )}
            {account && resetTimer && (
              <>
              <ResetTimerComponent />
              <LockComponent />
              </>
            )}
            {account && resetTimerHelp && (
              <>
              <ResetTimerHelpComponent />
              </>
            )}
            {account && start && (
              <div>
              <StartHelpComponent />
              </div>
            )}
            {account && tools && (
              <div>
              <ToolsHelpComponent />
              </div>
            )}
            {account && customize && (
              <>
              <CustomizeComponent />
              <LockComponent />
              </>
            )}
            {account && customizeHelp && (
              <>
              <CustomizeHelpComponent />
              </>
            )}
            {account && winnings && (
              <>
              <WinningsHelpComponent />
              </>
            )}
            {account && weaponize && (
              <>
              <WeaponizeComponent />
              <LockComponent />
              </>
            )}
            {account && weaponizeHelp && (
              <>
              <WeaponizeHelpComponent />
              </>
            )}
          </div>
        </Mobile>
    </>
  </>
  );
}

export default App;